import React from 'react';
import 'twin.macro';
import { SelectControl } from './SelectControl';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { nl } from 'date-fns/locale/nl';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import styles from './DateRangeSelect.css';


registerLocale('nl', nl);
setDefaultLocale('nl');

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;

const DATE_FORMAT = 'dd.MM.yyyy HH:mm';

export const dateRanges = {
  '2d': () => ({ start: new Date(Date.now() - DAY * 2), end: new Date() }),
  '2w': () => ({ start: new Date(Date.now() - WEEK * 2), end: new Date() }),
};

export const defaultDateRange = '2d';

export class DateRangeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { search: '', range: defaultDateRange, dates: dateRanges[defaultDateRange]() };
    this.selectRange = this.selectRange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { dates, range } = this.state;
    if(range !== prevState.range && range !== 'custom' ||
      dates.start !== prevState.dates.start ||
      dates.end !== prevState.dates.end) {
      this.props.onSelect(range === 'custom' ? dates : dateRanges[range]());
    }
  }

  selectRange(range) {
    const state = { range };
    if (range !== 'custom') {
      state.dates = dateRanges[range]();
    }
    this.setState(state);
  }

  render() {
    const { range, dates: { start, end } } = this.state;
    return (
      <div tw="flex">
        <SelectControl value={range} options={[
          { label: 'Laatste 2 dagen', value: '2d' },
          { label: 'Laatste 2 weken', value: '2w' },
          { label: 'Custom', value: 'custom' },
        ]} onChange={this.selectRange} disabled={false}/>
        {range === 'custom' && <>
          <div tw="ml-1 mr-2">
            <DatePicker selected={start} showTimeSelect selectsStart
              startDate={start} endDate={end} maxDate={new Date(end.getTime() - DAY)}
              dateFormat={DATE_FORMAT} className={styles.datepicker}
              onChange={x => this.setState({ dates: { end, start: x } })}
              tw="border shadow-sm p-2 block w-full border-gray-300 rounded-md
              focus:ring-indigo-500 focus:border-indigo-500 focus:ring-1"
            />
          </div>
          <div>
            <DatePicker selected={end} showTimeSelect selectsEnd
              startDate={start} endDate={end} minDate={new Date(start.getTime() + DAY)} maxDate={new Date()}
              dateFormat={DATE_FORMAT} className={styles.datepicker}
              onChange={x => this.setState({ dates: { start, end: x } })}
              tw="border shadow-sm p-2 block w-full border-gray-300 rounded-md
              focus:ring-indigo-500 focus:border-indigo-500 focus:ring-1"  
            />
          </div>
        </>}
      </div>

    );
  }
}

DateRangeSelect.defaultRange = dateRanges[defaultDateRange]();
