export const JobState = {
  invalid: 'StateInvalid', // -1,
  created: 'StateCreated', // 0,
  scheduled: 'StateScheduled', // 1,
  running: 'StateRunning', // 2,
  paused: 'StatePaused', // 3,
  failed: 'StateFailed', // 4,
  aborted: 'StateAborted', // 5,
  completed: 'StateCompleted', // 6,
};

export const LOCALE = {
  format: 'nl-NL',
  timezone: 'Europe/Amsterdam',
};

export const optionsFullDate = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: '2-digit',
};

export const humanDiff = (t1, t2) => {
  const diff = Math.max(t1, t2) - Math.min(t1, t2); 
  const SEC = 1000, MIN = 60 * SEC, HRS = 60 * MIN;
  
  const hrs = Math.floor(diff/HRS);
  const min = Math.floor((diff%HRS)/MIN).toLocaleString(LOCALE.format, {minimumIntegerDigits: 2});
  const sec = Math.floor((diff%MIN)/SEC).toLocaleString(LOCALE.format, {minimumIntegerDigits: 2});
    
  return `${hrs}u:${min}m:${sec}s`;
};

export const humanFileSize = size => {
  const i = size === 0 ? 0 : Math.floor( Math.log(size) / Math.log(1024) );
  return `${( size / Math.pow(1024, i) ).toFixed() * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const formatPercent = Intl.NumberFormat(LOCALE.format, {
  style: 'percent',
});
