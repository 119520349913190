// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videoplayer_container--tNhS4I {
  padding-top: 56.25%;
  display: block;
  position: relative;
  background: #000
}
.videoplayer_container--tNhS4I > div {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`, "",{"version":3,"sources":["webpack://./src/components/videoplayer/AgnoVideoPlayer.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,cAAc;EACd,kBAAkB;EAClB;AAUF;AATE;IACE,SAAS;IACT,OAAO;IACP,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,YAAY;IACZ,WAAW;EACb","sourcesContent":[".videoplayer_container {\n  padding-top: 56.25%;\n  display: block;\n  position: relative;\n  background: #000;\n  & > div {\n    bottom: 0;\n    left: 0;\n    position: absolute;\n    right: 0;\n    top: 0;\n    height: 100%;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoplayer_container": `videoplayer_container--tNhS4I`,
	"videoplayerContainer": `videoplayer_container--tNhS4I`
};
export default ___CSS_LOADER_EXPORT___;
