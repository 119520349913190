import * as React from 'react';
import tw from 'twin.macro';

const Container = ({ children, ...r }) =>       
  <div tw='max-w-7xl mx-auto p-3 lg:py-3 lg:px-8' {...r}>
    {children}
  </div>; 

export const PageContainer = ({ children, toolbar, isPostPage }) => (
  <div 
    css={[tw`pt-16 min-h-screen`, !!toolbar && tw`-top-20`]}>
    <div css={[tw`bg-white sticky z-10 top-16 shadow-toolbar w-full`, isPostPage && tw`-top-20 md:top-16`]}>
      <Container tw='overflow-x-scroll'>
        {toolbar}
      </Container> 
    </div>    
    <Container>
      {children}
    </Container>
  </div>

);