import React from 'react';
import { Textarea } from 'react-bulma-components/cjs/components/form';

export const Scripts = ({ upload, enabled, change }) => {
  return <Textarea
    value={upload.scripts || ''} onChange={e => {
      enabled ? change({ scripts: e.target.value }) : '';
    }}
  />;
};
