import { createSelector } from 'reselect';


export const selectCurrentUploads = state => state.current.uploads;
export const selectCurrentJobs = state => state.current.jobs;
export const selectCurrentPosts = state => state.current.posts;
export const selectEditors = state => state.users.editors;
export const selectFinishedUploadIDs = state => state.current.uploadIDsJobsDone;
export const selectRefreshAssetsKey = state => state.current.refreshAssetsKey;


export const selectUploadsWithEditorsFlag = createSelector(
  selectCurrentUploads,
  selectEditors,
  (uploads, editors) => uploads.map( u => {
    const {uploader: {email} = {email: ''}} = u;
    return {...u, isEditors: editors.indexOf(email) !== -1};
  }),
);

export const selectCurrentUploadsSorted = createSelector(
  selectUploadsWithEditorsFlag,
  uploads => {
    const sorted = [...uploads];
    sorted.sort((a, b) => a.created > b.created ? -1 : 1);
    return sorted;
  },
);

export const selectCurrentJobsByItem = createSelector(selectCurrentJobs, jobs => {
  const res = Object.fromEntries(jobs.map(j => [j.item.id, { jobs: [], scry: 0, total: 0 }]));
  for (const j of jobs) {
    const itemInfo = res[j.item.id];
    const scry = j.scry || 1;
    itemInfo.scry += scry;
    itemInfo.total += (j.progress * scry);
    itemInfo.jobs.push(j);
    itemInfo.uploadId = j.item.upload_id;
  }

  for (const i of Object.values(res)) {
    i.total = i.total / i.scry;
  }
  return res;
});

export const selectCurrentJobsByUpload = createSelector(selectCurrentJobsByItem, itemJobMap => {
  const values = Object.values(itemJobMap);
  const res = Object.fromEntries(values.map(itemInfo => [itemInfo.uploadId, { jobs: [], scry: 0, total: 0 }]));

  for (const itemInfo of values) {
    const uploadInfo = res[itemInfo.uploadId];
    const scry = itemInfo.scry || 1;
    uploadInfo.scry += scry;
    uploadInfo.total += (itemInfo.total * scry);
    uploadInfo.jobs = [...uploadInfo.jobs, ...itemInfo.jobs];
  }

  for (const i of Object.values(res)) {
    i.total = i.total / i.scry;
  }
  return res;
});

export const mapJobsToUploads =
  (uploads, jobs) => (uploads) ? uploads.map(u =>
    ({ ...u, items: u.items && u.items.map(item =>
      ({ ...item, jobs: jobs.filter(j => j.item.id === item.id) }) )})) : [];

export const selectCurrentUploadsSortedMapAllJobs = createSelector(
  selectCurrentUploadsSorted,
  selectCurrentJobs,
  mapJobsToUploads,
);

export const selectCurrentUploadsMapActiveJobs = createSelector(
  selectCurrentUploads,
  selectCurrentJobs,
  mapJobsToUploads,
);

export const selectCurrentPostsMapActiveJobs = createSelector(
  selectCurrentPosts,
  selectCurrentJobs,
  mapJobsToUploads,
);
