import React, { useCallback, useEffect, useState } from 'react';
import 'twin.macro';
import { DateRangeSelect, RefreshButton, SelectControl, SwitchButtons } from '../common';
import { getJobHistory } from 'services/dmp';
import { ActiveJobsList } from './conversion/ActiveJobsList';
import { HistoryJobsList } from './conversion/HistoryJobsList';
import { formatDateRFC3339 } from '../utils';
import { useShowErrorMessage } from '../common/hooks';
import { JobState } from './conversion/helpers';

const JobStateTranslation = {
  invalid: 'niet geldig',
  created: 'aangemaakt',
  scheduled: 'gepland',
  running: 'bezig',
  paused: 'gepauzeerd',
  failed: 'mislukt',
  aborted: 'afgebroken',
  completed: 'klaar',
};

export const ConversionTab = () => {
  const [jobs, setJobs] = useState([]);
  const [stateFilter, setStateFilter1] = useState('*');
  const [sort, setSort] = useState('nieuwste');
  const [{ start, end }, setDateRange] = useState(DateRangeSelect.defaultRange);
  const showErrorMessage = useShowErrorMessage();

  const filteredJobs = stateFilter === '*' ? jobs : jobs.filter(j => j.state === stateFilter);

  filteredJobs.sort((a, b) => sort === 'nieuwste' ?
    new Date(b.since) - new Date(a.since) : new Date(a.since) - new Date(b.since));

  const loadJobs = useCallback(() => {
    getJobHistory({
      start: formatDateRFC3339(start),
      end: formatDateRFC3339(end),
    })
      .then(({ data }) => setJobs(data))
      .catch(showErrorMessage);
  }, [start, end]);

  useEffect(loadJobs, [loadJobs]);

  return (
    <>
      <div tw="py-14">
        <div tw="m-6 flex items-center justify-center">
          <div tw="flex-grow-0">
            <SwitchButtons buttons={[
              { title: 'nieuwste', value: 'nieuwste', color: 'info' },
              { title: 'oudste', value: 'oudste', color: 'info' },
            ]} selected={sort} onSelect={setSort}/>
          </div>

          <SelectControl options={
            [{ label: 'alles', value: '*' }].concat(
              Object.entries(JobState).map(([k, v]) => ({ label: JobStateTranslation[k], value: v }),
              ))
          } onChange={setStateFilter1} value={stateFilter}
          />
        </div>

        <div tw="m-3 grid grid-cols-1 md:grid-cols-3 md:gap-3">
          {/* Queue Column */}
          <div tw="space-y-3">
            <div tw="h-8 flex justify-between">
              <h2 tw="text-3xl leading-tight">
                In Queue
              </h2>

            </div>
            <section>
              <ActiveJobsList stateFilter={['StateCreated']} sort={sort}/>
            </section>
          </div>
          {/* Progress Column */}
          <div tw="space-y-3">
            <div tw="h-8 flex justify-between">
              <h2 tw="text-3xl leading-tight">
                In Progress
              </h2>

            </div>
            <section>
              <ActiveJobsList stateFilter={['StatePaused', 'StateScheduled', 'StateRunning']} sort={sort}/>
            </section>
          </div>
          {/* History Column */}
          <div tw="space-y-3">
            <div tw="flex justify-between h-8">
              <h2 tw="text-3xl leading-tight">
                Done
              </h2>
              <div tw="flex self-start">
                <RefreshButton onRefresh={loadJobs} tw="mr-2"/>
                <DateRangeSelect onSelect={setDateRange}/>
              </div>
            </div>
            <section>
              <HistoryJobsList jobs={filteredJobs}/>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
