import React, { useEffect, useState } from 'react';
import 'twin.macro';
import { Button } from '../common/AdminButton';
import { useHistory } from 'react-router-dom';
import {
  PageContainer,
  ListContainer,
  ListRow, 
  ListColumnFixed,
  Notification, 
  FormField,
  Input,
} from '../common';
import {
  fetchCensoredPhrases, 
  addPhrase,
  deletePhrase,
} from 'services/comment';

function CensoredPhrases() {
  const [items, setItems] = useState([]);
  const [inputPhrase, setInputPhrase] = useState('');
  const [inputComment, setInputComment] = useState('');
  const [notification, setNotification] = useState(null);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    await addPhrase(inputPhrase, inputComment).then(async () => {
      await loadData().then(() => {
        setInputPhrase('');
        setInputComment('');
        setNotification({ color: 'success', message: 'item toegevoegd aan lijst' });
      });
    });
  };

  const handleDeletePhrase = async (phrase) => {
    await deletePhrase(phrase).then(async () => {
      await loadData().then(() => {
        setNotification({ color: 'danger', message: 'item verwijderd van lijst' });
      });
    });    
  };

  const loadData = async () =>  { 
    await fetchCensoredPhrases()
      .then(response => {
        setItems(response.data);
      })
      .catch(error => setNotification({ color: 'danger', message: error.response.data }));
  };

  useEffect(() => {
    if (items.length) { return; } 
    loadData();
  }, [items]);

  useEffect(() => {
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  }, [notification]);

  return (
    <div className="Moderation">
      {notification !== null && 
        <div tw='fixed right-10 top-36 z-50'>
          <Notification 
            color={notification.color}
            onRemove={() => setNotification(null)}
          >
            <p>{notification.message}</p>
          </Notification>
        </div>
      }      
      <ListContainer>
        <ListColumnFixed>
          <form 
            onSubmit={e => handleSubmitForm(e)}
            tw='flex gap-2 items-end'
          >
            <FormField
              label='phrase'
              name='phrase'
              placeholder='phrase'
              autoComplete="off"
              value={inputPhrase}
              onChange={e => setInputPhrase(e.target.value)}
              FieldType={Input}
            />               
            <FormField
              label='comment'
              name='comment'
              placeholder='comment'
              autoComplete="off"
              value={inputComment}
              onChange={e => setInputComment(e.target.value)}
              FieldType={Input}
            />               
            <Button type='submit'>Add</Button>
          </form>
        </ListColumnFixed>
        <ListRow >
          <ListColumnFixed text='Phrase' tw='font-bold'/>
          <ListColumnFixed text='Comment' tw='font-bold'/>
        </ListRow>
        {items.map(item => 
          <ListRow key={item.id}>
            <ListColumnFixed><pre tw='p-2'>{item.phrase}</pre></ListColumnFixed>
            <ListColumnFixed>{item.comment.String}</ListColumnFixed>
            <ListColumnFixed>
              <Button label='Delete' onClick={() => handleDeletePhrase(item.id)} />
            </ListColumnFixed>
          </ListRow>,
        )}
      </ListContainer>
    </div>
  );
}

export const CensoredPhrasesModTab = () => {
  return (
    <PageContainer toolbar={<ToolBar/>}>
      <CensoredPhrases />
    </PageContainer>
  );
};

const ToolBar = () => {
  const history = useHistory();
  return <Button label="Go Back" onClick={() => history.goBack()} />;
};