import React, { useCallback, useState } from 'react';
import Field from 'react-bulma-components/cjs/components/form/components/field';
import { Control, Input, Label } from 'react-bulma-components/cjs/components/form';
import Button from 'react-bulma-components/cjs/components/button';
import { useDispatch } from 'react-redux';
import { authLogInUserOp } from './ducks';
import { FormPage, Notification } from '../common';
import c from 'classnames';
import { useUnmounted } from '../common/hooks';
import { useHistory } from 'react-router-dom';



export const LoginPage = () => {
  const unmounted = useUnmounted();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOTP] = useState('');
  const [rememberIP, setRememberIp] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const otpIsValid = /\d{6}/.test(otp);

  const login = useCallback(e => {

    e.preventDefault();
    setError(null);
    setDisabled(true);
    dispatch(authLogInUserOp(email, password, otpIsValid ? otp : ''))
      .then(() => {
        const { state } = history.location;
        history.replace(state && state.next ? state.next : '/');
      })
      .catch(e => {
        if(!unmounted.current) {
          let errorText = `${e.status} ${e.statusText}`;
          if(e.status === 429) {
            errorText = 'Te veel verkeerde inlogpogingen. Probeer het over 5 minuten nog eens!';
          } else if(e.status === 401) {
            errorText = 'Verkeerde email of wachtwoord';
          } else if(e.status === 403) {
            errorText = 'Vul een juiste OTP code in of maak een nieuwe OTP code aan';
          }
          setError(errorText);
        }
      })
      .finally(() => {
        if(!unmounted.current) {
          setDisabled(false);
        }
      });
  }, [email, password, otp, otpIsValid]);

  return (
    <FormPage header={
      error && <Notification color="danger" onRemove={() => setError(null)}>
        {error}
      </Notification>
    }>
      <Field>
        <Label>Email</Label>
        <Control>
          <Input type="email" placeholder="e.g. bobsmith@gmail.com" disabled={disabled} autoComplete={'off'}
            required value={email} onChange={e => setEmail(e.target.value)}/>
        </Control>
      </Field>
      <Field>
        <Label>Wachtwoord</Label>
        <Control>
          <Input type="password" placeholder="*******" required disabled={disabled} autoComplete={'off'}
            value={password} onChange={e => setPassword(e.target.value)}/>
        </Control>
      </Field>
      <Field>
        <div className={c('is-flex')}>
          <Label>OTP</Label>
          <span className={c('ml-1')}>(Alleen nodig bij wijziging IP)</span>
        </div>
        <Control>
          <Input type="text" placeholder="code" disabled={disabled} autoComplete={'off'}
            value={otp} onChange={e => setOTP(e.target.value)}/>
        </Control>
      </Field>
      <Field>
        <Control onClick={() => {
          if (!disabled && otpIsValid) {
            setRememberIp(!rememberIP);
          }
        }} className={c({ ['is-invisible']: !otpIsValid })}>
          <input className="is-checkradio is-small"
            type="checkbox" name="ch" disabled={disabled || !otpIsValid}
            checked={rememberIP} onChange={() => null}/>
          <label>
            <small>In het vervolg inloggen zonder OTP vanaf dit IP</small>
          </label>
        </Control>
      </Field>
      <Field>
        <Control>
          <Button loading={disabled} submit onClick={login}>Inloggen</Button>
        </Control>
      </Field>
    </FormPage>
  );
};
