import React from 'react';
import Button from 'react-bulma-components/cjs/components/button';
import NotificationMessage from 'react-bulma-components/cjs/components/notification';
import Container from 'react-bulma-components/cjs/components/container';

export const Notification = ({ color, onRemove, children }) => {

  return (
    <NotificationMessage color={color}>
      <Container>{children}</Container>
      {onRemove && <Button remove onClick={onRemove}/>}
    </NotificationMessage>
  );
};
