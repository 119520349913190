import React, { useEffect, useState } from 'react';
import Level from 'react-bulma-components/cjs/components/level';
import {
  LoadingListContainer,
  PageContainer,
  RefreshButton,
  SelectControl,
  ToolBarSide,
} from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsersList, selectUsersListStatus } from './ducks';
import { usersFetchListOp } from './ducks/operations';
import { UserItem } from './UserItem';
import { selectAuthUser } from '../auth/ducks';
import { checkRolePermit, rolesSelectOptions } from './roles';
import { CreateUserButton } from './CreateUserButton';

const ToolBar = ({ filter, onFilter, onRefresh }) => (
  <Level className="toolbar">
    <ToolBarSide>
      <SelectControl value={filter} options={[{ label: 'Alles', value: ''}].concat(rolesSelectOptions)}
        onChange={onFilter}/>
      <RefreshButton onRefresh={onRefresh}/>
    </ToolBarSide>
    <ToolBarSide align="right">
      <CreateUserButton/>
    </ToolBarSide>
  </Level>
);

export const UsersTab = () => {
  const { role } = useSelector(selectAuthUser);
  const list = useSelector(selectUsersList);
  const status = useSelector(selectUsersListStatus);
  const [filter, setFilter] = useState('');
  const dispatch = useDispatch();
  const loadUsers = () => dispatch(usersFetchListOp(filter));

  useEffect(() => {
    loadUsers();
  }, [filter]);

  if (!checkRolePermit(role, UsersTab.maxRoleLevel)) {
    return <PageContainer>Unauthorized</PageContainer>;
  }
  return (
    <PageContainer toolbar={<ToolBar filter={filter} onFilter={setFilter} onRefresh={loadUsers}/>}>
      <LoadingListContainer
        items={list} status={status}
        mapFunc={item => <UserItem {...{ ...item, key: item.name }}/>}/>
    </PageContainer>
  );
};

UsersTab.maxRoleLevel = 2;
