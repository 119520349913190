import React, { useCallback } from 'react';
import { selectionAddAction, selectionRemoveAction, selectSelectedItems } from './ducks';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ItemsCheckBox.css';

export const ItemsCheckBox = ({ controlItems, group = 'default' }) => {
  const selectedItems = useSelector(selectSelectedItems);
  const dispatch = useDispatch();

  const checked = (selectedItems[group] && selectedItems[group].items) ? 
    selectedItems[group].items.some(id => controlItems && controlItems.some(x => x.id === id)) : false;

  const element = useCallback(el => {
    if(controlItems.length > 1 && el !== null) {
      el.indeterminate = (checked  && selectedItems[group].items.length !== controlItems.length);
    }
  }, [checked, selectedItems && selectedItems[group] && selectedItems[group].items, controlItems]);

  if (!controlItems || controlItems.length < 1 ) {
    return null;
  }

  return (
    <input type="checkbox" checked={checked} className={styles.checkbox} ref={element}
      onChange={() =>
        dispatch((checked ? selectionRemoveAction : selectionAddAction)({controlItems, group}))
      }>
    </input>
  );
};
