import { activateUser, loginUser, signIn, signOut } from 'services/auth';
import { authUserAction } from './actions';


export const authSignInOp = (user, token ) => async (dispatch, getState, { stream }) => {

  //if no token then the user is already signed in
  if(token) {
    await signIn(token);
  }
  await dispatch(authUserAction(user));
  stream.reconnect({ dispatch, getState });
};

export const authLogInUserOp = (userName, password, otp) => async dispatch => {
  
  const { email, name, role, token } = await loginUser(userName, password, otp);

  await dispatch(authSignInOp({ name, email, role }, token));
};


export const authActivateUserOp = (emailParam, password, otp, code) => async dispatch => {
  const {
    email, name, role, token,
  } = await activateUser(emailParam, password, otp,  code);

  await dispatch(authSignInOp({ name, email, role }, token));
};

export const authLogOutUserOp = () => async (dispatch, getState, { stream }) => {
  await signOut();
  await dispatch(authUserAction({}));
  stream.close();
};
