import { initializeApp } from 'firebase/app';
import {
  getAuth,
  getIdToken as firebaseGetIdToken, getIdTokenResult,
  onAuthStateChanged,
  signInWithCustomToken,
  signOut as firebaseSignOut,
} from 'firebase/auth';
import axios from 'axios';
import React from 'react';

const userApi = axios.create({ baseURL: process.env.USER_HOST, withCredentials: true });
const parseError = e => e.response ? e.response : { status: '', statusText: e.toString() };

const FIREBASE_CONFIG = {

  // @TODO: empty api key is failing tests
  apiKey: process.env.FIREBASE_API_KEY || 'AIzaSyDlsTplscGUvKuUP1iWljt-ej5oxJWiGt4',
  projectID: process.env.FIREBASE_PROJECT_ID,
};


const firebase = initializeApp({

  apiKey: FIREBASE_CONFIG.apiKey,
  authDomain: 'dumpert.firebaseapp.com',
  databaseURL: 'https://dumpert.firebaseio.com',
  projectId: FIREBASE_CONFIG.projectID,
  storageBucket: 'dumpert.appspot.com',
  messagingSenderId: '988298271238',
  appId: '1:988298271238:web:2abeb8f128b74ad48a4187',

});

const authClient = getAuth(firebase);

let idToken = '';
export const getIdTokenSync = () => {
  if (idToken === '') {
    throw new Error('user must be authorized when call getIdTokenSync');
  }
  return idToken;
};
export const signIn = async token => {
  const { user } = await signInWithCustomToken(authClient, token);
  if (user) {
    idToken = await getIdToken();
  }
};
export const signOut = () => firebaseSignOut(authClient);
export const listenAuthStateChanged = listener => onAuthStateChanged(authClient, listener);
const getIdToken = () =>
  authClient.currentUser ? firebaseGetIdToken(authClient.currentUser) : Promise.resolve('');

// get claims on client side can be used instead of calling refresh token endpoint
// roles claims are numbers and must be converted to string representation
export const getClaims = async refresh => {
  const { currentUser } = authClient;
  if (currentUser) {
    const { claims, token } = await getIdTokenResult(currentUser, refresh);
    idToken = token;
    return claims;
  }
  return {};
};

export const authorizedURL = url => {
  if (!url) {
    return url;
  }
  const newURL = new URL(url.startsWith('/') ? `${window.location.origin}${url}` : url);
  newURL.searchParams.append('access_token', getIdTokenSync());
  return newURL.href;
};

export const createAuthHeaders = () => ({ Authorization: `Bearer ${getIdTokenSync()}` });

export const authorizeRequest = async cfg => {
  idToken = await getIdToken();
  if (idToken) {
    return {
      ...cfg,
      headers: { ...cfg.headers, ...createAuthHeaders() },
    };
  }
  return cfg;
};

export const loginUser = async (username, password, otp) => {
  try {
    const { data } = await userApi.post('/login-like-a-boss', { username, password, otp });
    return data;
  } catch (e) {
    throw parseError(e);
  }
};

export const activateUser = async (email, password, otp,  activation_code) => {
  try {
    const { data } = await userApi.post('/validate-mod',
      { email, password, otp, activation_code });
    return data;
  } catch (e) {
    throw parseError(e);
  }
};

function QRCodeImage(props) {
  return (
    <img src={`data:image/png;base64,${props}`} alt="QR Code" />
  );
}

export const loadQRCodeImage = async (email, code) => {
  try {
    const response = await userApi.get(`/get-qrcode/${email}/${code}`, {
      responseType: 'json',
      headers: {
        Accept: 'application/json',
      },
    });
    const { qrcode } = response.data;
    return QRCodeImage(qrcode);
  } catch (e) {
    throw parseError(e);
  }
};

