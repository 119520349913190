// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field--uM0iYG {
    align-items: baseline;
}
    .field--uM0iYG button {
        margin-left: 0.02rem;
    }
    .field--uM0iYG label {
        margin-right: 0.5rem;
    }
    .field--uM0iYG .control {
            flex: 1;
        }
`, "",{"version":3,"sources":["webpack://./src/components/common/TextInputControl.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AAYzB;IAXI;QACI,oBAAoB;IACxB;IACA;QACI,oBAAoB;IACxB;IAEI;YACI,OAAO;QACX","sourcesContent":[".field {\n    align-items: baseline;\n    button {\n        margin-left: 0.02rem;\n    }\n    label {\n        margin-right: 0.5rem;\n    }\n    :global {\n        .control {\n            flex: 1;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `field--uM0iYG`
};
export default ___CSS_LOADER_EXPORT___;
