// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datepicker--HOWGdv {
    margin-left: 0.2rem;
    width: 6.18rem;
}`, "",{"version":3,"sources":["webpack://./src/components/common/DateRangeSelect.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".datepicker {\n    margin-left: 0.2rem;\n    width: 6.18rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datepicker": `datepicker--HOWGdv`
};
export default ___CSS_LOADER_EXPORT___;
