import React, { useCallback, useState } from 'react';
import 'twin.macro';
import { Button } from '../common';
import { useQueryCommandIsSupported } from './hooks';
import { copyTextToClipboard } from '../utils';
import {
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  ExternalLinkIcon,
  SaveIcon,
  SpinnerIcon,
  LinkIcon,
  PublishIcon,
} from 'icons';
import { useSelector } from 'react-redux';
import { selectShowNotification } from '../store';
import { useHistory } from 'react-router-dom';

const saveButtonState = {
  unsaved: SaveIcon,
  saved: CheckIcon,
  saving: SpinnerIcon,
  deleting: SaveIcon,
  publishing: SaveIcon,
};


export const CloseButton = p =>
  <Button label='Sluiten' Icon={CloseIcon} {...p} />;

export const UrlButton = ({ label, Icon, ...p }) => <Button 
  label={label} 
  Icon={Icon} 
  tw="w-5/12 md:w-auto mr-2 mb-2 md:mr-1 md:mb-0" {...p} 
/>;

export const DeleteButton = ({ state, ...p }) => {
  const Icon = state === 'deleting' ? SpinnerIcon : DeleteIcon;
  return <Button label='Verwijderen' Icon={Icon} tw="w-5/12 md:w-auto mb-2 md:mr-1 md:mb-0" {...p} />;
};

export const DirectOnlineButton = ({ state, ...p }) => {
  const Icon = state === 'publishing' ? SpinnerIcon : PublishIcon;
  return <Button label='Direct online' Icon={Icon} tw="w-5/12 md:w-auto mr-2 md:mr-1" {...p} />;
};

export const PreviewButton = p => 
  <Button label='Preview' Icon={ExternalLinkIcon} tw="w-5/12 md:w-auto mr-2 md:mr-1" {...p} />;

export const SaveButton = ({ source, state = 'unsaved', ...p }) => {
  const Icon = saveButtonState[state];
  return <Button label={source === 'incoming' ? 'Post' : 'Opslaan'} Icon={Icon} tw="w-5/12 md:w-auto" {...p} />;
};

export const EditButtons = ({
  publish, data, source, editState, save, remove, disabled, createPostFromUpload,
}) => {

  const copyIsSupported = useQueryCommandIsSupported();
  const [copySuccess, setCopySuccess] = useState(false);
  const showNotification = useSelector(state => selectShowNotification(state, 'saveSuccess'));
  const history = useHistory();

  const close = useCallback(() => {
    if (source && source === 'incoming') {
      history.push({
        pathname: '/uploads',
        state: { next: history.location.pathname },
      });
    } else {
      window.history.back();
    }
  }, [source, history]);

  return (
    <div tw="max-w-screen-xl mx-auto justify-between px-2 md:px-6 pt-8 md:flex">
      <div>
        <CloseButton onClick={close}/>
      </div>
      <div tw="flex items-center text-green-600">
        {showNotification && <span tw="animate-pulse">
          Lekker bezig! Jouw wijzigingen zijn succesvol opgeslagen.
        </span>}
      </div>
      <div tw="flex flex-wrap mt-2 md:mt-0">
        {copyIsSupported && (              
          <UrlButton 
            onClick={() => {
              copyTextToClipboard(window.location.href, () => {
                setCopySuccess(true);
                setTimeout(() => {
                  setCopySuccess(false);
                }, 2000); 
              });
            }}
            label={copySuccess ? 'gekopieerd' : 'Url kopiëren'} 
            Icon={copySuccess ? CheckIcon : LinkIcon} 
          />
        )} 
        {data && <DeleteButton onClick={remove} state={editState} disabled={disabled}/>}
        {data && data.post && data.post.id &&
          <PreviewButton onClick={
            () => window.open(`${process.env.DUMPERT_HOST}/item/${data.post.id}`)
          } disabled={disabled}/>}
        {data && !data.isOnline && source !== 'archived' &&
        <DirectOnlineButton onClick={publish} state={editState} disabled={disabled}/>}
        {data && <SaveButton onClick={source === 'accepted' ? save : createPostFromUpload }
          source={source} state={editState} disabled={editState !== 'unsaved'}/>}
      </div>
    </div>
  );
}; 