import React, { useEffect, useLayoutEffect, useState } from 'react';
import tw from 'twin.macro';
import { useHistory, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import {
  ActionPopUpButton,
  Button,
  CheckBox,
  FormField,
  Input,
  Label,
  Notification,
  PageContainer,
  RadioGroup,
  RadioItem,
  TextArea,
} from '../common';
import 'react-datepicker/dist/react-datepicker.css';
import { StrongLabel } from './Conponents';
import {
  getLogs,
  ban_user,
  bypass_newbie,
  change_username,
  confirm_email,
  delete_user,
  getMailgunStatus,
  getUserData,
  setUserRole,
  subscribeModerator,
  updateUser,
} from 'services/user';
import { getUserComments, hide_all_comments, wegjorissen_all_comments } from 'services/comment';
import { parseDate, parseDateRFC3339 } from '../utils';

const FormDivider = () => <div tw="w-full border-t my-2 border-gray-300"></div>;
const ShadowBox = ({ children, ...r }) => <div 
  tw="bg-white shadow sm:rounded-lg shadow p-4" {...r}>
  {children}
</div>;

const inputFields = [
  {type: 'text', id: 'username', label: 'Username'},
  {type: 'email', id: 'email', label: 'Email'},
  {type: 'text', id: 'first_name', label: 'First Name'},
  {type: 'text', id: 'last_name', label: 'Last Name'},
  {type: 'email', id: 'birthday', label: 'Date of Birth'},
  {type: 'text', id: 'created_at', label: 'Created At'},
  {type: 'text', id: 'last_updated', label: 'Last Updated' }];

export const DummyPage = () => {
  const { id, commentId } = useParams();
  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  const [userData, setUserData] = useState(null);
  const [userApiData, setUserApiData] = useState(null);
  const [notification, setNotification] = useState(null);
  const [userComments, setUserComments] = useState(null);
  const [mailgunStatus, setMailgunStatus] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [banReason, setBanReasonContent] = useState('');
  const [selectedCommentIds, setSelectedCommentIds] = useState([]);
  const [commentsPage, setCommentsPage] = useState(0);
  const [secretClickRole, setSecretClickRole] = useState(0);

  const [LogsDaysAgo, setLogsDaysAgo] = useState(1);
  const [LogsOperation, setLogsOpertaion] = useState('login');






  const loadUserData = async (id) =>  { 
    await getUserData(id)
      .then(response => {
        response.data.stateHolder = response.data.commentary_state;
        if (response.data.commentary_state !== 'permaban' && commentId) {
          response.data.commentary_state = 'tempban';
        }

        setUserData(response.data);
        setUserApiData(response.data);


      })
      .catch(error => setNotification({ color: 'danger', message: error.response.data }));
  };

  const handTextleLogsDays = (event) => {
    setLogsDaysAgo(parseInt(event.target.value, 10) || 0);
  };

  const handleChangeLogOps = (event) => {
    setLogsOpertaion(event.target.value);
  };


  const handleCheckLogs = async () => {
    const endpointResponse = await getLogs(LogsDaysAgo, 
      `jsonPayload.request_email: "${userData.email}"`, 
      LogsOperation);
    
    setMailgunStatus(endpointResponse.data);
  };

  const loadUserDataRefresh = async (id) =>  { 
    await getUserData(id)
      .then(response => {
        setUserData(response.data);
        setUserApiData(response.data);
      })
      .catch(error => setNotification({ color: 'danger', message: error.response.data }));
  };

  useEffect(() => {
    loadUserData(id);
  }, [id]);

  useEffect(() => {
    const currentDate = new Date();
    const oneWeekLater = new Date(currentDate);
    oneWeekLater.setDate(currentDate.getDate() + 7);    
    setSelectedDate(oneWeekLater);
  }, []);

  useEffect(() => {
    document.title = 'Dumpert - Moderation'; 
    getUserComments(id, commentsPage)
      .then(response => {
        const commentsWithCheckbox = response.data.map(comment => ({
          ...comment,
          checked: comment.id === Number(commentId),
        }));
        setUserComments(commentsWithCheckbox);
      });
  }, [id]);

  useLayoutEffect(() => { 
    document.title = 'Dumpert - Moderation'; 
    return () => document.title = 'Dumpert - Admin';
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  }, [notification]);

  const handleMailgunStatus = async () => {
    const endpointResponse = await getMailgunStatus(userData.email);
    setMailgunStatus(endpointResponse.data);
  };
  
  const handleCheckboxChange = (commentId) => {
    const isSelected = selectedCommentIds.includes(commentId);
    const newSelectedCommentIds = isSelected
      ? selectedCommentIds.filter(id => id !== commentId)
      : [...selectedCommentIds, commentId];

    setSelectedCommentIds(newSelectedCommentIds);
    const updatedComments = userComments.map(comment => {
      if (comment.id === commentId) {
        return {
          ...comment,
          checked: !isSelected,
        };
      }
      return comment;
    });
    setUserComments(updatedComments);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allCommentIds = userComments.map(comment => comment.id);
      setSelectedCommentIds(allCommentIds);
      setSelectAll(true);
    } else {
      setSelectedCommentIds([]);
      setSelectAll(false);
    }
  };

  const handlePrevious = () => {	

    getUserComments(id,  Math.max(0, commentsPage - 1))	
      .then(response => {	
        const commentsWithCheckbox = response.data.map(comment => ({	
          ...comment,	
          checked: comment.id === Number(commentId),	
        }));	
        setUserComments(commentsWithCheckbox);	
      });
    setCommentsPage( Math.max(0, commentsPage - 1));	
  };	

  const handleNext = () => {	
    getUserComments(id, commentsPage + 1)	
      .then(response => {	
        const commentsWithCheckbox = response.data.map(comment => ({	
          ...comment,	
          checked: comment.id === Number(commentId),	
        }));	
        setUserComments(commentsWithCheckbox);	
      });	
    setCommentsPage( commentsPage + 1);	
  };

  const handleUpdate = async () => {
    await updateUser(id, userData);
    setNotification({ color: 'success', message: 'user updated' });
  };

  const handleBypass = async () => {
    await bypass_newbie(id);
    setNotification({ color: 'success', message: 'bypassed zwitsal status' });
  };

  const handleConfirm = async () => {
    await confirm_email(userData);
    setNotification({ color: 'success', message: 'email confirmed' });
  };

  const handleDelete = () => {
    delete_user(id, userData);
  };

  const handleChangeUsername = async () => {
    await change_username(id, userData);
    setNotification({ color: 'success', message: 'username changed' });
  };

  const handleHideComments = async () => {
    await hide_all_comments(id, selectedCommentIds);
    setNotification({ color: 'success', message: 'All comments hidden' });
  };

  const handleWegjorissenComments = async () => {
    await wegjorissen_all_comments(id, selectedCommentIds);
    setNotification({ color: 'success', message: 'All comments weggejorist' });
  };



  const handleChangRole = async () => {
    await setUserRole(id, userData.role.String);
    setNotification({ color: 'success', message: 'Role changed' });
  };

  const handleSubscribe = () => {
    subscribeModerator(id);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleBanReasonContent = (event) => {
    setBanReasonContent(event.target.value);
  };

  const incrementRoleSecretCounter = () => {
    setSecretClickRole(secretClickRole + 1);
  };

  const handleUserBan = async ({ isHourlyBan = false }) => {
    const isSingleComment = commentId && userComments.find(comment => comment.id === Number(commentId));
    const commentContent = isSingleComment 
      ? isSingleComment.content 
      : '';
    const commentLink = isSingleComment 
      ? process.env.DUMPERT_HOST + '/item/' + isSingleComment.link + '#comment' + commentId
      : '';
    const date = new Date();
    date.setHours(date.getHours() + 1);
    const setBanDate = isHourlyBan ? date : selectedDate;


    await ban_user(
      id, 
      userData, 
      setBanDate, 
      banReason, 
      commentLink,
      commentContent,
    ); 
  };

  const handleBan = async ({ isHourlyBan = false }) => {
    await handleUserBan({ isHourlyBan }).then(() => {
      setTimeout(async () => {
        await loadUserDataRefresh(id).then(() => {
          setNotification({ color: 'success', message: 'user ban status updated' });
        });
      }, 1000);
    });
  };

  if (!userData || !userApiData) {
    return <div>Loading...</div>;
  }

  const displayValue = userApiData.stateHolder !== undefined ? userApiData.stateHolder : userApiData.commentary_state;

  return (
    <PageContainer toolbar={<ToolBar toolbarFunctions={{
      handleClick,
      handleUpdate,
      handleChangeUsername,
      handleBypass,
      handleConfirm,
      handleDelete,
      handleMailgunStatus,
    }}/>}> 
      {notification !== null && 
        <div tw='fixed right-10 top-36 z-50'>
          <Notification 
            color={notification.color}
            onRemove={() => setNotification(null)}   
          >
            <p>{notification.message}</p>
          </Notification>
        </div>
      }    
      <div tw="mt-4 max-w-3xl flex flex-col mx-auto gap-6  lg:max-w-7xl ">
        <div tw='flex flex-col lg:flex-row w-full gap-6'>
          <ShadowBox tw='lg:w-2/3'>
            {(secretClickRole>2) && <>
              <div tw="flex items-center gap-2 flex-wrap">
                <div tw="flex items-center">
                  <Label label="Role" htmlFor="userRole"/>
                  <div className="select is-small pl-2">
                    <select id="userRole" value={userData.role.String}  
                      onChange={e => setUserData({ ...userData, role: { String: e.target.value, Valid: true} })}>
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                </div>
                <div tw='flex items-center gap-2'>
                  <Button label="Change" onClick={handleChangRole} />
                  <Button label="Subscribe" onClick={handleSubscribe} />
                </div>
              </div>
              <FormDivider />
            </> }

            <div tw='flex gap-6'>
              <div tw='flex-1'>
                {inputFields.slice(0, 5).map(field => <FormField 
                  key={field.id}
                  label={field.label}
                  name={userData[field.id]}
                  placeholder={userData[field.id]}
                  autoComplete="off"
                  value={field.id === 'birthday' 
                    ? userData[field.id].split('-').reverse().join('-') 
                    : userData[field.id]}  
                  id={field.id}              
                  onChange={e => setUserData({ ...userData, [field.id]: field.id === 'birthday' 
                    ? e.target.value.split('-').reverse().join('-') 
                    : e.target.value,
                  }, 
                  )}
                  FieldType={Input}
                  tw='mb-2'
                  disabled={field.id === 'birthday'}
                />,
                )}
              </div>
              <div tw='flex-1'>
                {inputFields.slice(5).map(field => 
                  <FormField 
                    key={field.id}
                    label={field.label}
                    name={userData[field.id]}
                    placeholder={userData[field.id]}
                    autoComplete="off"
                    value={field.id === 'created_at' || field.id === 'last_updated' 
                      ? parseDateRFC3339(userData[field.id])
                      : userData[field.id]
                    }  
                    id={field.id}              
                    onChange={e => setUserData({ ...userData, [field.id]: e.target.value })}
                    FieldType={Input}
                    tw='mb-2'
                    disabled={field.id === 'created_at' || field.id === 'last_updated'}

                    //tw='bg-gray-900 text-gray-50 focus:ring-brand'
                  />,
                )}                
                <RadioGroup tw='mt-2'>
                  <Label label="Gender" htmlFor="gender"/>
                  <RadioItem 
                    label='M' 
                    name='gender'
                    value='M'
                    id='male'
                    checked={userData.gender === 'M'}
                    onChange={e =>setUserData({ ...userData, gender: e.target.value })}
                  />
                  <RadioItem 
                    label='V' 
                    name='gender'
                    value='V'
                    id='female'
                    checked={userData.gender === 'V'}
                    onChange={e =>setUserData({ ...userData, gender: e.target.value })}
                  />
                  <RadioItem 
                    label='NA' 
                    name='gender'
                    value='na'
                    id='notavailable'
                    checked={userData.gender === 'na'}
                    onChange={e =>setUserData({ ...userData, gender: e.target.value })}
                  />
                </RadioGroup>

                <FormDivider />

                <div tw='pl-2'>
                  <CheckBox 
                    label="Lives in Netherlands" 
                    name="lives_in_nl"
                    checked={userData.lives_in_nl} 
                    disabled
                  />
                  <CheckBox 
                    label="Active" 
                    name="active"
                    checked={userData.active === true} 
                    onChange={e =>setUserData({ ...userData, active: e.target.checked })}
                  />
                  <CheckBox 
                    label="Confirmed" 
                    name="confirmed"
                    id="confirmed"
                    checked={userData.confirmed !== 1} 
                    onChange={e => setUserData({...userData, confirmed: e.target.checked ? 2 : 1})}
                  />

                  <ul>
                    <li><p> - - - - - - - - - </p></li>
                    <li><label htmlFor="myDropdown">Operation:</label>
                      <select id="myDropdown" value={LogsOperation} onChange={handleChangeLogOps}>
                        <option value="">--</option>
                        <option value="login">login</option>
                        <option value="register">register</option>
                      </select>
                    </li>

                    <li><lable htmlFor="myTextField">DaysAgo:</lable>
                      <input
                        id="myTextField"
                        type="text"
                        value={LogsDaysAgo}
                        onChange={handTextleLogsDays}
                        style={{border: '1px solid #4CAF50'}}/></li>
                  </ul>

                  <Button label="CheckLogs" onClick={handleCheckLogs} tw='bg-black text-white hover:text-black' />

                </div>
              </div>
            </div>
          </ShadowBox>

          <ShadowBox tw='lg:w-1/3' >
            <div tw='flex gap-2 items-center'>
              <div onClick={incrementRoleSecretCounter}>
                <StrongLabel text={'Current Status:'} />
              </div>
              <span css={[
                tw`px-1.5 py-0.5 rounded-md`,
                displayValue === 'ok' && tw`bg-brand`,
                displayValue === 'tempban' && tw`bg-[orange]`,
                displayValue === 'permaban' && tw`bg-[red]`,
                displayValue === 'shadowban' && tw`bg-[gray]`,
              ]}>
                {displayValue.toUpperCase()}
              </span>
              {(userApiData.commentary_state === 'tempban' && userData.ban_history) && <span>     
                until {parseDateRFC3339(userData.commentary_end_ban)}
              </span>}
            </div>
            <FormDivider />
            <RadioGroup tw='-ml-2'>
              <RadioItem 
                label='ok' 
                name='ok'
                value='ok'
                id='ok'
                checked={userData.commentary_state === 'ok'} 
                onChange={e => setUserData({ ...userData, commentary_state: e.target.value })}
              />
              <RadioItem 
                label='tempban' 
                name='tempban'
                value='tempban'
                id='tempban'
                checked={userData.commentary_state === 'tempban'} 
                onChange={e => setUserData({ ...userData, commentary_state: e.target.value })}
              />
              <RadioItem 
                label='permaban' 
                name='permaban'
                value='permaban'
                id='permaban'
                checked={userData.commentary_state === 'permaban'} 
                onChange={e => setUserData({ ...userData, commentary_state: e.target.value })}
              />
              <RadioItem 
                label='shadowban' 
                name='shadowban'
                value='shadowban'
                id='shadowban'
                checked={userData.commentary_state === 'shadowban'} 
                onChange={e => setUserData({ ...userData, commentary_state: e.target.value })}
              />
            </RadioGroup>

            <FormDivider />
            <div>
              {userData.commentary_state === 'ok' && !commentId ? (
                <StrongLabel text="Unban Reason" />
              ) : userData.commentary_state === 'tempban' 
              || (userData.commentary_state !== 'permaban' && commentId) ? (
                  <>
                    <StrongLabel text="Ban Until" />
                    <DatePicker 
                      selected={selectedDate} 
                      onChange={handleDateChange} 
                      tw='border border-gray-300 rounded-md w-full p-2 mb-2' />
                    <br />
                    <StrongLabel text="Tempban Reason" />
                  </>
                ) : (
                  <StrongLabel text="Permaban Reason" />
                )}
              <TextArea id="banReason" onChange={handleBanReasonContent} tw='my-2'/> 
            </div>
            <ActionPopUpButton label="Save change" onClick={handleBan} tw='bg-brand' disabled={banReason.length < 4}/>
            <ActionPopUpButton label="Hourly Ban" onClick={() => handleBan({ isHourlyBan: true })} 
              tw='bg-brand' disabled={userData.commentary_state !== 'tempban'}/>
            {userData.ban_history &&
              <pre tw='max-h-[200px] overflow-y-scroll mt-2'>
                { userData.ban_history.map((item) => (
                  <div key={item.id} tw='pb-2 mb-2 border-b'> 
                    <StrongLabel text={`${item.commentary_state} by ${item.changed_by_user_username}`}/>
                    <Label label={parseDateRFC3339(item.changed_at)}/>
                    <Label label={`Reason given: ${item.change_reason.String}`}/>
                  </div>
                )).reverse()}
              </pre>
            }
          </ShadowBox>
        </div>
  
        <ShadowBox>
          <div tw='flex gap-2 justify-end mb-2'>
            <Button label="Hide all comments" onClick={handleHideComments} tw='bg-[#759FF3]' />
            <Button label="Wegjorissen all comments" onClick={handleWegjorissenComments} tw='bg-[tomato]' />
          </div>
          {JSON.stringify(mailgunStatus, null, 2) !== 'null' && 
            <pre tw='my-2'>{JSON.stringify(mailgunStatus, null, 2)}</pre>
          }
          <div tw='overflow-x-scroll'>
            <table tw='w-full table-auto rounded-xl '>
              <thead>
                <tr tw='bg-gray-800 py-2 '>
                  <th tw='pt-0.5 pl-1 pr-2'>
                    <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                  </th>
                  <th tw='p-1 text-white'>Content</th>
                  <th tw='p-1 text-white'>IP</th>
                  <th tw='p-1 text-white'>Article ID</th>
                  <th tw='p-1 text-white'>Date</th>
                </tr>
              </thead>
              <tbody>              
                {userComments && userComments.map(item => (
                  <tr key={item.id} css={[
                    tw`py-2 border-b`,
                    !item.approved && tw`bg-[orange] bg-opacity-30`,
                    item.banned && tw`bg-[tomato] bg-opacity-30`,
                  ]}>
                    <td tw='py-1.5 pl-1 pr-2'>
                      <input
                        type="checkbox"
                        checked={selectedCommentIds.includes(item.id) || item.checked}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                    </td>
                    <td tw='p-1'>{item.content}</td>
                    <td tw='p-1'>{item.ip}</td>
                    <td tw='p-1'>
                      <button
                        onClick={() => window.open(`${process.env.DUMPERT_HOST}/item/${item.link}`)}
                        style={{ marginRight: '5px' }}
                      >
                        {item.title}
                      </button>
                    </td>
                    <td tw='whitespace-nowrap p-1'>{parseDate(item.date, {
                      hour: '2-digit',
                      minute: '2-digit',
                      day: '2-digit',
                      month: '2-digit',
                      year: '2-digit',
                    })}</td>
                  </tr>    
                ))}
              </tbody>
            </table>
          </div>
        </ShadowBox>
        <div>	
          <button onClick={handlePrevious} disabled={commentsPage === 0}>	
            Previous	
          </button>	
          <span> {commentsPage} </span>	
          <button onClick={handleNext}>Next</button>	
        </div>
      </div>
    </PageContainer>
  );
};


const ToolBar = ({ toolbarFunctions }) => {
  const {
    handleClick,
    handleUpdate,
    handleChangeUsername,
    handleBypass,
    handleConfirm,
    handleDelete,
    handleMailgunStatus,
  } = toolbarFunctions;
  return (
    <div tw='flex gap-2'>
      <Button label="Go Back" onClick={handleClick} />
      <Button label="Save changes" onClick={handleUpdate} tw='bg-brand' />
      <Button label="Change Username" onClick={handleChangeUsername}  />
      <Button label="BypassNewbie" onClick={handleBypass}  />
      <Button label="Confirm email" onClick={handleConfirm}  />
      <Button label="Delete user" onClick={handleDelete} tw='bg-[tomato]' />
      <Button label="Mailgun Status" onClick={handleMailgunStatus} tw='bg-black text-white hover:text-black' />
    </div>
  );
};
