import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from 'redux';

import { withExtraArgument } from 'redux-thunk';

import { createStreamSource } from 'services/current';

import {
  authInitialState,
  authReducer as auth,
} from '../components/auth/ducks';

import {
  usersInitialState,
  usersReducer as users,
} from '../components/users/ducks';

import {
  currentInitialState,
  currentReducer as current,
} from '../components/current/ducks';

import {
  archiveInitialState,
  archiveReducer as archive,
} from '../components/archive/ducks';

import {
  postsInitialState,
  postsReducer as posts,
} from '../components/posts/ducks';

import {
  selectionInitialState,
  selectionReducer as selection,
} from '../components/selection/ducks';

const messageInitialState = {
  message: null,
  notifications: [],
};

const message = (state = messageInitialState, { type, payload}) => {
  switch (type) {
  case 'SET_MESSAGE':
    return { ...state, message: payload};
  case 'ADD_NOTIFICATION':
    return { ...state, notifications: [...state.notifications, payload] };
  case 'REMOVE_NOTIFICATION':
    return { ...state, notifications: state.notifications.filter(x => x.id !== payload) };
  }
  return state;
};

const composeEnhancers = (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;


export const createReduxStore = () => {

  const store = createStore(
    combineReducers({ auth, users, current, selection, archive, posts, message }),
    {
      auth: authInitialState,
      users: usersInitialState,
      posts: postsInitialState,
      current: currentInitialState,
      archive: archiveInitialState,
      selection: selectionInitialState,
      message: messageInitialState,
    }, composeEnhancers(applyMiddleware(withExtraArgument({
      stream: createStreamSource(),
    }))),
  );

  return store;
};

export const setMessageAction = payload => ({ type: 'SET_MESSAGE', payload });
export const addNotificationAction = payload => ({ type: 'ADD_NOTIFICATION', payload });
export const removeNotificationAction = payload => ({ type: 'REMOVE_NOTIFICATION', payload });
export const selectMessage = state => state.message.message;
export const selectShowNotification = (state, id) => state.message.notifications.find(x => x.id === id);
export const selectShowNotifications = state => state.message.notifications;

const timeouts = {};
export const showFlashNotificationOp = (id, text) => dispatch => {
  if(timeouts[id]) {
    clearTimeout(timeouts[id]);
    dispatch(removeNotificationAction(id));
  }
  dispatch(addNotificationAction({ id, text}));
  timeouts[id] = setTimeout(() => dispatch(removeNotificationAction(id)), 5000);
};
