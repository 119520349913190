import { setMessageAction } from '../store';
import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfirmButtons } from './ConfirmButtons';
import 'twin.macro';

export const useUnmounted = () => {
  const unmounted = useRef(false);
  useEffect(() => () => {
    unmounted.current = true;
  }, []);
  return unmounted;
};


export const useShowErrorMessage = () => {
  const dispatch = useDispatch();
  return e => {
    if (e.response) {

      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const title = e.response.data.title || e.response.statusText || 'Error';
      let text = e.response.data.message || e.response.data.detail || e.response.data || e.toString();
      text = text.charAt(0).toUpperCase() + text.slice(1);

      if (!text) {
        text = 'Something went wrong';
      }

      dispatch(setMessageAction({ text: text, title: title, color: 'danger' }));
    } else if (e.request) {
      dispatch(setMessageAction({ text: e.toString(), title: 'Error', color: 'danger' }));
    } else {

      // Something happened in setting up the request that triggered an Error
      dispatch(setMessageAction({ text: e.toString(), title: 'Error', color: 'danger' }));
    }
  };
};


export const useURLParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useConfirmDialog = (onConfirm, withComment = true) => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(setMessageAction({
    text: <div tw="text-center w-full">Yo, weet je het absoluut zeker?</div>,
    hideCloseButton: true,
    options: <ConfirmButtons onConfirm={onConfirm} withComment={withComment} />,
  })), [onConfirm]);
};

export const useFetchData = (serviceMethod, reloadEvery, params = {}, callback = null) => {
  const unmounted = useUnmounted();
  const [data, setData] = useState(null);
  const [admins, setAdmins] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const setResult = (d) => {
    if (d.items) {
      setData(d.items);
      setAdmins(d.admins);
    } else {

      // for backward compability
      setData(d);
    }
  };

  const fetch = (showSpinner = true) => {
    if (showSpinner) {
      setError(null);
      setData(null);
      setLoading(true);
    }

    serviceMethod(params)
      .then(d => unmounted.current || setResult(d))
      .catch(e => unmounted.current || setError(e))
      .finally(() => {
        unmounted.current || setLoading(false);
        if (callback) {
          callback();
        }
      });

  };

  useEffect(() => {
    fetch();
    const i = setInterval(() => fetch(false), reloadEvery);
    return () => clearInterval(i);
  }, [JSON.stringify(params || {})]);

  return [data, admins, loading, error, fetch];
};
