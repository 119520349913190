import React, { useCallback } from 'react';
import Box from 'react-bulma-components/cjs/components/box';
import Columns from 'react-bulma-components/cjs/components/columns';
import { getRoleDisplayName } from './roles';
import { parseDateRFC3339 } from '../utils';
import { useDispatch } from 'react-redux';
import { usersDeleteOp } from './ducks/operations';
import { useConfirmDialog } from '../common/hooks';


export const UserItem = ({ email, last_login, name, role, onClick }) => {
  const dispatch = useDispatch();
  const deleteUser = useConfirmDialog(
    useCallback(() => dispatch(usersDeleteOp(email)), [email]),
    false,
  );
  return (
    <Box onClick={onClick}>
      <Columns>
        <Columns.Column size={3} textAlignment="centered">
          <p>{name}</p>
        </Columns.Column>
        <Columns.Column size={3} textAlignment="centered">
          <p>{email}</p>
        </Columns.Column>
        <Columns.Column size={2} textAlignment="centered">
          <p>{getRoleDisplayName(role)}</p>
        </Columns.Column>
        <Columns.Column size={3} textAlignment="centered">
          <p><small>{parseDateRFC3339(last_login)}</small></p>
        </Columns.Column>
        <Columns.Column size={1} textAlignment="right">
          <button className="delete" onClick={deleteUser}/>
        </Columns.Column>
      </Columns>
    </Box>
  );
};
