import React, { useState } from 'react';
import tw from 'twin.macro';

export const Label = ({ label, children, ...restProps }) => <label
  htmlFor={restProps.name} tw="block font-bold text-gray-700"
>
  <span>{label[0].toUpperCase() + label.slice(1)}:</span>
  {children}
</label>;


export const Input = ({ ...restProps }) => 
  <input
    css={[
      tw`border shadow-sm p-2 block w-full border-gray-300 rounded-md
    focus:ring-indigo-500 focus:border-indigo-500 focus:ring-1`,
      restProps.disabled && tw`bg-gray-200 text-gray-700`,
    ]}
    id={restProps.name}
    onChange={() => restProps.onChange}
    {...restProps}
  />;

export const TextArea = ({ ...restProps }) => 
  <textarea
    id={restProps.name}
    tw='border shadow-sm p-2 block w-full border-gray-300 rounded-md
    focus:ring-indigo-500 focus:border-indigo-500 focus:ring-1'
    onChange={() => restProps.onChange}
    {...restProps}
  />;

export const InputButton = ({ label, ...restProps }) => {

  const [text, setText] = useState('');

  const onClickHandler = () => {
    const { onPush } = restProps;
    restProps.name === 'pagenum' 
      ? onPush(parseInt(text))
      : onPush(text.toLowerCase());
    setText(''); 
  };

  const keyPressHandler = event => {
    if ((event.key === ' ' && label.toLowerCase() === 'tags') || event.key === 'Enter') {
      onClickHandler();
    }
  };

  return ( 
    <div tw="flex rounded-md shadow-sm">
      <div tw="relative flex items-stretch flex-grow focus-within:z-10">
        <Input 
          size="small" 
          value={text}
          id={label ? label : ''}
          placeholder={restProps.placeholder}
          onChange={({ target: { value } }) => setText(value)}
          onKeyPress={e => keyPressHandler(e)}
          tw="border block w-full rounded-none rounded-l-md"
        /> 
      </div>
      <button
        onClick={() => onClickHandler()}
        tw="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 
        border border-gray-300 rounded-r-md text-gray-400 bg-gray-50">
        {restProps.buttonLabel}
      </button>
    </div>
  );
};
    
export const FormField = ({ label, FieldType, ...restProps }) => (
  <>
    {label && (
      <Label label={label} {...restProps}>
        <FieldType label={label} {...restProps} tw="mt-1"/>
      </Label>
    )}
    {!label && (<FieldType {...restProps} />)}
  </>
);

export const RadioGroup = ({ children, ...restProps }) => <CheckBoxGroup { ...restProps }>{children}</CheckBoxGroup>;

export const RadioItem = ({ ...restProps }) => {
  return( <CheckBox type='radio' tw='ml-1.5' { ...restProps }/> );
};

export const CheckBox = ({ type = 'checkbox', ...restProps }) => {
  const { name, id, label, checked, onChange } = restProps;
  return(
    <div tw='relative flex items-center'>
      <div tw='flex items-center h-5'>
        <input 
          type={type}
          id={id ? id : name}
          name={name}
          checked={checked}
          tw='focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded cursor-pointer'
          onChange={e => onChange(e)} 
          {...restProps}
        />
      </div>  

      <div tw='ml-2 pt-0.5'>
        <label htmlFor={id ? id : name} tw='font-medium text-gray-700 cursor-pointer'>{label}</label>   
      </div>
    </div>
  );
};

export const CheckBoxGroup = ({ children, ...restProps }) => 
  <div tw='relative flex items-start justify-between px-2' { ...restProps }>
    { children }
  </div>;