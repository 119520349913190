/* eslint-disable no-console */
import { DMP_BASE_URL } from './dmp';
import { getIdTokenSync } from './auth';
import { currentUpdateAction } from '../components/current/ducks';
import { showFlashNotificationOp } from '../components/store';


const getStorageUpdateTime = () => {
  let time = 0;
  try {
    time = parseInt(window.localStorage.getItem('streamUpdateTime'), 10);
    if (isNaN(time) || time < 0) {
      time = 0;
    }
  } catch (e) {

    // no-op
  }
  return time;
};


// only one tab connects to the server and shared updates with others using local storage
// master tab also instantly writes timestamp into local storage and other tabs read it
// if master tab is closed one of the other tabs if any should become a master

//WRITE_INTERVAL must be less than READ_INTERVAL
const WRITE_INTERVAL = 8000;

//READ_INTERVAL must be greater than WRITE_INTERVAL
const READ_INTERVAL = 12000;

//attempts to reconnect in case of error
const MAX_ATTEMPTS = 4;

class StreamSource {
  constructor() {
    this.messageHandler = this.messageHandler.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.reconnect = this.reconnect.bind(this);
    this.streamConnect = this.streamConnect.bind(this);
    this.storageHandler = this.storageHandler.bind(this);
    this.updateState = this.updateState.bind(this);
    this.removeStreamHandlers = this.removeStreamHandlers.bind(this);
    this.writeUpdateTime = this.writeUpdateTime.bind(this);
    this.readUpdateTime = this.readUpdateTime.bind(this);
    this.timeoutId = null;
    this.attempts = 0;
  }

  updateState({ work_list, wait_queue, posts }) {
    if (!this.dispatch) {
      return;
    }

    if (!work_list) {
      work_list = [];
    }

    const js = work_list.concat(wait_queue);

    this.dispatch(currentUpdateAction({

      // uploads will now come from an API call instead of topical state
      // uploads: incoming.map(uploadWithDefaultStatus),
      jobs: js,
      posts: posts,
    }));
  }

  //master tab with socket connection writes timestamp in WRITE_INTERVAL until tab is closed or user logged out
  writeUpdateTime() {
    try {
      window.localStorage.setItem('streamUpdateTime', Date.now().toString(10));
    } catch (e) {

      // no-op
    }
    if(this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.writeUpdateTime, WRITE_INTERVAL);
  }

  //all non master tabs read timestamp in READ_INTERVAL until master stops update it
  //first tab that discovers outdated timestamp becomes master tab
  readUpdateTime() {
    const t = READ_INTERVAL;
    if(this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if(Date.now() - getStorageUpdateTime() > READ_INTERVAL * 1.2) {

      // timestamp is outdated, become a master
      window.removeEventListener('storage', this.storageHandler);
      this.streamConnect();
      this.writeUpdateTime();
    } else {

      //randomize timout to lower the chance of two tabs read from storage and become master simultaneously
      this.timeoutId = setTimeout(
        this.readUpdateTime, t + Math.floor(Math.random() * t*0.05),
      );
    }
  }

  storageHandler() {
    try {
      const dataSnapshot = window.localStorage.getItem('streamData');
      if (dataSnapshot && this.lastData !== dataSnapshot) {
        this.lastData = dataSnapshot;
        this.updateState(JSON.parse(dataSnapshot));
      }

    } catch (e) {

      // no-op
    }

  }

  messageHandler(event) {
    try {
      const { incoming, work_list, wait_queue, posts } = JSON.parse(event.data);

      // check for actual update, backend can send the same data multiple times
      const dataSnapshot = JSON.stringify({ incoming, work_list, wait_queue, posts });
      if (this.lastData === dataSnapshot) {
        return;
      }
      this.lastData = dataSnapshot;
      try {
        window.localStorage.setItem('streamData', dataSnapshot);
      } catch (e) {

        // no-op
      }

      // incoming: incoming,
      this.updateState({ work_list: work_list, wait_queue: wait_queue, posts: posts });
    } catch (e) {
      console.error(e);
    }
  }

  errorHandler(event) {
    this.removeStreamHandlers();
    if(++this.attempts < MAX_ATTEMPTS) {
      this.dispatch(showFlashNotificationOp('connecting'));
      setTimeout(() => {
        this.streamConnect();
      }, 500*this.attempts*this.attempts+5000);
    } else {
      this.attempts = 0;
      this.dispatch(showFlashNotificationOp('connectionError'));

      // start reconnect again
      window.addEventListener('storage', this.storageHandler);
      this.storageHandler();
      this.readUpdateTime();
    }
    console.error(event);
  }

  removeStreamHandlers() {
    this.stream.removeEventListener('message', this.messageHandler);
    this.stream.removeEventListener('error', this.errorHandler);
    this.stream.close();
    this.stream = undefined;
  }

  streamConnect() {
    this.stream =
      new EventSource(
        `${DMP_BASE_URL}/manage/topical?stream=true&access_token=${getIdTokenSync()}`,
        { withCredentials: true },
      );
    this.stream.addEventListener('message', this.messageHandler);
    this.stream.addEventListener('error', this.errorHandler);
  }

  reconnect({ dispatch, getState }) {
    this.close();
    this.dispatch = dispatch;
    this.getState = getState;

    //start from reading local storage
    window.addEventListener('storage', this.storageHandler);
    this.storageHandler();
    this.readUpdateTime();

  }

  close() {
    this.dispatch = null;
    this.getState = null;
    if (this.stream) {
      this.removeStreamHandlers();
    } else {
      window.removeEventListener('storage', this.storageHandler);
    }
    if(this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}

export const createStreamSource = store => new StreamSource(store);
