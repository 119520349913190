import React from 'react';

export const ArchiveIcon = props =>
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1em"
    width="1em"
    {...props}
  >
    <path d="M3 3h18v4H3V3m1 5h16v13H4V8m5.5 3a.5.5 0 00-.5.5V13h6v-1.5a.5.5 0 00-.5-.5h-5z"/>
  </svg>;