import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import 'twin.macro';
import { BusyIcon, ListContainer, PageContainer, SearchTextInput, SelectControl } from '../common';
import { Paginator } from '../common/Paginator';
import { UploadButtons, UploadRow } from './uploads';
import { ItemsCheckBox } from '../selection';
import { selectionClearAction, selectSelectedItems } from '../selection/ducks';
import { navigate } from '../utils';
import { fetchPage } from 'services/uploads';
import { useFetchData, useUnmounted, useURLParams } from '../common/hooks';

const statusFiltersOptions = [
  { label: 'Alles', value: 'all' },
  { label: 'Afkeuren', value: 'rejected' },
  { label: 'Kan online', value: 'approved' },
  { label: 'Repost', value: 'repost' },
  { label: 'Twijfel', value: 'dubious' },
  { label: 'Geupload door redactie', value: 'editors' },
];

const mediatypeFilterOptions = [
  { label: 'Alles', value: 'all' },
  { label: 'Video', value: 'video' },
  { label: 'Image', value: 'image' },
];


const ToolBar = ({ selected, uploads, statusFilter, mediatypeFilter, history, onSearch, onRefresh, page, source }) => {
  const selectedUploads = (selected) ?
    selected.items.map(itemId => uploads.find(u => u.id === itemId))
      .filter(s => s) : [];
  const tabName = source === 'archived' ? 'archive' : 'uploads';

  return <div tw="w-full flex flex-col md:flex-row justify-between">
    <div tw="flex flex-col md:flex-row">
      <div tw="order-2 flex flex-row justify-between mb-2 md:mb-0">
        <div tw="flex-1 mr-1 md:mr-3">
          <SelectControl value={statusFilter} options={statusFiltersOptions}
            onChange={s => navigate(history, `/${tabName}`, { page, source, status: s, mediatype: mediatypeFilter })}/>
        </div>
        <div tw="flex-1 ml-1 md:ml-0 md:mr-3">
          <SelectControl value={mediatypeFilter} options={mediatypeFilterOptions}
            onChange={s => navigate(history, `/${tabName}`, { page, source, status: statusFilter, mediatype: s })}/>
        </div>
      </div>
      <div tw="order-1 mb-2 md:mb-0 md:order-3">
        <SearchTextInput onSearch={s => onSearch(s.toLowerCase())} placeholder={`Zoek in ${tabName}`} delay/>
      </div>
    </div>
    <div tw="flex flex-col md:flex-row items-start md:items-center">
      <UploadButtons uploads={selectedUploads} refresh={onRefresh} source={tabName}>
        <ItemsCheckBox controlItems={uploads}/>
      </UploadButtons>
      <Paginator
        startPage={page}
        history={history}
        onPageChange={p => navigate(history, `/${tabName}`, { 
          page: p, source, status: statusFilter, mediatype: mediatypeFilter,
        })}
      />
    </div>
  </div>;
};


export const UploadsTab = () => {
  const unmounted = useUnmounted();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [timestamp, setTimestamp] = useState(0);
  const page = useURLParams().get('page') || '0';
  const status = useURLParams().get('status') || 'all';
  const mediatype = useURLParams().get('mediatype') || 'all';
  const source = location.pathname === '/archive' ? 'archived' : 'incoming';
  const reloadEvery = 15000;  // reload every 15 seconds

  // handle scroll position after content load
  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(scrollPosition));
        sessionStorage.removeItem('scrollPosition');
      }, 100);
    }
  };

  const [data, admins, loading, error, forceReload] = useFetchData(fetchPage, reloadEvery, {
    page, searchText, status, mediatype, source,
  }, handleScrollPosition);

  const { selected } = useSelector(state => ({
    selected: selectSelectedItems(state),
  }));

  useEffect(() => {
    if (data && selected['default'] &&
      selected['default'].items.some(itemId => data.every(u => u.id !== itemId))) {
      dispatch(selectionClearAction());
    }
  }, [JSON.stringify(data || {}), JSON.stringify(selected['default'])]);

  useEffect(() => {
    if (timestamp > 0) {
      forceReload();
    }
  }, [timestamp]);

  const onRefresh = useCallback(() => !unmounted.current ? setTimestamp(Date.now()) : null, []);

  const toolbarProps = {
    selected: selected['default'] || {items: []},
    uploads: data || [],
    history,
    page,
    source,
    statusFilter: status,
    mediatypeFilter: mediatype,
    onRefresh,
    onSearch: setSearchText,
  };

  return (
    <PageContainer toolbar={<ToolBar {...toolbarProps}/>} isPostPage={false}>
      {loading && <BusyIcon size={100}/>}
      {error && !loading && <pre>{error.message}</pre>}
      {data && !loading && !error && <ListContainer>
        {data && data.map(u => <UploadRow upload={u} key={u.id} refresh={onRefresh} source={source} 
          admins={admins}/>)}
      </ListContainer>}
    </PageContainer>
  );
};
