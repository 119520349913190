// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .popup--Wpn4O1 .modal-content--L0TIaU, .popup--Wpn4O1 .modal-card--bkmTWl {
        width: 900px;
    }
`, "",{"version":3,"sources":["webpack://./src/components/common/PopupButton.css"],"names":[],"mappings":";IACI;QACI,YAAY;IAChB","sourcesContent":[".popup {\n    & .modal-content, & .modal-card {\n        width: 900px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `popup--Wpn4O1`,
	"modal-content": `modal-content--L0TIaU`,
	"modalContent": `modal-content--L0TIaU`,
	"modal-card": `modal-card--bkmTWl`,
	"modalCard": `modal-card--bkmTWl`
};
export default ___CSS_LOADER_EXPORT___;
