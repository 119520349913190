import React from 'react';

export const BxPlayIcon = props =>
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1em"
    width="1em"
    {...props}
  >
    <path d="M7 6v12l10-6z" />
  </svg>;
