import React, { useCallback, useEffect, useState } from 'react';
import 'twin.macro';
import { Button, DateRangeSelect, RefreshButton } from '../common';
import { cancelPostJobs, cancelPostsJobs, getDonePostsHistory } from 'services/dmp';
import { selectionClearAction, selectSelectedItems } from 'components/selection/ducks';
import { ConvertHistoryPostsList } from './postsconversion/ConvertHistoryPostsList';
import { formatDateRFC3339 } from '../utils';
import { useShowErrorMessage, useUnmounted } from '../common/hooks';
import { ConvertPostList } from './postsconversion/ConvertPostList';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentJobs, selectCurrentPosts } from './ducks';
import { ItemsCheckBox } from '../selection';
import { Link } from 'react-router-dom';

export const PostsConversionTab = () => {
  const unmounted = useUnmounted();
  const [enabled, setEnabled] = useState(true);
  const setEnabledIfMounted = () => !unmounted.current  ? setEnabled(true) : null;

  const posts = useSelector(selectCurrentPosts);
  const currJobs = useSelector(selectCurrentJobs);
  const showErrorMessage = useShowErrorMessage();
  const selectedItems = useSelector(selectSelectedItems);
  const [iqbDisabled, setIqbDisabled] = useState(false);
  const [ipbDisabled, setIpbDisabled] = useState(false);

  const inQueueGroup = 'inqueue';
  const inProgressGroup = 'inprogress';

  const [{ start, end }, setDateRange] = useState(DateRangeSelect.defaultRange);

  const [donePosts, setDonePosts] = useState([]);
  const loadDonePosts = useCallback(() => {
    if (enabled) {
      setEnabled(false);
      getDonePostsHistory({
        start: formatDateRFC3339(start),
        end: formatDateRFC3339(end),
      })
        .then(({ data }) => {
          if (data && data.constructor === Object ) {
            if (Object.keys(data).length !== 0) {
              setDonePosts(data);
            }
            return;
          }

          setDonePosts(data);
        })
        .catch(showErrorMessage)
        .finally(setEnabledIfMounted);
    }
  }, [start, end]);

  // call loadDonePosts every 8 seconds, and clear the interval when the component is unmounted
  useEffect(() => {
    loadDonePosts();
    const interval = setInterval(loadDonePosts, 8000);
    return () => clearInterval(interval);
  }, [loadDonePosts]);

  // in queue
  const [inqueue, setInQueue] = useState([]);
  const loadInQeue = useCallback(() => {
    const filteredJobs = currJobs.filter(j => ['StateCreated'].includes(j.state));
    if  (posts) {
      setInQueue(posts.filter(p => filteredJobs.map(j => j.item.upload_id).includes(p.id)));
    } else {
      setInQueue(null);
    }
  }, [currJobs, posts]);

  useEffect(loadInQeue, [loadInQeue]);

  // in progress
  const [inprogress, setInProgress] = useState([]);
  const loadInProgress = useCallback(() => {
    const filteredJobs = currJobs.filter(j => ['StatePaused', 'StateScheduled', 'StateRunning'].includes(j.state));
    if  (posts) {
      setInProgress(posts.filter(p => filteredJobs.map(j => j.item.upload_id).includes(p.id)));
    } else {
      setInProgress(null);
    }
  }, [currJobs, posts]);

  useEffect(loadInProgress, [loadInProgress]);

  const dispatch = useDispatch();

  const cancelInQueue = useCallback(() => {
    const cancelJobs = ids => {
      setIqbDisabled(true);
      if (ids.length > 1) {
        cancelPostsJobs(ids).catch(showErrorMessage);
      } else {
        cancelPostJobs(ids).then(selectionClearAction).catch(showErrorMessage);
      }

      setIqbDisabled(false);

      return dispatch(selectionClearAction({posts: inqueue, group: inQueueGroup}));
    };

    if (!selectedItems || !selectedItems[inQueueGroup] || !selectedItems[inQueueGroup].items ||
      selectedItems[inQueueGroup].items.length === 0) {
      return;
    }

    const ids = selectedItems[inQueueGroup].items;
    return cancelJobs(ids);
  }, [JSON.stringify(selectedItems[inQueueGroup])]);

  const cancelInProgress = useCallback(() => {
    const cancelJobs = ids => {
      setIpbDisabled(true);
      if (ids.length > 1) {
        cancelPostsJobs(ids).then(selectionClearAction).catch(showErrorMessage);
      } else {
        cancelPostJobs(ids).then(selectionClearAction).catch(showErrorMessage);
      }

      setIpbDisabled(false);

      return dispatch(selectionClearAction({posts: inprogress, group: inProgressGroup}));
    };

    if (!selectedItems || !selectedItems[inProgressGroup] || !selectedItems[inProgressGroup].items || 
      selectedItems[inProgressGroup].items.length === 0) {
      return;
    }

    const ids = selectedItems[inProgressGroup].items;
    return cancelJobs(ids);
  }, [JSON.stringify(selectedItems[inProgressGroup])]);


  return (
    <>
      <div tw="py-14">
        <div tw="m-3 grid grid-cols-1 md:grid-cols-3 md:gap-3">
          <div tw="space-y-3">
            <div tw="h-8 flex justify-between">
              <h2 tw="text-3xl leading-tight">
    In Queue
              </h2>
              <ItemsCheckBox controlItems={inqueue} group={inQueueGroup}/>
              <Button disabled={iqbDisabled} onClick={() => dispatch(cancelInQueue)} label={'Cancel Jobs'} />
            </div>
            <section>
              <ConvertPostList stateFilter={['StateCreated']} group={inQueueGroup}/>
            </section>
          </div>

          <div tw="space-y-3">
            <div tw="h-8 flex justify-between">
              <h2 tw="text-3xl leading-tight">
    In Progress
              </h2>
              <ItemsCheckBox controlItems={inprogress} group={inProgressGroup}/>
              <Button disabled={ipbDisabled} onClick={() => dispatch(cancelInProgress)} label={'Cancel Jobs'} />
            </div>
            <section>
              <ConvertPostList stateFilter={['StatePaused', 'StateScheduled', 'StateRunning']} group={inProgressGroup}/>
            </section>
          </div>

          {<div tw="space-y-3">
            <div tw="flex justify-between h-8">
              <h2 tw="text-3xl leading-tight">
      Done
              </h2>
              {<div tw="flex self-start">
                <DateRangeSelect onSelect={setDateRange}/>
                {<>
                  <RefreshButton onRefresh={loadDonePosts} tw="mr-2"/>
                  <Link 
                    key={'/postqueuearchive'}
                    to={'/postqueuearchive'}
                    tw="bg-white hover:bg-gray-700 hover:text-gray-300 px-3 py-2 rounded-md"
                    activeStyle={{ backgroundColor: 'rgba(107,114,128,var(--tw-bg-opacity))' }}
                  >
                        Conversie (geannuleerd) Archief
                  </Link>
                </>}
              </div>}
            </div>
            <section>
              {<ConvertHistoryPostsList posts={donePosts}/>}
            </section>
          </div>}
        </div>
      </div>
    </>
  );
};
