import { SELECTION_ADD, SELECTION_CLEAR, SELECTION_REMOVE, SELECT_ALL } from './actions';

export const selectionInitialState = {
  selected: {},
};

export const selectionReducer = (state = selectionInitialState, { type, payload }) => {
  if (!payload || !payload.group) {
    return state;
  }

  const group = String(payload.group);

  switch (type) {
  case SELECTION_ADD:
    if (!state.selected[group]) {
      state.selected[group] = {items: []};
    }

    return payload.controlItems ? {
      selected: { ...state.selected, [group]: {
        items: state.selected[group].items.concat(payload.controlItems.map(item => item.id)),
      }},
    } : state;
  case SELECTION_REMOVE:
    return payload.controlItems ? {
      selected: { ...state.selected, [group]: {
        items: state.selected[group].items.filter(itemId => payload.controlItems.every(x => x.id !== itemId)),
      }},
    } : state;
  case SELECTION_CLEAR:
    state.selected[group] = {items: []};

    return {
      selected: { ...state.selected, [group]: { items: [] } },
    };
  case SELECT_ALL:
    return payload.controlItems ? {
      selected: { ...state.selected, [group]: { items: payload.controlItems.map(x => x.id) } },
    } : state;
  default:
    break;
  }
  return state;
};

const emptyArray = [];
export const selectSelectedItems = state => state.selection.selected || emptyArray;
