import React from 'react';


export const DeleteIcon = props =>
  <svg
    viewBox="0 0 48 48"
    fill="currentColor"
    height="1em"
    width="1em"
    {...props}
  >
    <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z"/>
  </svg>;
