import React, { useCallback, useEffect, useState } from 'react';
import { ActionPopUpButton } from './AdminButton';
import { useDispatch } from 'react-redux';
import { setMessageAction } from '../store';
import 'twin.macro';


export const ConfirmButtons = ({ onConfirm, withComment }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('');
  const close = useCallback(() => dispatch(setMessageAction(null)), []);
  const confirm = useCallback(() => {
    setLoading(true);
    onConfirm(note).then(close);
  }, [onConfirm, note]);

  useEffect(() => () => {
    setNote('');
  }, []);

  return <div tw="flex justify-center space-x-4 w-full">
    {withComment && <textarea tw="px-2 w-full border border-gray-300" placeholder="(Optionele notitie)"
      maxLength="180" value={note} onChange={e => {
        setNote(e.target.value);
      }} disabled={loading} autoFocus> </textarea>}
    <ActionPopUpButton onClick={confirm} label={'Ja'} loading={loading}
      disabled={loading} />
    <ActionPopUpButton onClick={close} label={'Nee'} disabled={loading} />
  </div>;
};

