import React from 'react';
import 'twin.macro';
import { Button } from '../../common';
import {
  BxPlayIcon,
  BxPauseIcon,
  RedoIcon,
  DeleteIcon,
  CloseIcon,
  InformationCircleIcon,
} from 'icons';

export const JobState = {
  invalid: 'StateInvalid', // -1,
  created: 'StateCreated', // 0,
  scheduled: 'StateScheduled', // 1,
  running: 'StateRunning', // 2,
  paused: 'StatePaused', // 3,
  failed: 'StateFailed', // 4,
  aborted: 'StateAborted', // 5,
  completed: 'StateCompleted', // 6,
};

export const PauseButton = p => 
  <Button label='Pauzeren' Icon={BxPauseIcon} tw="ml-1" {...p} />;

export const AbortButton = p => 
  <Button label='Afbreken' Icon={CloseIcon} tw="ml-1" {...p} />;

export const ResumeButton = p => 
  <Button label='Doorgaan' Icon={BxPlayIcon} tw="ml-1" {...p} />;
    
export const RedoButton = p => 
  <Button label='Opnieuw' Icon={RedoIcon} tw="ml-1" {...p} />;

export const DeleteButton = p => 
  <Button label='Verwijderen' Icon={DeleteIcon} tw="ml-1" {...p} />;

export const InfoButton = p => 
  <Button label='Info' Icon={InformationCircleIcon} tw="ml-1" {...p} />;