import { POSTS_FETCH } from './actions';


export const postsInitialState = {
  filters: {},
};

export const postsReducer = (state = postsInitialState, { type, payload }) => {
  switch (type) {
  case POSTS_FETCH:
    return {
      filters: {
        ...state.filters,
        [payload.filter]: ['items', 'admins', 'status'].reduce(
          (o, k) => payload[k] ? { ...o, [k]: payload[k] } : o, {},
        ),
      },
    };
  default:
    break;
  }
  return state;
};

