import React from 'react';
import { FormField, InputButton } from '../common';
import { BxSearchIcon } from 'icons';

export class SearchTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { text: '' };
    this.timeout = 0;
    this.updateText = this.updateText.bind(this);
    this.clearText = this.clearText.bind(this);
  }

  updateText(text) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.props.onSearch, this.props.delay ? 1500 : 10, text);
    this.setState({ text });
  }

  clearText() {
    clearTimeout(this.timeout);
    this.setState({ text: '' });
    this.props.onSearch('');
  }

  render() {
    const { placeholder, onSearch } = this.props;
    const { text } = this.state;
    return (
      <FormField
        name="search"
        placeholder={placeholder}
        autoComplete="off"
        value={text}
        buttonLabel={<BxSearchIcon tw="h-5 w-5" />}
        onPush={onSearch}
        FieldType={InputButton}
      />
    );
  }

}