import React, { useEffect, useState } from 'react';
import { getPostStats, updatePostStats } from 'services/dmp';
import { BusyIcon } from '../../common';
import 'twin.macro';
import { EditableCell } from '../../common/EditableCell';
import { useUnmounted } from '../../common/hooks';

export const StatsEditableCell = ({ saving, value, onChange }) => (
  <>
    {!saving && <EditableCell value={value} onChange={onChange} />}
    {saving && (
      <div tw="w-full">
        <BusyIcon size={20} />
      </div>
    )}
  </>
);

export const Cijfertjes = ({ upload }) => {
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [saving, setSaving] = useState('');
  const unmounted = useUnmounted();
  const callIfMounted = (f) => {
    if (!unmounted.current) {
      f();
    }
  };
  const {
    views_today = 0,
    views_total = 0,
    kudos_today = 0,
    kudos_total = 0,
  } = stats || {};

  useEffect(() => {
    if (upload.post) {
      setLoading(true);
      getPostStats(upload.post.id)
        .then(({ data }) => callIfMounted(() => setStats(data)))
        .catch((e) =>
          callIfMounted(() => {
            setStats(null);
            setError(e);
          }),
        )
        .finally(() => callIfMounted(() => setLoading(false)));
    }
  }, [upload]);

  const save = (key, value) => {
    const newStats = { ...stats, [key]: +value };
    const [type, period] = key.split('_');
    if (period === 'today') {
      const totalKey = `${type}_total`;
      newStats[totalKey] = newStats[totalKey] + (value - stats[key]);
    }
    setSaving(key);
    return updatePostStats(upload.post.id, newStats)
      .then(() => callIfMounted(() => setStats(newStats)))
      .catch((e) =>
        callIfMounted(() => {
          setStats(null);
          setError(e);
        }),
      )
      .finally(() => callIfMounted(() => setSaving('')));
  };

  return (
    <>
      {loading && <BusyIcon size={100} />}
      {stats && !loading && (
        <div tw="grid grid-cols-3 gap-4">
          <div></div>
          <div>Views</div>
          <div>Kudos</div>
          <div>Today:</div>
          <StatsEditableCell
            saving={saving === 'views_today'}
            value={views_today}
            onChange={(x) => save('views_today', x)}
          />
          <StatsEditableCell
            saving={saving === 'kudos_today'}
            value={kudos_today}
            onChange={(x) => save('kudos_today', x)}
          />
          <div>Total:</div>
          <StatsEditableCell
            saving={saving === 'views_total'}
            value={views_total}
            onChange={(x) => save('views_total', x)}
          />
          <StatsEditableCell
            saving={saving === 'kudos_total'}
            value={kudos_total}
            onChange={(x) => save('kudos_total', x)}
          />
        </div>
      )}
      {error && <pre>{error.toString()}</pre>}
    </>
  );
};
