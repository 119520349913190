import React, { useEffect, useRef, useState } from 'react';
import Script from 'react-load-script';
import { useShowErrorMessage } from '../common/hooks';
import { getIdTokenSync } from 'services/auth';
import 'twin.macro';


export const HLSVideoPlayer = ({ streamUrl }) => {
  const videoRef = useRef();
  const showErrorMessage = useShowErrorMessage();
  const [scriptLoaded, setScriptLoaded] = useState();
  const [hlsInstance, setHlsInstance] = useState();


  useEffect(() => {
    if(scriptLoaded) {
      setHlsInstance(x => {
        if(x) {
          x.destroy();
        }
        // eslint-disable-next-line no-undef
        const hls = new Hls({
          xhrSetup: xhr => {
            xhr.setRequestHeader('Authorization', `Bearer ${getIdTokenSync()}`);
          },
          capLevelToPlayerSize: true,
        });
        hls.attachMedia(videoRef.current);
        hls.loadSource(streamUrl);
        return hls;
      });
    }
  }, [streamUrl, scriptLoaded]);

  useEffect(() => () => {
    if(hlsInstance) {
      hlsInstance.destroy();
    }
  }, []);

  return (
    <>
      <Script url={'https://cdn.jsdelivr.net/npm/hls.js@1.0.10'}
        onLoad={() => setScriptLoaded(true)} onError={showErrorMessage}/>
      <video tw="w-full h-full" ref={videoRef} controls/>
    </>
  );
};
