import React from 'react';
import { TextInputControl } from './TextInputControl';

export class NotesEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { notes: [] };
    this.getNotesFromProps = this.componentDidMount = this.getNotesFromProps.bind(this);
    this.addNote = this.addNote.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.notes !== prevProps.notes) {
      this.getNotesFromProps();
    }
  }

  getNotesFromProps() {
    const { notes } = this.props;
    if (notes) {
      this.setState({ notes });
    }
  }

  addNote(text) {
    const { onChange } = this.props;
    const notes = this.state.notes.concat(text);
    onChange(notes);
    this.setState({ notes });
  }

  render() {
    const { notes } = this.state;
    return (
      <div>
        <TextInputControl buttonLabel="Plaatsen"
          placeholder="Aantekening plaatsen"
          onPush={this.addNote}/>
        <ul>
          {notes.map(n => <li key={n}><small><strong>username:</strong>&nbsp;<p><i>{n}</i></p></small></li>)}
        </ul>
      </div>
    );
  }
}