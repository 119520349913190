import React from 'react';
import 'twin.macro';
import styles from './SelectControl.css';

export const SelectControl = ({ options = [], value, onChange, disabled }) => (
  <>
    <div tw="relative flex overflow-hidden border-0" className={styles.select}>
      <select
        value={value}
        onChange={({ target: { value } }) => onChange(value)} disabled={disabled}
        tw='flex-1 px-2 py-0 rounded-md cursor-pointer bg-white w-40 text-black border border-gray-300' >
        {options.map(({ label, value }) => <option 
          key={value} 
          value={value}
        >
          {label}
        </option>)}
      </select>
    </div>
  </>
);