import axios from 'axios';
import { authorizeRequest } from 'services/auth';

const userApi = axios.create({ baseURL: process.env.USER_HOST, withCredentials: true });
userApi.interceptors.request.use(authorizeRequest);

export const fetchData = (page = 0, search = '', roles = []) => userApi.post('/moderation/search', {
  search: search,
  page: page,
  roles: roles,
});

export const getMailgunStatus = (email = 'email') => userApi.get(`/mailgun-status/${email}`);

export const getUserData = (id) => userApi.get(`/moderation/user/${id}`);
export const updateUser = (id, userData) => userApi.put(`/moderation/user/${id}`, {
  first_name: userData.first_name,
  last_name: userData.last_name,
  gender: userData.gender,
  confirmed: userData.confirmed === 2,
  is_active: userData.active,
});
export const bypass_newbie = (id) => userApi.put(`/moderation/bypass-newbie/${id}`);
export const confirm_email = (userData) => userApi.post(`/send-confirm-email/${userData.email}`);


export function delete_user(id) {
  if (window.confirm('Are you sure you want to delete the user?')) {

    // TODO: Promise returned from put is ignored
    userApi.delete(`/moderation/user/${id}`).catch(err => {
      throw new Error(err);
    });
  }
}


export function change_username(id, userData) {
  if (window.confirm('Are you sure you want to update the username?')) {

    // TODO: Promise returned from put is ignored
    userApi.put(`/moderation/usercreds/${id}`, {
      email: userData.email,
      username: userData.username,
    }).catch(err => {
      throw new Error(err);
    });
  }
}


export function ban_user(id, userData, selectedDate, banReason, commentLink = '', commentContent = '') {
  if (window.confirm('Weet je het zeker?')) {

    // TODO: Promise returned from post is ignored
    userApi.post(`/moderation/ban/${id}`, {
      ban: userData.commentary_state,
      ban_reason: banReason,
      end_ban: selectedDate,
      comment_link: commentLink,
      comment_content: commentContent,
    }).catch(err => {
      throw new Error(err);
    });
  }
}

export function setUserRole(id, role) {

  // TODO: Promise returned from put is ignored
  userApi.put(`/user/${id}/role/${role}`).catch(err => {
    throw new Error(err);
  });
}


export function subscribeModerator(id) {

  // TODO: Promise returned from post is ignored
  userApi.post(`/subscribe-mod/${id}`).catch(err => {
    throw new Error(err);
  });
}


// domains

export const FetchBlacklist = () => userApi.get('/moderation/domain');
export const Add_domain = (domain) => userApi.post(`/moderation/domain/${domain}`);
export const Delete_domain = (domain) => userApi.delete(`/moderation/domain/${domain}`);



export const getLogs = (days_ago, key, operation) => userApi.post('/logs_read', {
  operation: operation,
  days_ago: days_ago,
  key: key,
});
