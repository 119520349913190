import * as React from 'react';
import 'twin.macro';
import { ItemsCheckBox } from '../selection';
import { ConvertPostButtons, EditPostThumbLink } from './ConvertPostButtons';
import { selectCurrentJobsByUpload } from '../current/ducks';
import { useSelector } from 'react-redux';
import { useDeleteConversionArchive } from './hooks';

import {
  ListRow,
  ListColumnFixed,
  ListColumnGrow,
  ListItemMedia,
  ListItemInfo,
  ListItemStatus,
  ListItemActions,
} from '../common';

export const ArchivePost = ({ item, deleteCallback = null }) => {
  const jobsByUpload = useSelector(selectCurrentJobsByUpload);
  const uploadProgress = jobsByUpload[item.upload_id] && jobsByUpload[item.upload_id].total || 0;
  const [remove] = useDeleteConversionArchive({ids: [item.id]});

  const props = {
    posts: [item],
    withCancel: false,
    redoCallback: remove,
    withRedo: true,
    withDeleteArchive: true,
    deleteCallback: deleteCallback,
  };

  return <ListRow>
    <ListColumnGrow>
      <EditPostThumbLink posts={[item]}>
        <div tw="mb-2 md:mb-0">
          <ListItemMedia still={item.still} />
        </div>
      </EditPostThumbLink>
      <ListItemInfo item={item} maxLen={45} />
    </ListColumnGrow>
    <ListColumnFixed>
      <ListItemStatus uploadProgress={uploadProgress} date={item.date} />
      <ListItemActions>
        <ConvertPostButtons {...props}>
          <ItemsCheckBox controlItems={[item]}/>
        </ConvertPostButtons>
      </ListItemActions>
    </ListColumnFixed>
  </ListRow>;
};
