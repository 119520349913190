import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { CloseIcon } from 'icons';

export const StyledTag = styled.div(({ color, removable }) => [
  tw`inline-flex items-center mr-1 py-0.5 px-2 rounded-full text-xs font-medium`,
  removable && tw`pr-1`,
  color === 'green' && tw`bg-green-100 text-green-800`,
  color === 'blue' && tw`bg-blue-100 text-blue-800`,
  color === 'red' && tw`bg-red-100 text-red-800`,
  color === 'yellow' && tw`bg-yellow-100 text-yellow-800`,
  color === 'gray' && tw`bg-gray-300 text-gray-800`,
]);

export const Tag = ({ 
  label, 
  color, 
  removable, 
  removeHandler, 
}) => <StyledTag color={color} removable={removable}>
  {label}
  {removable && <button
    type="button"
    onClick={() => removeHandler(label)}
    tw="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 
      hover:bg-indigo-200 hover:text-indigo-500"
  >
    <CloseIcon />
  </button>}
</StyledTag>;

export const Tags = ({ list = [], max = false, invisTillMin = 1 }) => {
  
  const tagList = typeof list === 'string' ? [...list.split(' ')] : list;
  const numOfTags = tagList.length;
  
  // fallback to default
  if (invisTillMin < 0) {
    invisTillMin = 1;
  }

  const tags =  max 
    ? tagList
      .slice(0, max)
      .map(tag => <Tag key={tag} label={tag} color="blue">{tag}</Tag>)
    : tagList.map(tag => <Tag key={tag} label={tag} color="blue">{tag}</Tag>);
    
  return (
    <>
      {list && numOfTags > invisTillMin && <>{tags}</>}
      {max && (list && numOfTags > max) && (
        <span tw='text-gray-500'>
          +{numOfTags - max} tag{(numOfTags - max) > 1 ? 's' : ''}
        </span>)}
    </>);
};

export const NsfTags = ({ nsfw, nsfa }) => 
  <div className="flex items-center pt-1">
    {nsfw && (<Tag label='NSFW' color='red' />)}
    {nsfa && (<Tag label='NSFA' color='yellow' />)}
  </div>;    
