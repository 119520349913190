import React from 'react';
import { ArchivePost } from 'components/posts/ArchivePost';

export const ArchivePostsList = ({ posts, deleteCallback }) => {
  posts = posts.map(p => ({...p, still: p.thumbnail}));

  return (
    <>
      {posts.map(post => <ArchivePost key={post.id} item={post} deleteCallback={deleteCallback} />)}
    </>
  );
};
