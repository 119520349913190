import React from 'react';
import tw from 'twin.macro';
import { abortJob, deleteJob, pauseJob, resumeJob } from 'services/dmp';
import { useDispatch } from 'react-redux';
import { setMessageAction } from '../../store';
import { useShowErrorMessage } from '../../common/hooks';
import { 
  PauseButton,
  AbortButton, 
  ResumeButton,
  RedoButton,
  DeleteButton,
  InfoButton,
} from './JobButtons';
import {
  LOCALE,
  JobState,
  optionsFullDate,
  humanDiff,
  humanFileSize,
  formatPercent,
} from './helpers';


export const Job = ({ job }) => {

  const { 
    id, state, type, since, progress, item, created, 
    trail: { audit }, 
    item: { upload_id, media_type, upload_title, size } } = job;

  const dateSince = new Date(since);
  const dateNow = new Date(Date.now());
    
  const currentProcess = formatPercent.format(progress);

  const dispatch = useDispatch();
  const showError = useShowErrorMessage();

  const isPaused = JobState.paused === state;
  const isRunning = JobState.running === state;
  const isFinished = [JobState.completed, JobState.aborted, JobState.failed].includes(state);

  return (
    <>
      <div tw="bg-white shadow rounded-lg p-4 relative text-xs mb-3">
        <div tw="flex w-full text-gray-500 mb-2">
          <div tw="pr-3">#{id}</div>
          <div tw="self-start"><strong>{upload_title}</strong></div>
        </div>
        <div tw="flex w-full text-gray-500">
          <div tw="pr-3">{new Date(created).toLocaleDateString(LOCALE.format, optionsFullDate)}</div>
          <div tw="pr-3">{media_type}</div>
          <div>{audit}</div>
        </div>
        <div tw="w-full h-4 bg-gray-200 rounded-full my-2">
          <div css={[
            tw`h-full text-center text-xs text-white rounded-full bg-yellow-500`, 
            progress < 0.5 && tw`bg-red-500`,
            progress === 1 && tw`bg-green-500`,
          ]}
          style={{
            width: currentProcess,
          }}>
            {currentProcess}
          </div>
        </div>

        <div tw='flex justify-between w-full flex-col mt-2'>
          <div tw="flex w-full text-gray-500">
            <div tw='pr-3'>#{upload_id.substring(0, 5)}</div>
            <div tw='pr-3'><strong>{type}</strong></div>
            <div tw="pr-3"> ~ ({humanFileSize(size)})</div>
            <div>{humanDiff(dateSince, dateNow)}</div>
          </div>
          {item !== undefined && upload_title && (
            <div tw="flex justify-end w-full mt-2">
              {isRunning && (
                <>
                  <PauseButton onClick={() => pauseJob(id).catch(showError)}/>
                  <AbortButton onClick={() => abortJob(id).catch(showError)}/>
                </>
              )}
              {isPaused && 
                <ResumeButton onClick={() => resumeJob(id).catch(showError)}/>
              }
              {isFinished && (
                <>
                  <RedoButton onClick={() => dispatch(setMessageAction({ text: 'not implemented', color: 'warning'}))}/>
                  <DeleteButton onClick={() => deleteJob(id).catch(showError)}/>
                </>
              )}
              {JobState.failed === state &&
                <InfoButton onClick={() => dispatch(setMessageAction({
                  text: JSON.stringify(job), title: 'Job Details', color: 'warning',
                }))}/>
              }      
            </div>
          )}
        </div>
      </div>  
    </>
  );
};
