import React from 'react';

export const VideoImageIcon = props =>
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1em"
    width="1em"
    {...props}
  >
    <path d="M17 10.5l4-4v11l-4-4V17a1 1 0 01-1 1H4a1 1 0 01-1-1V7a1 1 0 011-1h12a1 1 0 011 1v3.5m-6.09-.95L9.2 11.82l1.3 1.73-.73.54-2.04-2.73L5 15h10l-4.09-5.45z" />
  </svg>;
