import 'twin.macro';
import React from 'react';
import { Stills } from './tabs/Stills';
import { TabsView } from './TabsView';

export const MediaOptions = ({ selectedItem, data, change, enabled, source }) => (
  <div tw="flex flex-col md:flex-row justify-between items-start mt-3">
    {selectedItem && selectedItem.mime_type &&
      <div tw="flex-1 pr-1 mb-2 md:mb-0">
        <Stills 
          upload={data}
          mediaItem={selectedItem}
          change={change} 
        />
      </div>
    }
    <div tw="flex-1 pl-1">
      <TabsView upload={data} selectedItem={selectedItem}
        change={change} enabled={enabled} type='media' hideTabs={source === 'incoming' ? ['Watermerk'] : []}/>
    </div>
  </div>
);
