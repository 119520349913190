import React from 'react';
import 'twin.macro';
import { Uploader } from '../Uploader';
import { cleanTextContent } from '../../utils';
import { Progress } from 'react-bulma-components';

export const Info = ({ upload }) => (
  <>
    <Uploader uploader={upload.uploader} created={upload.created} uploadDate />
    <div tw="flex">
      <p tw="font-medium text-gray-900 mr-1">Titel:</p>
      <p tw="text-gray-500">{upload.originalTitle}</p>
    </div>
    <div tw="flex">
      <p tw="font-medium text-gray-900 mr-1">Beschrijving:</p>
      {upload?.originalDescription && (
        <p tw="text-gray-500">{upload.originalDescription}</p>
      )}
    </div>
    {upload?.conversion_factor && upload.conversion_factor > 0 && (
      <div>
        <p tw="font-medium text-gray-900 mr-1">Conversie complexiteit:</p>
        <Progress
          color={progressColor(upload)}
          tw="mt-2 h-2 z-10 
        rounded-md w-11/12"
          max={100}
          value={Math.round(upload.conversion_factor)}
        />
      </div>
    )}
    <div>
      <p tw="font-medium block text-gray-900 mr-1">Originele bestandsnaam:</p>
      {upload.items[0].original_filename
        ? upload.items
          .map((item) => ({ id: item.id, fn: item.original_filename }))
          .map((item) => (
            <p key={item.id} tw="text-gray-500 break-words block mb-1">
              {cleanTextContent(item.fn)}
            </p>
          ))
        : upload.items
          .map((item) => ({ id: item.id, fn: item.filename }))
          .map((item) => (
            <p key={item.id} tw="text-gray-500 break-words block mb-1">
              {cleanTextContent(item.fn)}
            </p>
          ))}
    </div>
  </>
);

function progressColor(upload) {
  let color = 'primary';
  if (upload.conversion_factor > 80) {
    color = 'danger';
  } else if (upload.conversion_factor > 50) {
    color = 'warning';
  } else if (upload.conversion_factor > 25) {
    color = 'success';
  }

  return color;
}
