import React from 'react';
import Button from 'react-bulma-components/cjs/components/button';
import c from 'classnames';
import styles from './SwitchButtons.css';

export class SwitchButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: props.selected ? props.selected : [] };
  }

  componentDidUpdate() {
    const { selected } = this.props;
    if (selected !== this.state.selected) {

      // this.setState({ selected });
    }
  }

  select(title) {
    const { onSelect } = this.props;
    this.setState({ selected: title });
    onSelect(title);
  }

  render() {
    const { selected } = this.state;
    return (
      <div className={c('buttons', 'has-addons', styles.buttons)}>
        {this.props.buttons.map(b =>
          <Button data-button={b.value} key={b.value} size="small" onClick={() => this.select(b.value)}
            color={selected.includes(b.value) ? b.color : null }
            isSelected={selected.includes(b.value)}>
            {b.title}
          </Button>,
        )}
      </div>
    );
  }
}
