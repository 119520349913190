import React, { useEffect, useState } from 'react';
import { getItemSubtitles, getSubtitles, setItemSubtitles } from 'services/post';
import {makeSubs} from 'services/dmp';
import 'twin.macro';

export const Subtitles = ({ upload, mediaItem, ...restProps }) => {

  const [subtitles, setSubtitles] = useState([]);
  const [selectedSubtitle, setSelectedSubtitle] = useState(null);

  const [vttContent, setVttContent] = useState('');

  const [selectedLanguage, setSelectedLanguage] = useState('');

  const [hasSubtitles, setHasSubtitles] = useState(true);
  const [doTranslate, setDoTranslate] = useState(false);



  useEffect(() => {
    getItemSubtitles(mediaItem.id).then((response) => {
      if (response && response.data && response.data.length > 0) {
        setSubtitles(response.data);
        setSelectedSubtitle(response.data[0]);
        fetchVttFile(response.data[0]);
      }else{
        setHasSubtitles(false);
      }
    });
  }, [mediaItem.id]);


  const handleChangeLang = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleGenerate = () => {
    makeSubs(selectedLanguage ? selectedLanguage : 'nl', upload.id, mediaItem.id, hasSubtitles).then(() => {
      window.location.reload();
    });

  };






  const fetchVttFile = async (subtitle) => {
    try {
      const text = await getSubtitles(subtitle.PostId, subtitle.ItemId, subtitle.Language);
      setVttContent(text.data);

      setSelectedLanguage(subtitle.Language);
    } catch (error) {
      console.error('Failed to fetch VTT file', error);
    }
  };

  const handleChangeSubs = (event) => {
    if (subtitles.find(element => element.Language === event.target.value)) {
      fetchVttFile(subtitles.find(element => element.Language === event.target.value));
    }else{
      setSelectedLanguage(event.target.value);
      setDoTranslate(true);
    }

  };




  const handleTextChange = (event) => {
    setVttContent(event.target.value);
  };

  const handleSave = async () => {
    const blob = new Blob([vttContent], { type: 'text/vtt' });
    const formData = new FormData();
    formData.append('file', blob, 'file.vtt');

    const response = await setItemSubtitles(selectedSubtitle.PostId,
      selectedSubtitle.ItemId, selectedLanguage, formData);
    if (response.status!==201) {
      console.error('Failed to save subtitles:', response.statusText);
    }
  };

  if (mediaItem.media_type !== 'video') {
    return <div> <p>Not a video bro</p></div>;
  }
  if (!hasSubtitles) {
    return (
      <><div>
        <div className="App">
          <p> select the language of the video</p>
          <select value={selectedLanguage} onChange={handleChangeLang}>
            {['nl', 'en'].map((subtitle, index) => (
              <option key={index} value={subtitle}>
                {subtitle}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <button onClick={handleGenerate}
          style={{
            backgroundColor: '#4CAF50', color: 'white',
            padding: '15px 32px', fontSize: '16px', cursor: 'pointer',
          }}
        >
          Generate Subtitles
        </button>
      </div>
      </>
    );
  }
  if (doTranslate) {
    return (
      <><div>
        <div className="App">
          <p> translate subtitles to {selectedLanguage} </p>

        </div>
      </div>
      <div>
        <button onClick={handleGenerate}
          style={{
            backgroundColor: '#4CAF50', color: 'white',
            padding: '15px 32px', fontSize: '16px', cursor: 'pointer',
          }}
        >
          Generate Subtitles
        </button>
      </div>
      </>
    );
  }
  return (
    <>
      <div {...restProps}>
        <div className="App">
          <select value={selectedLanguage} onChange={handleChangeSubs}>
            {subtitles.map((subtitle, index) => (
              <option key={index} value={subtitle.Language}>
                {subtitle.Language}
              </option>
            ))}
          </select>
        </div>
        <div>
        </div>
        {vttContent && (
          <div tw="mt-4">
            <textarea
              tw="w-full p-2 border"
              value={vttContent}
              onChange={handleTextChange}
              rows={10}
            />
            <button tw="mt-4 p-2 bg-blue-500 text-white" onClick={handleSave}>
            Save
            </button>
          </div>
        )}

      </div>
    </>

  );
};
