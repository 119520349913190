import React from 'react';
import {useSelector} from 'react-redux';
import {selectCurrentJobsByItem} from '../../current/ducks';
import Progress from 'react-bulma-components/cjs/components/progress';

export const Convert = ({ mediaItem }) => {
  const jobsByItem = useSelector(selectCurrentJobsByItem);
  const itemInfo = jobsByItem && jobsByItem[mediaItem.id];
  const jobs = itemInfo && itemInfo.jobs;
  return <>
    { jobs && jobs.map(j => <>
      { `${j.type} ${JSON.stringify(j.params)}` }
      <Progress
        max={1}
        value={ j.progress }
        color={ j.progress < 0.5 ? 'warning' : j.progress === 1 ? 'success' : 'info' }
        size="small"
      />
    </>) }
  </>;
};
