import React from 'react';
import styles from './TextInputControl.css';
import Label from 'react-bulma-components/cjs/components/form/components/label';
import Control from 'react-bulma-components/cjs/components/form/components/control';
import { Input } from 'react-bulma-components/cjs/components/form';
import Button from 'react-bulma-components/cjs/components/button';
import Field from 'react-bulma-components/cjs/components/form/components/field';


export class TextInputControl extends React.Component {

  constructor(props) {
    super(props);
    this.state = { text: '' };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { text } = this.state;
    const { onPush } = this.props;
    onPush(text);
    this.setState({ text: '' });
  }

  handleKeyPress = (event) => {
    if (event.key === ' ' && this.props.label.toLowerCase() === 'tags') {
      this.onClick();
    }
  };

  render() {
    const { text } = this.state;
    const { buttonLabel, placeholder, label } = this.props;
    return (
      <Field horizontal className={styles.field}>
        {label && <Label size="small">{label}</Label>}
        <Control size="small" >
          <Input 
            size="small" value={text} 
            placeholder={placeholder}
            onChange={({ target: { value } }) => this.setState({ text: value })}
            onKeyPress={this.handleKeyPress}
          />
        </Control>
        <Button size="small" onClick={this.onClick} isStatic={text === ''}>{buttonLabel}</Button>
      </Field>
    );
  }

}
