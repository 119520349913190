import React, { useEffect, useState } from 'react';
import Tabs from 'react-bulma-components/cjs/components/tabs';
import {
  Cijfertjes,
  Convert,
  Download,
  Subtitles,
  Embed,
  Info,
  Scripts,
  Watermark,
} from './tabs';
import { CheckBox } from '../common';
import { itemMediaType } from '../utils';
import { useGetBlocked } from './hooks';

const tabsDisplay = { IMAGE: 'image', VIDEO: 'video', AUDIO: 'audio', ALL: 'all' };

const tabVisible = (tab, item, upload) => {
  if (tab.component === Cijfertjes && upload.source === 'incoming') {
    return false;
  }
  const mimeType = itemMediaType(item);
  switch (tab.display) {
  case tabsDisplay.ALL:
    return true;
  case tabsDisplay.VIDEO:
    return mimeType === 'video';
  case tabsDisplay.IMAGE:
    return mimeType === 'image';
  case tabsDisplay.AUDIO:
    return mimeType === 'audio';
  default:
    return false;
  }
};

export const BlockComments = ({ articleId, upload }) => {
  const { isBlocked, updateIsBlocked } = useGetBlocked(articleId);
  return (
    upload.post && (
      <CheckBox
        label="Open Comments"
        name="comments"
        checked={isBlocked}
        onChange={() => updateIsBlocked(articleId)}
      />
    )
  );
};

export const mediaTabs = [
  { title: 'Watermerk', component: Watermark, display: tabsDisplay.ALL },
  { title: 'Convert', component: Convert, display: null },
  { title: 'Embed', component: Embed, display: tabsDisplay.VIDEO },
  { title: 'Download', component: Download, display: tabsDisplay.ALL },
  { title: 'Ondertiteling', component: Subtitles, display: tabsDisplay.ALL },
];

export const TabsView = ({
  upload,
  selectedItem,
  change,
  enabled,
  type = 'post',
  hideTabs = [],
}) => {
  const articleId = upload?.post?.id.split('_')[0];
  const postTabs = [
    { title: 'Info', component: Info, display: tabsDisplay.ALL },
    { title: 'Cijfertjes', component: Cijfertjes, display: tabsDisplay.ALL },
    { title: 'Scripts', component: Scripts, display: tabsDisplay.ALL },
    {
      title: 'Comments',
      component: BlockComments,
      display: tabsDisplay.ALL,
      extraProps: { articleId, upload },
    },
  ];

  const tabsList = type === 'media' ? mediaTabs : postTabs;
  const visibleTabs = tabsList
    .filter(({ title }) => hideTabs.indexOf(title) < 0)
    .filter((t) => tabVisible(t, selectedItem, upload));

  const titles = visibleTabs.map((t) => t.title);
  const [selectedTab, setSelectedTab] = useState(visibleTabs[0].title);
  const TabComponent = (visibleTabs.find((x) => x.title === selectedTab) || {})
    .component;
  const extraProps = (visibleTabs.find((x) => x.title === selectedTab) || {})
    .extraProps;
  useEffect(() => {
    if (!titles.includes(selectedTab)) {
      setSelectedTab(titles[0]);
    }
  }, [selectedTab, titles.join()]);

  return (
    <>
      <Tabs size="small" fullwidth>
        {visibleTabs.map(({ title }) => (
          <Tabs.Tab
            key={title}
            active={title === selectedTab}
            onClick={() => setSelectedTab(title)}
          >
            {title}
          </Tabs.Tab>
        ))}
      </Tabs>

      <div tw="min-h-full">
        {selectedItem && !!TabComponent && (
          <TabComponent
            upload={upload}
            mediaItem={selectedItem}
            change={change}
            enabled={enabled}
            source={upload.source}
            {...extraProps}
          />
        )}
      </div>
    </>
  );
};
