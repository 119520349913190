import { useCallback, useEffect, useState } from 'react';
import { getUpload } from 'services/dmp';
import { useUnmounted } from '../common/hooks';
import {
  getItemsUnmoderatedComments,
  alterCommentsRisk,
  getUnmoderatedCommentsRisk,
  getBlockedComments,
  updateBlockedComments,
} from 'services/comment';

export const useUpload = (uploadId, source) => {
  const unmounted = useUnmounted();
  const [pending, setPending] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [dataChanges, setDataChanges] = useState({});
  const setStateIfMounted = (setter) => (value) =>
    !unmounted.current ? setter(value) : null;
  const change = useCallback(
    (c) => setStateIfMounted(setDataChanges)((x) => ({ ...x, ...c })),
    [],
  );
  const revert = useCallback(
    (k) =>
      k in dataChanges
        ? setStateIfMounted(setDataChanges)(
          // eslint-disable-next-line no-unused-vars
          ({ [k]: _, ...x }) => x,
        )
        : null,
    [dataChanges],
  );
  useEffect(() => {
    if ((data && data.id !== uploadId) || (!data && error)) {
      setData(null);
      setError(null);
      setDataChanges({});
    }
    if (uploadId && (!data || data.id !== uploadId)) {
      setPending(true);
      getUpload(uploadId, source)
        .then(({ data = {} }) => {
          const {
            nsfw = false,
            nopreroll = false,
            secret = false,
            tags = data.tags?.join(' ') || '',
            labels = data.labels ? data.labels : [],
            date: pubDate = undefined,
            title = data.title,
            description = data.description,
            scripts = data.scripts,
          } = data.post || {};

          // items renamed to files
          if (
            data.files &&
            !data.items &&
            (source === 'incoming' || source === 'archived')
          ) {
            data.items = data.files;
            delete data.files;
          }

          setStateIfMounted(setData)({
            postStatus: data.files
              ? 'draft'
              : pubDate
                ? new Date(pubDate) > new Date(Date.now())
                  ? 'planned'
                  : 'publish'
                : 'draft',
            ...data,
            nsfw,
            nopreroll,
            secret,
            title,
            description,
            scripts,
            pubDate: pubDate ? new Date(pubDate) : undefined,
            tags: tags === '' ? [] : tags.split(' '),
            labels,
            source,
            originalTitle: data.title,
            originalDescription: data.description,
          });
        })
        .catch(setStateIfMounted(setError))
        .finally(() => setStateIfMounted(setPending)(false));
    }
  }, [uploadId, data, source]);

  return {
    data: data
      ? {
        ...data,
        ...dataChanges,
        source,
        isOnline: data.postStatus === 'publish',
      }
      : null,
    pending,
    error,
    change,
    revert,
  };
};

export const useQueryCommandIsSupported = () => {
  const [isSupported, setIsSupported] = useState(false);
  useEffect(() => {
    if (document.queryCommandSupported('copy')) {
      setIsSupported(true);
    }
  }, []);
  return isSupported;
};

export const useGetUnmoderatedComments = (itemIds) => {
  const [unmoderatedCommentsCounts, setUnmoderatedCommentsCounts] = useState(
    {},
  );

  useEffect(() => {
    const fetchUnmoderatedComments = async () => {
      if (!itemIds || itemIds.length === 0) {
        return;
      }
      try {
        const response = await getItemsUnmoderatedComments({ ids: itemIds });
        const counts = response.data.reduce((acc, item) => {
          acc[item.id] = item.unmoderated_comments;
          return acc;
        }, {});

        setUnmoderatedCommentsCounts(counts);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUnmoderatedComments();
  }, [itemIds]);

  return unmoderatedCommentsCounts;
};

export const useGetIsRisk = (articleId) => {
  const [isRisk, setIsRisk] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUnmoderatedCommentsRisk(articleId);
        setIsRisk(response?.data?.is_risk);
      } catch (err) {
        setError(err);
      }
    };

    if (articleId) {
      fetchData();
    }
  }, [articleId]);

  const updateIsRisk = async (articleId) => {
    try {
      const response = await alterCommentsRisk(articleId);
      setIsRisk(response?.data?.is_risk);
    } catch (err) {
      setError(err);
      alert('Please publish first and check if comments are in place...');
    }
  };

  return { isRisk, error, updateIsRisk };
};
export const useGetBlocked = (articleId) => {
  const [isBlocked, setIsBlocked] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getBlockedComments(articleId);
        setIsBlocked(response?.data[0]?.allow_comments);
      } catch (err) {
        setError(err);
      }
    };

    if (articleId) {
      fetchData();
    }
  }, [articleId]);
  const updateIsBlocked = async (articleId) => {
    try {
      const response = await updateBlockedComments(articleId);
      setIsBlocked(response?.data?.allow_comments);
    } catch (err) {
      setError(err);
      alert('Please publish first and check if comments are in place...');
    }
  };

  return { isBlocked, error, updateIsBlocked };
};
