import React, { useCallback, useEffect, useState } from 'react';
import 'twin.macro';
import { useUnmounted } from './hooks';
import { SpinnerIcon } from 'icons';

const minInterval = 550;
const maxInterval = 10000;
const ease = 1.9;

/*  using styles is a temporary solution to align icon */
export const BusyIcon = ({ size }) => <div style={{
  width: `${size}px`, height: `${size}px`,
  display: 'flex', alignItems: 'center', justifyContent: 'center',
}}>
  <SpinnerIcon tw="h-8 w-8" />
</div>;

export const ImageView = ({ src, size, retry, onClick, ...p }) => {
  const unmounted = useUnmounted();
  const [waitInterval, setWaitInterval] = useState(minInterval);
  const [waiting, setWaiting] = useState(null);

  const onError = useCallback(() => {
    if(retry) {
      if(waiting) {
        clearTimeout(waiting);
      }
      if(waitInterval < maxInterval) {
        setWaiting(setTimeout(() => {
          if (!unmounted.current) {
            setWaiting(null);
          }
        }, waitInterval));
        setWaitInterval(Math.min(maxInterval, waitInterval + ease**(waitInterval/100)));
      }
    }
  }, [retry, waitInterval, waiting]);

  useEffect(() => setWaitInterval(minInterval), [src]);

  return <div { ...p}>
    {!waiting && <img src={src} onError={onError} onClick={onClick}
      tw="mx-auto object-cover rounded"/>}
    {waiting && <BusyIcon size={size}/> }
  </div>;
};
