import axios from 'axios';
import { authorizeRequest } from 'services/auth';

const dmp = axios.create({ baseURL: process.env.DMP_HOST, withCredentials: true });
dmp.interceptors.request.use(authorizeRequest);

export const upload = ({ email, name }, files, onUploadProgress) => {
  const date = new Date();

  const formData = new FormData();
  formData.set('user_name', name);
  formData.set('user_email', email);
  formData.set(
    'title',
    `Redactie upload ${
      date.toLocaleDateString('nl-NL', { dateStyle: 'short' })
    } @ ${
      date.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' })
    } by ${name}`);
  formData.set('nr_of_files', files.length);
  for (let i = 0; i < files.length; i++) {
    formData.set(`file${i}`, files[i]);
  }
  formData.set('accept_conditions', '1');
  formData.set('must_accept_conditions', '1');
  return dmp.post('/manage/upload', formData, { onUploadProgress });
};

export const accept = upload => dmp.post(
  `/manage/upload/${upload.upload_id}/accept`,
  { upload },
);

export const archive = (itemId, note) => dmp.post(
  `/manage/upload/${itemId}/archive`,
  { note },
);

export const restore = uploadId => dmp.get(
  `/manage/upload/${uploadId}/restore`,
);

export const applyLabels = (itemId, labels) => dmp.patch(`/manage/upload/${itemId}`, labels);

export const fetch = (uploadId, source) => dmp.get(
  `/manage/upload/${uploadId}`,
  { params: { source } },
)
  .then(({ data }) => data);

export const fetchPage = (params) => dmp.get(
  `/manage/upload/page/${params.page}`,
  { params: { ...params } },
).then(({ data }) => data);

// stub
export const remove = itemId => dmp.delete(
  `/manage/upload/${itemId}?source=archive`,
).then(({ data }) => data);

export const search = ({ page, criteria }) => dmp.get(
  `/manage/archive/${page}`,
  { params: { ...criteria } },
)
  .then(({ data }) => data);

/* below methods were deprecated! thou shall not edit the content of an upload... */

export const uploadItem = async (uploadId, files, source = 'incoming', onUploadProgress) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append(`file${i}`, files[i]);
  }
  const { data } = await dmp.post(
    `/manage/upload/${uploadId}/item`,
    formData,
    { params: { source }, onUploadProgress: onUploadProgress },
  );
  return data;
};

export const deleteItem = (uploadId, itemId, source = 'incoming') => dmp.delete(
  `/manage/upload/${uploadId}/item/${itemId}`,
  { params: { source } },
)
  .then(({ data }) => data);

export const reorderItems = (uploadId, itemIDs, source) => dmp.put(
  `/manage/upload/${uploadId}/reorder`,
  itemIDs,
  { params: { source } },
)
  .then(({ data }) => data);

export const addEmbed = (uploadId, itemId, embedUrl, source) => dmp.post(
  `manage/upload/${uploadId}/item/${itemId}/embed`,
  { embed_url: embedUrl },
  { params: source === 'incoming' ? { source } : {} },
)
  .then(({ data }) => data);

export const removeEmbed = (uploadId, itemId, source) => dmp.delete(
  `manage/upload/${uploadId}/item/${itemId}/embed${
    source === 'incoming' ? '?source=incoming' : ''
  }`,
)
  .then(({ data }) => data);

export const redo = uploadId => dmp.get(
  `manage/rpc/upload/${uploadId}/redo`,
)
  .then(({ data }) => data);

export const uploadAsset = (uploadId, itemId, assetFile) => {
  const formData = new FormData();
  formData.set('file', assetFile);
  return dmp.post(
    `/manage/upload/${uploadId}/item/${itemId}/asset`,
    formData,
  );
};

export const uploadWatermark = (uploadId, itemId, watermarkFile) => {
  const formData = new FormData();
  formData.set('file', watermarkFile);
  return dmp.post(
    `/manage/upload/${uploadId}/item/${itemId}/custom_watermark`,
    formData,
  );
};

export const removeWatermark = (uploadId, itemId) => dmp.delete(
  `/manage/upload/${uploadId}/item/${itemId}/custom_watermark`,
);

export const requestReplaceStills = (uploadId, itemId, assetFilePath) => dmp.post(
  '/manage/job',
  {
    upload: uploadId,
    item: itemId,
    priority: 100,
    type: 'set-poster',
    params: { path: assetFilePath },
  });

export const requestGenerateStills = (uploadId, itemId) => dmp.post(
  '/manage/job',
  {
    upload: uploadId,
    item: itemId,
    priority: 100,
    type: 'stills',
  });

/* */
