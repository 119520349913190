import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { ItemsCheckBox } from '../../selection';
import { EditUploadThumbLink, UploadButtons } from './UploadButtons';
import {
  ListColumnFixed,
  ListColumnGrow,
  ListItemActions,
  ListItemInfo,
  ListItemMedia,
  ListItemUploader,
  ListRow,
} from '../../common';
import { assetPath, thumbLarge, UPLOAD_BASE_URL } from '../../utils';
import axios from 'axios';
import { useUnmounted } from '../../common/hooks';
import { authorizedURL, createAuthHeaders } from 'services/auth';

const framesCache = {};
const loadFrames = async (mediaItem) => {
  if (mediaItem.id in framesCache) {
    return framesCache[mediaItem.id];
  }
  const { data } = await axios.get(`${assetPath(mediaItem)}/thumbs.vtt`, {
    headers: createAuthHeaders(),
  });
  framesCache[mediaItem.id] = data
    .split('\n')
    .filter((x) => x.includes('#xywh='))
    .map((s) => {
      const [image, fr] = s.split('#xywh=');
      const [x, y, w, h] = fr.split(',');
      return {
        image: authorizedURL(`${UPLOAD_BASE_URL}/${image}`),
        x,
        y,
        w,
        h,
      };
    });
  return framesCache[mediaItem.id];
};

export const ItemThumbAnimated = ({ mediaItem }) => {
  const defaultFrame = {
    image: thumbLarge(mediaItem),
    x: 0,
    y: 0,
    w: '100%',
    h: '100%',
  };
  const [frames, setFrames] = useState([defaultFrame]);
  const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
  const [animate, setAnimate] = useState(false);
  const unmounted = useUnmounted();

  const frame = frames[currentFrameIndex];

  useEffect(() => {
    loadFrames(mediaItem)
      .then((f) => {
        if (!unmounted.current) {
          setFrames(f);
        }
      })
      .catch(() => {
        if (!unmounted.current) {
          setFrames([defaultFrame]);
        }
      });
  }, [mediaItem.id]);

  useEffect(() => {
    if (animate) {
      setTimeout(() => {
        if (!unmounted.current) {
          setCurrentFrameIndex((currentFrameIndex + 1) % frames.length);
        }
      }, 256);
    } else {
      setCurrentFrameIndex(0);
    }
  }, [frames, currentFrameIndex, animate, mediaItem]);

  return (
    <div
      onMouseOver={() => setAnimate(true)}
      onMouseLeave={() => setAnimate(false)}
      style={{
        backgroundImage: `url("${frame.image}")`,
        backgroundPosition: `${frame.x}px ${frame.y}px`,
        width: `${frame.w}px`,
        height: `${frame.h}px`,
      }}
    ></div>
  );
};

const getMediaType = (item) => {
  if (item.media_type) {
    return item.media_type;
  }
  if (item.items && item.items.length > 0) {
    const itemWitType = item.items.find((i) => i.media_type);
    if (itemWitType) {
      return itemWitType.media_type;
    }
  }
  return 'unknown_type';
};
export const UploadRow = ({ upload, source, refresh, admins }) => {
  const item = upload;
  const isByAdmin = admins?.includes(item?.uploader?.email);

  return (
    <>
      <ListRow
        style={{ position: 'relative', borderLeft: isByAdmin ? '2px solid #6C2' : 'none',
          borderRight: isByAdmin ? '2px solid #6C2' : 'none'}}>
        <ListColumnFixed>
          <EditUploadThumbLink uploads={[upload]} source={source}>
            <div tw="mb-2 md:mb-0">
              <ListItemMedia still={thumbLarge(item)} />
            </div>
          </EditUploadThumbLink>
          <ListItemInfo
            item={{
              title: item.title,
              description: item.description,
              media_type: getMediaType(item).toUpperCase(),
              viewed_by: item.viewed_by,
            }}
          />
        </ListColumnFixed>
        <ListColumnGrow css={[tw`flex flex-col py-0 md:flex-row md:flex-grow items-center px-2 md:py-2`]}>
          <ListItemUploader
            item={item}
            uploader={item.uploader}
            created={item.date}
            uploadDate
            tw="flex flex-col px-2 w-full md:w-[35%] md:p-2 justify-center items-start"
          />
          <ListItemActions uploadItem>
            <UploadButtons uploads={[upload]} refresh={refresh} source={source}>
              <ItemsCheckBox controlItems={[upload]} />
            </UploadButtons>
          </ListItemActions>
        </ListColumnGrow>
      </ListRow>
    </>
  );
};
