export const rolesMap = {
  ROLE_SUPER_USER: { name: 'Super User', level: 1 },
  ROLE_ADMIN: { name: 'Admin', level: 2 },
  ROLE_EDITOR: { name: 'Redactie', level: 3 },
  ROLE_USER: { name: 'Gebruiker', level: 4 },
};

export const RoleClaim = role => ({
  toString: () => {
    switch (role) {
    case 255:
      return 'ROLE_SUPER_USER';
    case 63:
      return 'ROLE_ADMIN';
    case 15:
      return 'ROLE_EDITOR';
    case 1:
      return 'ROLE_USER';
    default:
      return role;
    }
  },
});

export const getRoleDisplayName = role => {
  const r = RoleClaim(role).toString();
  return rolesMap[r] ? rolesMap[r].name : 'unknown role';
};

export const checkRolePermit = (role, level) => {
  const r = RoleClaim(role).toString();
  return rolesMap[r] && rolesMap[r].level <= level;
};

export const rolesSelectOptions = Object.keys(rolesMap).map(
  k => ({ label: rolesMap[k].name, value: k}),
);