import React, { useEffect, useRef, useState } from 'react';
import Script from 'react-load-script';
import styles from './AgnoVideoPlayer.css';

const settings = {
  PLAYER_SCRIPT_1: '//imasdk.googleapis.com/js/sdkloader/ima3.js',
  PLAYER_SCRIPT_2: '//player.agnoplay.com/static/agnoplay/js/agnoplay.js',
};

export const AgnoVideoPlayer = (
  { itemId, variants, still, title, description, duration, dateCreated, tags, related = [], index = 0 },
) => {
  const ref = useRef({ current: null });
  const [scriptsLoaded, setScriptsLoaded] = useState(0);
  const [scriptsErrors, setScriptsErrors] = useState(0);
  const [player, setPlayer] = useState(null);
  const dateFormat = dateCreated.slice(0, 19).split('T');
  const onScriptLoad = () => setScriptsLoaded(x => x + 1);
  const onScriptError = () => setScriptsErrors(x => x + 1);

  const getWidth = (version) => {
    switch(version) {
    case 'tablet': return 480;
    case 'mobile': return 270;
    case '720p': return 720;
    case '1080p': return 1080;
    default: return 480;
    }
  };

  const config = {
    brand: 'dumpert-admin',
    related: related,
    url: window.location.href,
    socialUrl: window.location.href,
    autoplay: false,
    video_metadata: {
      duration,
      title,
      description,
      date_created: dateFormat[0] + ' ' + dateFormat[1],
      format: 'Dumpert',
      tags: tags && tags.length > 0 ? tags.join() : '',
      category: 'Dumpert',
      publisher: 'Dumpert',
    },
  };

  const stream = variants.find(x => x.version === 'stream');
  const liveStream = variants.find(x => x.version === 'agnoplay');

  if (liveStream) {

    // special item of live streaming from dupmert studio
    config.brand = 'dumpert-live';
    config.videoId = 'tmg2';
  } else if (stream) {
    config.stream_source = 'custom';
    config.custom_source = {
      source: stream.uri,
      thumbnail: still,
      title,
    };
  } else {
    config.stream_source = 'custom';
    config.custom_source = {
      source: variants.map(v => ({'width': getWidth(v.version), 'src': v.uri})),
      thumbnail: still,
      title,
    };
  }


  useEffect(() => {
    if (scriptsLoaded + scriptsErrors > 1) {
      setPlayer(x => {
        if(x) {
          window.AGNO.disposePlayer(x);
        }
        return window.AGNO.insertPlayer(config, ref.current);
      });
    }
  }, [scriptsLoaded + scriptsErrors, variants.map(({ uri }) => uri).join()]);

  useEffect(() => () => {
    if (player) {
      try {
        window.AGNO.disposePlayer(player);
      } catch (e) { /* no-op */ }

      // remove tags added by AGNO player
      [].filter.call(
        document.getElementsByTagName('script'),
        x => x.type === 'application/ld+json' && x.id && x.id.startsWith('videoObject-'),
      ).forEach(x => x.remove());
    }
  }, [player]);

  return (
    <>
      <Script url={`${settings.PLAYER_SCRIPT_1}`} onLoad={onScriptLoad} onError={onScriptError} />
      <Script url={`${settings.PLAYER_SCRIPT_2}`} onLoad={onScriptLoad} onError={onScriptError} />
      {scriptsErrors > 1 && <span>{`${scriptsErrors} errors player script is not loaded`}</span>}
      <div className={styles.videoplayer_container}>
        <div
          ref={ref}
          id={index > 0 ? `${itemId}-${index}` : itemId}>
        </div>
      </div>
    </>
  );
};