import React, { useCallback, useState } from 'react';
import 'twin.macro';
import { applyLabels, archive } from 'services/uploads';
import { selectionRemoveAction } from '../../selection/ducks';
import { useDispatch } from 'react-redux';
import {
  ActionButton,
  SwitchButtons,
  ActionsDivider as Divider,
  Tags,
} from '../../common';
import { uploadToPost } from '../../edit';
import { createPost } from 'services/dmp';
import { useShowErrorMessage, useUnmounted } from '../../common/hooks';
import { useHistory } from 'react-router-dom';

export const CreatePost = (props) => (
  <ActionButton {...{ label: 'post', ...props }} buttons={false}/>
);
export const EditUpload = (props) => (
  <ActionButton {...{ label: 'edit', ...props }} buttons={false}/>
);
export const ArchiveUpload = (props) => (
  <ActionButton {...{ label: 'prullenbak', ...props }} buttons={false}/>
);

export const EditUploadThumbLink = ({ uploads, children, source }) => {
  const history = useHistory();
  return (
    <>
      {uploads.length === 1 && (
        <div
          tw="cursor-pointer h-full w-full md:w-auto mb-2 md:mb-0"
          onClick={() => {
            history.push({
              pathname: `/edit/${uploads[0].id}`,
              search: `?source=${source}`,
              state: { next: history.location.pathname },
            });
            sessionStorage.setItem('scrollPosition', window.scrollY);
          }
          }
        >
          {children}
        </div>
      )}
      {uploads.length !== 1 && <>{children}</>}
    </>
  );
};

export const UploadButtons = ({
  uploads,
  refresh,
  children,
  source,
  editButtons = true,
}) => {
  const unmounted = useUnmounted();
  const [enabled, setEnabled] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const showErrorMessage = useShowErrorMessage();

  const uploadsIds = uploads.map((x) => x.id).join();
  const setEnabledIfMounted = () =>
    !unmounted.current ? setEnabled(true) : null;
  const updateUploadsList = () => {
    dispatch(selectionRemoveAction(uploads));
    refresh();
  };

  const setLabel = useCallback(
    (selected) => {
      applyLabels(uploads[0].id, { status: selected }).catch(showErrorMessage);
    },
    [uploadsIds],
  );

  const createPostFromUpload = useCallback(() => {
    setEnabled(false);
    return Promise.all(
      uploads.map((x) => {
        const { post, publishWhenDone } = uploadToPost(x);
        return createPost(post, publishWhenDone);
      }),
    )
      .then(updateUploadsList)
      .catch(showErrorMessage)
      .finally(setEnabledIfMounted);
  }, [uploadsIds]);

  const archiveUpload = useCallback(() => {
    setEnabled(false);
    return Promise.all(uploads.map((x) => archive(x.id)))
      .then(updateUploadsList)
      .catch(showErrorMessage)
      .finally(setEnabledIfMounted);
  }, [uploadsIds]);

  return (
    <>
      <div tw="flex mb-2 sm:mb-0 flex-col">
        {uploads.length === 1 && (
          <>
            <SwitchButtons
              buttons={[
                { title: 'Afkeuren', value: 'rejected', color: 'danger' },
                { title: 'Kan online', value: 'approved', color: 'success' },
                { title: 'Repost', value: 'repost', color: 'warning' },
                { title: 'Twijfel', value: 'dubious', color: 'info' },
              ]}
              selected={uploads[0].labels}
              onSelect={setLabel}
            />
            <div tw="block">
              {uploads[0]?.viewed_by?.length > 0 && (
                <div tw="block mt-2">
                  <span tw="font-medium text-gray-900 mr-1">
                    Gechecked door:
                  </span>
                  <Tags list={uploads[0]?.viewed_by} max={2} invisTillMin={0} />
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div tw="flex items-start mb-2 sm:mb-0">
        {editButtons && uploads.length === 1 && (
          <>
            <EditUpload
              onClick={() =>
                history.push({
                  pathname: `/edit/${uploads[0].id}`,
                  search: `?source=${source}`,
                  state: { next: history.location.pathname },
                })
              }
            />
            <Divider />
          </>
        )}
        {editButtons && uploads.length > 0 && (
          <>
            <CreatePost onClick={createPostFromUpload} disabled={!enabled} />
            {source !== 'archived' && (
              <>
                <Divider />
                <ArchiveUpload onClick={archiveUpload} disabled={!enabled} />
              </>
            )}
          </>
        )}
        {children}
      </div>
    </>
  );
};
