import {CURRENT_TRIGGER_REFRESH, CURRENT_UPDATE, CURRENT_UPLOADS_UPDATE, CURRENT_UPLOADS_SEARCH} from './actions';

export const currentInitialState = {
  uploads: [],
  jobs: [],
  uploadIDsJobsDone: [],
  refreshAssetsKey: Math.random(),
};

export const currentReducer = (state = currentInitialState, { type, payload }) => {
  switch (type) {
  case CURRENT_UPDATE:
    return {
      ...payload,
      uploadIDsJobsDone: findUploadsDone(state, payload),
      refreshAssetsKey: state.refreshAssetsKey,
    };
  case CURRENT_UPLOADS_SEARCH:
    return { ...state, uploads: payload };
  case CURRENT_UPLOADS_UPDATE:
    return { ...state, uploads: payload };
  case CURRENT_TRIGGER_REFRESH:
    return { ...state, refreshAssetsKey: Math.random() };
  default:
    break;
  }
  return state;
};

const findUploadsDone = ({ jobs: oldJobs }, { jobs: newJobs }) => {
  const newUploadIds = new Set(newJobs.map(j => j.item.upload_id));
  return [...oldJobs.reduce((s, j) => !newUploadIds.has(j.item.upload_id) && s.add(j.item.upload_id) || s, new Set())];
};
