import React, { useCallback, useState } from 'react';
import tw from 'twin.macro';
import { upload } from 'services/uploads';
import { FileUploadIcon, SpinnerIcon } from 'icons';
import { useShowErrorMessage, useUnmounted } from '../common/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthUser } from '../auth/ducks';
import { Button } from '../common';
import { useHistory } from 'react-router-dom';
import { postsFetchOp } from '../posts/ducks';
import { Progress } from 'react-bulma-components';

const UploadButton = ({ onSelectFiles, label, loading }) => 
  <Button 
    css={[
      tw`hover:cursor-pointer hover:bg-blue-200`, 
      loading && tw`pt-1 pb-2.5`,
    ]} 
    Icon={loading ? SpinnerIcon : FileUploadIcon}>
    <input className="file-input" type="file" name="admin-upload" onChange={e => onSelectFiles(e.target.files || [])}
      disabled={loading} multiple />
    {loading ? 'uploading' : label}
  </Button>;

export const CreateUploadButton = () => {
  const unmounted = useUnmounted();
  const [uploadProgress, setUploadProgress] = useState(-1);
  const authUser = useSelector(selectAuthUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const uploading = uploadProgress > -1;

  const showErrorMessage = useShowErrorMessage();

  const uploadFiles = useCallback(files => {
    if (files.length > 0) {
      setUploadProgress(0);
      upload(authUser, files, e => {
        if(!unmounted.current) {
          setUploadProgress(e.loaded/e.total);
        }
      })
        .then(() => {
          const { pathname, search } = history.location;
          if(pathname === '/posts') {
            if(search) {
              history.push(pathname);
            } else {
              dispatch(postsFetchOp('all', 0, 'all'));
            }
          }
        })
        .catch(showErrorMessage)
        .finally(() => {
          if (!unmounted.current) {
            setUploadProgress(-1);
          }
        });
    }
  }, [authUser]);

  return (
    <div tw="flex flex-col md:items-center">
      <UploadButton
        label="Redactie upload"
        onSelectFiles={uploadFiles}
        loading={uploading}
        uploadProgress={uploadProgress}
      />
      {uploading && <Progress color="success" tw="-mt-2 h-1 z-10 
      rounded-md w-11/12" max={100} value={Math.round(uploadProgress * 100)} />}
    </div>);
};
