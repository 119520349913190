import React, { useState, useEffect } from 'react';
import { HeroPage, FormPage, Notification } from '../common';
import Field from 'react-bulma-components/cjs/components/form/components/field';
import { Control, Input, Label } from 'react-bulma-components/cjs/components/form';
import Button from 'react-bulma-components/cjs/components/button';
import { authActivateUserOp } from './ducks';
import { loadQRCodeImage } from 'services/auth';
import { OmitKeys } from '../utils';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';


export const ActivatePage = ({ history }) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [otp, setOTP] = useState('');
  const [rememberIP, setRememberIp] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const validate = () => ({
    password: !password ? 'Password can\'t be empty' : null,
    passwordConfirm: passwordConfirm !== password ? 'Passwords Don\'t Match' : null,
    otp: rememberIP && otp === '' ? 'invalid OTP' : null,
  });

  useEffect(() => {
    const errors = validate();
    const valid = Object.keys(errors)
      .filter(k => errors[k] === null && validationErrors[k] !== null);
    if (valid.length) {
      setValidationErrors(OmitKeys(validationErrors, valid));
    }

  }, [password, passwordConfirm, otp, rememberIP]);

  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const email = params.get('email');

  if (!code || !email) {
    return (
      <HeroPage>
        <Notification color="danger" onRemove={() => history.push('/')}>
          <p>Invalid Link</p>
        </Notification>
      </HeroPage>
    );
  }

  const [totpKey, setTotpKey] = useState('');
  useEffect(() => {
    loadQRCodeImage(email, code)
      .then(totpKey => setTotpKey(totpKey))
      .catch(() => setTotpKey(null));
  }, []);


  return (
    <FormPage header={
      submitError &&
      <Notification color="danger" onRemove={() => setSubmitError(null)}>
        {submitError}
      </Notification>
    }>
      <Field>
        <Label>Wachtwoord</Label>
        <Control>
          <Input type="password" placeholder="*******" required disabled={disabled} autoComplete={'off'}
            color={validationErrors.password ? 'danger' : undefined}
            value={password} onChange={e => setPassword(e.target.value)}/>
        </Control>
      </Field>
      <Field>
        <Label>Herhaal</Label>
        <Control>
          <Input type="password" placeholder="*******" required disabled={disabled} autoComplete={'off'}
            color={validationErrors.passwordConfirm ? 'danger' : undefined}
            value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)}/>
        </Control>
      </Field>
      <Field>
        <Label>OTP</Label>
        <Control>
          <Input type="text" placeholder="code" disabled={disabled} autoComplete={'off'}
            color={validationErrors.otp ? 'danger' : undefined}
            value={otp} onChange={e => setOTP(e.target.value)}/>
        </Control>
      </Field>
      <Field>
        <Control onClick={() => {
          if (!disabled) {
            setRememberIp(!rememberIP);
          }
        }}>
          <input className="is-checkradio is-small"
            type="checkbox" name="ch" disabled={disabled}
            checked={rememberIP} onChange={() => null}/>
          <label>
            <small>In het vervolg inloggen zonder OTP vanaf dit IP</small>
          </label>
        </Control>
      </Field>
      <Field>
        <Control>
          <Button loading={disabled} submit onClick={
            e => {
              e.preventDefault();
              setSubmitError(null);
              const errors = validate();
              setValidationErrors(errors);
              if (Object.values(errors).some(x => x)) {
                return;
              }
              setDisabled(true);
              dispatch(authActivateUserOp(email, password, otp,  code))
                .then(() => {
                  history.replace('/');
                })
                .catch(e => {
                  setSubmitError(`${e.status} ${e.statusText}`);
                  setDisabled(false);
                });
            }
          }>Verzenden</Button>
        </Control>
      </Field>
      <>{totpKey}</>
    </FormPage>
  );
};
