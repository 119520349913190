import React from 'react';
import tw, {styled} from 'twin.macro';
import styles from './ItemsBar.css';
import {thumbLarge} from '../utils';
import {Button, ImageView} from '../common';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {DeleteIcon, PlusIcon, SpinnerIcon} from 'icons';
import {Progress} from 'react-bulma-components';

export const RemoveButton = ({onClick}) =>
  <Button
    tw="absolute right-2 top-2 cursor-pointer rounded-full p-1.5 opacity-60 hover:opacity-100"
    onClick={onClick}>
    <DeleteIcon tw="w-4 h-4 text-gray-400"/>
  </Button>
;

export const ItemThumb = ({mediaItem, isSelected, onClick}) => (
  <div className={`${styles.left} ${isSelected ? styles.selected : ''}`}>
    <ImageView src={thumbLarge(mediaItem)} onClick={onClick} retry/>
  </div>
);

export const StyledThumbWrapper = styled.div(({isSelected}) => [
  tw`h-16 w-16 rounded`,
  isSelected && tw`border-4 border-blue-600 bg-blue-600`,
]);

export const ItemThumbs = ({mediaItem, isSelected, onClick}) => (
  <StyledThumbWrapper isSelected={isSelected} onClick={() => onClick(mediaItem)}>
    <ImageView src={thumbLarge(mediaItem)} onClick={onClick} retry/>
  </StyledThumbWrapper>
);


export const ItemsBar = ({upload, selectedItem, setSelectedItem, onSelectItem, onAddItems, onRemoveItem, onReorderItem,
  uploadProgress }) => {
  const uploading = uploadProgress > -1;

  const handleUploadSourceChange = (e) => {
    setSelectedItem({ ...selectedItem, upload_source: e.target.value });
  };

  return <>
    <div tw="grid grid-cols-2 gap-2">
      {upload.items && <div>{upload.items.length} {upload.items.length === 1 ? 'item' : 'items'}</div>}
      <div>
        <label htmlFor="source">Source:</label>
        <input type="text" id='source' value={selectedItem.upload_source}
          css={[tw`border shadow-sm pl-1 ml-2 border-gray-300 rounded-md focus:ring-indigo-500
            focus:border-indigo-500 focus:ring-1 text-gray-700`, styles.input]}
          onChange={handleUploadSourceChange}
        />
      </div>
    </div>
    <div tw="flex my-3">
      {upload.items && upload.items.length > 1 &&
        <DragDropContext onDragEnd={onReorderItem}>
          <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={!onReorderItem}>
            {(provided) => <div className={styles.sortableWrapper} ref={provided.innerRef}
              {...provided.droppableProps}>
              {upload.items && upload.items.map(
                (item, index) =>
                  <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!onReorderItem}>
                    {(provided) => <div tw="block relative mr-4" ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <ItemThumbs
                        mediaItem={item}
                        isSelected={selectedItem && selectedItem.id === item.id}
                        onClick={() => onSelectItem(item)}
                        tw="mx-auto object-cover rounded h-16 w-16 "
                      />
                      {onRemoveItem && <RemoveButton onClick={() => onRemoveItem(item.id)}/>}
                    </div>}
                  </Draggable>,
              )}
              {provided.placeholder}
            </div>}
          </Droppable>
        </DragDropContext>}
      {onAddItems && <div tw="block h-16 w-16 shadow">
        <label htmlFor="input-file" tw="rounded border-2 h-16 w-16 flex justify-center items-center
      border-gray-300 text-gray-300 hover:text-gray-400 hover:border-gray-400 cursor-pointer transition-all">
          {uploading ?
            <SpinnerIcon tw="w-12 h-12 cursor-pointer"/> :
            <PlusIcon tw="w-12 h-12 cursor-pointer"/>
          }
        </label>
        <input type="file" id="input-file" tw="invisible absolute"
          onChange={e => onAddItems(e.target.files)} multiple/>
        {uploading && <Progress color="success" tw="-mt-2 h-1 z-10
          rounded-md w-11/12" max={100} value={Math.round(uploadProgress * 100)}/>}
      </div>}
    </div>
  </>;
};
