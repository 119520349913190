import React from 'react';

export const StrongLabel = ({ text}) => 
  <p>
    <label>
      <strong>{text}</strong>
    </label>
  </p>;





