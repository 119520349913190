import React from 'react';
import 'twin.macro';
import { Button } from '../common';
import { UploadButtons } from '../current/uploads';

export const PrevButton = p =>
  <Button label='Vorige' {...p} />;

export const NextButton = p =>
  <Button label='Volgende' {...p} />;

export const PrevNextButtons = ({upload, prev, next}) => {
  const incoming = 'incoming';

  return (
    <div tw="max-w-screen-xl mx-auto justify-between px-2 md:px-6 pt-8 md:flex">
      {upload && <div className="basis-1/4">
        {upload.next && <PrevButton onClick={next} />}
      </div>}

      {upload && <div className="basis-1/4">
        <UploadButtons uploads={[upload]} source={incoming} editButtons={false} />
      </div>}

      {upload && upload.accepted && upload.accepted !== '' && <div className="basis-1/4">
        <p style={{color: 'green'}}>Deze upload is al geplaatst.</p>
      </div>}
      {upload && upload.archived && upload.archived !== '' && <div className="basis-1/4">
        <p style={{color: 'red'}}>Deze upload is gearchiveerd.</p>
      </div>}

      {upload && upload.previous && <div className="basis-1/4">
        <NextButton style={{class: 'basis-1/4'}} onClick={prev} />
      </div>}
    </div>
  );
};
