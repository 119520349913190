import React from 'react';
import Hero from 'react-bulma-components/cjs/components/hero';
import Container from 'react-bulma-components/cjs/components/container';
import Columns from 'react-bulma-components/cjs/components/columns';
import Box from 'react-bulma-components/cjs/components/box';


export const HeroPage = ({ children, size = 6 }) => {

  return (
    <Hero size="fullheight" gradient>
      <Hero.Body>
        <Container>
          <Columns centered>
            <Columns.Column tablet={{ size: size + 2 }} desktop={{ size: size + 1 }} widescreen={{ size }}>
              {children}
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export const FormPage = ({ header, children, size = 4 }) => {

  return (
    <Hero size="fullheight" gradient>
      {header && <Hero.Header>
        {header}
      </Hero.Header>}
      <Hero.Body renderAs="form">
        <Container>
          <Columns centered>
            <Columns.Column tablet={{ size: size + 2 }} desktop={{ size: size + 1 }} widescreen={{ size }}>
              <Box>
                {children}
              </Box>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  );
};
