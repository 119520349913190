import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'draft-js/dist/Draft.css';
import { Editor, EditorState, RichUtils, CompositeDecorator } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { Input, Button } from '../common';
import tw, { css } from 'twin.macro';
import { editorCSS } from './RichTextEditorStyles';
import { BxsMoonIcon, BxsSunIcon, SaveIcon } from 'icons';

// eslint-disable-next-line react/display-name
const ToggleStyleButton = label => ({ selected, onMouseDown }) => <Button
  css={[
    tw`mr-1 py-1 px-1 flex-1 justify-center text-gray-800 bg-gray-50 border border-gray-300 
    hover:bg-gray-100 hover:text-gray-900`,
    selected && tw`text-black font-bold bg-gray-300 hover:(bg-gray-300 text-black)`,
  ]}
  onMouseDown={onMouseDown}>
  <span>{label}</span>
</Button>;

export const ToggleBoldButton = ToggleStyleButton('bold');
export const ToggleItalicButton = ToggleStyleButton('italic');
export const ToggleUnderlineButton = ToggleStyleButton('underline');
export const ToggleStrikeThroughButton = ToggleStyleButton('strikethrough');
export const ToggleLinkButton = ToggleStyleButton('link');

const inlineStyleButtons = {
  BOLD: ToggleBoldButton,
  ITALIC: ToggleItalicButton,
  UNDERLINE: ToggleUnderlineButton,
  STRIKETHROUGH: ToggleStrikeThroughButton,
};


const styleToHTML = (style) => {
  if (style === 'STRIKETHROUGH') {
    return <s />;
  }
};

const entityToHTML = (entity, originalText) => {
  if (entity.type === 'LINK') {
    return <a href={entity.data.url} target="_blank" rel="noreferrer">{originalText}</a>;
  }
  return originalText;
};
const htmlToEntity = (nodeName, node, createEntity) => {
  if (nodeName === 'a') {
    return createEntity(
      'LINK',
      'MUTABLE',
      { url: node.href },
    );
  }
};

const toHTML = convertToHTML({ styleToHTML, entityToHTML });
const fromHTML = convertFromHTML({ htmlToEntity });

const LinkUrlInput = ({ url, onSave }) => {
  const [text, setText] = useState('');
  const change = useCallback(({ target }) => setText(target.value), [setText]);
  const save = useCallback(e => {
    e.preventDefault();
    onSave(text);
  }, [text]);

  useEffect(() => {
    setText(url);
  }, [url]);

  return <div tw="w-full flex mb-2">
    <Input autoFocus={true} tw="mr-2" onBlur={save} onChange={change} type="text"
      value={text} onMouseDown={e => e.preventDefault()} />
    <Button label="Opslaan" Icon={SaveIcon} />
  </div>;
};

const decorator = new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === 'LINK'
          );
        },
        callback,
      );
    },
    // eslint-disable-next-line react/display-name
    component: p =>
      <a href={p.contentState.getEntity(p.entityKey).getData()}>
        {p.children}
      </a>,
  },
]);

export const RichTextEditor = ({ text, onChange, hideButtons = false, onBlur, ctw, shouldFocus = true }) => {
  let initstate = EditorState.createEmpty(decorator);
  if (text) {
    initstate = EditorState.createWithContent(
      fromHTML(text),
      decorator,
    );
  }

  const [editorState, setEditorState] = useState(initstate);
  const [editorNightMode, setEditorNightMode] = useState(false);
  const [showLinkInput, setShowLinkInput] = useState(false);
  const editorRef = useRef();

  const ToggleNightModeButton = ToggleStyleButton(editorNightMode ? <BxsMoonIcon /> : <BxsSunIcon />);

  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const selectedEntity = currentContent.getBlockForKey(selection.getStartKey()).getEntityAt(selection.getStartOffset());

  const changeText = useCallback(s => {
    onChange(toHTML(s.getCurrentContent()));
    setEditorState(s);
  }, [onChange]);

  const onEditorBlur = useCallback(() => {
    if (onBlur) {
      onBlur(toHTML(editorState.getCurrentContent()));
    }
  }, [onBlur, editorState]);

  const toggleStyle = Object.keys(inlineStyleButtons).reduce((a, s) => ({
    ...a,
    [s]: useCallback(e => {
      e.preventDefault();
      setEditorState(RichUtils.toggleInlineStyle(editorState, s));
    }, [editorState]),
  }), {});

  const toggleLinkInput = useCallback(e => {
    e.preventDefault();
    if (!selection.isCollapsed()) {
      setShowLinkInput(true);
    }
  }, [editorState]);

  const saveLinkURL = useCallback(url => {
    if (url) {
      const contentStateWithEntity = currentContent.createEntity('LINK', 'MUTABLE', { url });
      setEditorState(RichUtils.toggleLink(
        EditorState.set(editorState, { currentContent: contentStateWithEntity }),
        selection,
        contentStateWithEntity.getLastCreatedEntityKey(),
      ));
    } else {
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
    }
    setShowLinkInput(false);
  }, [editorState]);

  useEffect(() => {
    if (showLinkInput === false) {
      setTimeout(() => {
        if (editorRef.current) {
          if (shouldFocus) {
            editorRef.current.focus();
          }
        }
      }, 0);
    }
  }, [showLinkInput]);

  useEffect(() => {
    setEditorState(s => 
      toHTML(s.getCurrentContent()) === text ? s : EditorState.createWithContent(
        fromHTML(text),
        decorator,
      ));
  }, [text]);


  return (
    <div tw="flex flex-col">
      {!hideButtons && <>
        <div tw="flex pb-1">
          {Object.entries(inlineStyleButtons).map(
            ([S, B]) => <B key={S} onMouseDown={toggleStyle[S]}
              selected={editorState.getCurrentInlineStyle().has(S)}/>,
          )}
          <ToggleLinkButton onMouseDown={toggleLinkInput} selected={showLinkInput}/>
          <ToggleNightModeButton
            onMouseDown={() => setEditorNightMode(!editorNightMode)}
            selected={editorNightMode}
          />
        </div>
      </>}
      {showLinkInput &&
      <LinkUrlInput onSave={saveLinkURL}
        url={selectedEntity && currentContent.getEntity(selectedEntity).getData().url || ''}/>}
      <div css={[editorCSS, ctw, editorNightMode && css`color:#fff; background: #666;`]}>
        <Editor ref={editorRef}
          editorState={editorState}
          onChange={changeText}
          onBlur={onEditorBlur}
          stripPastedStyles
        />
      </div>
    </div>
  );
};
