import React from 'react';
import 'twin.macro';
import { PlusIcon } from 'icons';
import { Tag, FormField, InputButton } from '../common';

export const TagsEditor = ({ tags, onChange }) => {
  const addTag = text => {  
    const filteredTags = [
      ...tags,
      ...text
        .split(' ')
        .filter(tag => tag !== ''),
    ];
    onChange([...new Set(filteredTags)]);
  };

  const removeTag = (tag) => {
    onChange(tags.filter(t => t !== tag));
  };

  return(
    <>
      <FormField
        label="tags"
        name="tags"
        placeholder='tags toevoegen'
        autoComplete="off"
        buttonLabel={<PlusIcon tw="h-4 w-4" />}
        onPush={addTag}
        FieldType={InputButton}
      />
      {!!tags && tags.map(
        t => <Tag key={t} label={t} color="blue" removeHandler={removeTag} removable />,
      )}
    </>
  );
};
