import React from 'react';

export const BxsDownloadIcon = props =>
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1em"
    width="1em"
    {...props}
  >
    <path d="M19 9h-4V3H9v6H5l7 8zM4 19h16v2H4z" />
  </svg>;
