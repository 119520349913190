// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons--b9WpxV {
  flex-grow: 2;
  margin-right: 0rem;
  margin-bottom: 0 !important;
}

.buttons--b9WpxV button {
  margin-bottom: 0 !important;
}

@media only screen and (min-width: 768px) {
  .buttons--b9WpxV {
    margin-right: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/common/SwitchButtons.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".buttons {\n  flex-grow: 2;\n  margin-right: 0rem;\n  margin-bottom: 0 !important;\n}\n\n.buttons button {\n  margin-bottom: 0 !important;\n}\n\n@media only screen and (min-width: 768px) {\n  .buttons {\n    margin-right: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": `buttons--b9WpxV`
};
export default ___CSS_LOADER_EXPORT___;
