import React, { useEffect, useState } from 'react';
import 'twin.macro';

export const EditableCell = ({ value, onChange }) => {
  const [currentValue, setCurrentValue] = useState('');
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  return (
    <div tw="flex w-full justify-between">
      {!editMode && (
        <span
          tw="select-none w-full cursor-pointer"
          onClick={() => setEditMode(true)}
        >
          {value}
        </span>
      )}
      {editMode && (
        <input
          tw="border shadow-sm pl-1 pr-0.5 block w-full border-gray-300 rounded-md
      focus:ring-indigo-500 focus:border-indigo-500 focus:ring-1"
          type="number"
          value={currentValue}
          autoFocus
          onChange={(e) => setCurrentValue(e.target.value)}
          onBlur={() => setEditMode(false)}
        />
      )}
      {editMode && (
        <button
          tw="ml-2 px-4 font-black text-green-600 bg-blue-100 hover:text-green-500 rounded-full"
          onMouseDown={() => onChange(currentValue)}
        >
          ✓
        </button>
      )}
    </div>
  );
};
