import * as React from 'react';
import tw from 'twin.macro';
import { ItemsCheckBox } from '../selection';
import { PostButtons, EditPostThumbLink } from './PostButtons';
import { selectCurrentJobsByUpload } from '../current/ducks';
import { useSelector } from 'react-redux';

import {
  ListRow,
  ListColumnGrow,
  ListItemMedia,
  ListItemUploader,
  ListItemInfo,
  ListItemStatus,
  ListItemActions,
} from '../common';

export const Post = ({ item, admins }) => {
  const jobsByUpload = useSelector(selectCurrentJobsByUpload);
  const uploadProgress =
    (jobsByUpload[item.upload_id] && jobsByUpload[item.upload_id].total) || 0;
  const uploader = item.uploader ? { ...item.uploader, ip_address: null } : {};

  // check if the uploader is in the admins list
  const isByAdmin = admins?.includes(uploader?.email);

  return (
    <ListRow
      style={{
        borderLeft: isByAdmin ? '2px solid #6C2' : 'none',
        borderRight: isByAdmin ? '2px solid #6C2' : 'none',
        backgroundColor: new Date(item.date) < new Date(Date.now()) ? 'rgb(241 255 240)' : 'white',
      }}
    >
      <ListColumnGrow css={[tw`basis-8/12 flex flex-col py-0 md:flex-row items-center px-2 md:py-1`]}>
        <EditPostThumbLink posts={[item]}>
          <div tw="mb-2 md:mb-0">
            <ListItemMedia still={item.still} />
          </div>
        </EditPostThumbLink>
        <ListItemInfo item={item} />
      </ListColumnGrow>
      <ListColumnGrow css={[tw`basis-4/12 flex flex-col py-0 md:flex-row items-center px-2 md:py-2`]}>
        <ListItemActions>
          <PostButtons posts={[item]} />
        </ListItemActions>

        <ListItemUploader item={item} uploader={uploader} created={item.date}
          tw="flex flex-col px-2 w-full md:p-2 justify-center items-start">
          <ListItemStatus uploadProgress={uploadProgress} date={item.date} />
        </ListItemUploader>

        <ListItemActions>
          <ItemsCheckBox controlItems={[item]} />
        </ListItemActions>
      </ListColumnGrow>
    </ListRow>
  );
};
