import React from 'react';

export const BxPauseIcon = props =>
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1em"
    width="1em"
    {...props}
  >
    <path d="M8 7h3v10H8zm5 0h3v10h-3z" />
  </svg>;
