import { AUTH_USER } from './actions';

export const authInitialState = {
  user: null,
};

export const authReducer = (state = {}, { type, payload }) => {
  switch (type) {
  case AUTH_USER:
    return { ...state, user: payload };
  }
  return state;
};

/*
Selector selectAuthUser returned a different result when called with the same parameters.
https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization {
*/
const emptyUser = {};
export const selectAuthUser = state => {
  return state.auth.user || emptyUser;
};
