import React, { useEffect, useState } from 'react';
import Field from 'react-bulma-components/cjs/components/form/components/field';
import Columns from 'react-bulma-components/cjs/components/columns';
import { Control, Input, Label } from 'react-bulma-components/cjs/components/form';
import { rolesSelectOptions } from './roles';
import { Button, Notification, PopupCard, SelectControl } from '../common';
import { createUser } from 'services/dmp';
import { useStore } from 'react-redux';
import { selectUsersListFilter } from './ducks';
import { usersFetchListOp } from './ducks/operations';
import { OmitKeys } from '../utils';
import { PlusIcon } from 'icons';

export const CreateUserButton = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('ROLE_USER');
  const [modalActive, setModalActive] = useState(false);
  const { dispatch, getState } = useStore();
  const [validationErrors, setValidationErrors] = useState({});

  const open = () => setModalActive(true);
  const close = () => {
    if(!loading) {
      setModalActive(false);
    }
  };
  const clear = () => {
    setError(null);
    close();
  };
  const validate = () => ({
    email: /^\S+@\S+$/.test(email) ? null : 'invalid value',
    name: name.length > 0 ? null : 'invalid value',
  });
  const submit = async () => {
    const errors = validate();
    setValidationErrors(errors);
    if (Object.values(errors).some(x => x)) {
      return;
    }
    try {
      setLoading(true);
      await createUser(name, email, role);
      dispatch(usersFetchListOp(selectUsersListFilter(getState())));
      setModalActive(false);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const errors = validate();
    const valid = Object.keys(errors)
      .filter(k => errors[k] === null && validationErrors[k] !== null);
    if (valid.length) {
      setValidationErrors(OmitKeys(validationErrors, valid));
    }
  }, [name, email]);

  return (
    <>
      <Button Icon={PlusIcon} onClick={open} />
      <PopupCard
        show={modalActive}
        onClose={close}
        title={'Gebruiker aanmaken'}
        footer={
          <Button onClick={error ? clear : submit} loading={loading} disabled={loading}>
            {error ? 'OK' : 'Verzenden'}
          </Button>
        }>
        {error &&
        <Notification color="danger">
          <strong>{error.status}</strong>
          <p>{error.statusText}</p>
        </Notification>
        }
        {!error &&
        <Columns>
          <Columns.Column size="three-fifths" offset="one-fifth">
            <Field>
              <Label>Naam</Label>
              <Control>
                <Input type="text" placeholder="naam" autoComplete={'off'}
                  color={validationErrors.name ? 'danger' : undefined}
                  required value={name} onChange={e => setName(e.target.value)} disabled={loading}/>
              </Control>
            </Field>
            <Field>
              <Label>Email</Label>
              <Control>
                <Input type="email" placeholder="e.g. bobsmith@gmail.com" autoComplete={'off'}
                  color={validationErrors.email ? 'danger' : undefined}
                  required value={email} onChange={e => setEmail(e.target.value)} disabled={loading}/>
              </Control>
            </Field>
            <Field>
              <Label>Soort gebruiker</Label>
              <SelectControl value={role} options={rolesSelectOptions.filter(
                ({ label }) => ['Admin', 'Redactie', 'Gebruiker'].includes(label),
              )} onChange={setRole} disabled={loading}/>
            </Field>
          </Columns.Column>
        </Columns>
        }
      </PopupCard>
    </>
  );

  // return (
  //   <>
  //     <Button color="primary" onClick={open}>
  //       Gebruiker aanmaken
  //     </Button>
  //     <PopupCard
  //       show={modalActive}
  //       onClose={close}
  //       title={'Gebruiker aanmaken'}
  //       footer={
  //         <Button onClick={error ? clear : submit} loading={loading} disabled={loading}>
  //           {error ? 'OK' : 'Verzenden'}
  //         </Button>
  //       }>
  //
  //
  //       {error &&
  //       <Notification color="danger">
  //         {error.toString()}
  //       </Notification>
  //       }
  //       {!error &&
  //       <Columns>
  //         <Columns.Column size="three-fifths" offset="one-fifth">
  //           <Field>
  //             <Label>Naam</Label>
  //             <Control>
  //               <Input type="text" placeholder="naam" autoComplete={'off'}
  //                      required value={name} onChange={e => setName(e.target.value)} disabled={loading}/>
  //             </Control>
  //           </Field>
  //           <Field>
  //             <Label>Email</Label>
  //             <Control>
  //               <Input type="email" placeholder="e.g. bobsmith@gmail.com" autoComplete={'off'}
  //                      required value={email} onChange={e => setEmail(e.target.value)} disabled={loading}/>
  //             </Control>
  //           </Field>
  //           <Field>
  //             <Label>Soort gebruiker</Label>
  //             <SelectControl value={role} options={rolesSelectOptions} onChange={setRole}/>
  //           </Field>
  //         </Columns.Column>
  //       </Columns>
  //       }
  //     </PopupCard>
  //     < />
  //
  //     );
};
