import React, { useCallback } from 'react';
import 'twin.macro';
import { NavBar } from '../navbar';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Conversion, PostsConversion, PostsConversionArchive, Uploads } from '../current';
import { Posts } from '../posts';
import { Users } from '../users';
import { Moderation, DummyPage, BlackList, CensoredPhrases } from '../moderation';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthUser } from '../auth/ducks';
import { checkRolePermit } from '../users/roles';
import { selectMessage, setMessageAction } from '../store';
import { PopupCard } from '../common';
import { Notification } from 'react-bulma-components';
import { EditItemPage, ImportItemPage } from '../edit';

const links = [
  { title: 'Uploads', path: '/uploads', component: Uploads },
  { title: 'Posts', path: '/posts', component: Posts },
  { title: 'Prullenbak', path: '/archive', component: Uploads },
  { title: 'Posts Conversie', path: '/postsqueue', component: PostsConversion },
  { title: 'Conversie', path: '/queue', component: Conversion },
  { title: 'Gebruikers', path: '/users', component: Users },
  { title: 'Moderation', path: '/moderation', component: Moderation },
  { title: 'Moderation', path: '/moderation/edit/:id', component: DummyPage, subpage: true },
  { title: 'Moderation', path: '/moderation/edit/:id/:commentId', component: DummyPage, subpage: true },
  { title: 'Moderation', path: '/moderation/blacklist', component: BlackList, subpage: true },
  { title: 'Moderation', path: '/moderation/phrases', component: CensoredPhrases, subpage: true },
  { title: 'Conversie (geannuleerd) Archief', path: '/postqueuearchive', 
    component: PostsConversionArchive, hidden: true },
];

const routes = links
  .map(({ path, component }) => (<Route exact path={path} component={component} key={path}/>))
  .concat(
    <Route exact key={'/'} path='/' render={() => <Redirect to={'/uploads'}/>}/>,
  );

export const LayoutMain = () => {
  const { role } = useSelector(selectAuthUser);
  const message = useSelector(selectMessage);
  const dispatch = useDispatch();

  const closeMessage = useCallback(() => dispatch(setMessageAction(null)), []);

  return (
    <React.Fragment>
      {!!message && <PopupCard show={true} title={message.title} hideCloseButton={message.hideCloseButton}
        onClose={message.options ? null : closeMessage}
        footer={message.options}>
        <Notification color={message.color ? message.color : 'warning'}>
          {message.text}
        </Notification>
      </PopupCard>}
      <main>
        <Switch>
          <Route exact path="/edit/:uploadId" component={EditItemPage}/>
          <Route exact path="/import/:postId" component={ImportItemPage}/>
          <Route path="*">
            <NavBar links={
              links.filter(
                ({ hidden: hidden, component: { maxRoleLevel } = {}, subpage  }) => {
                  return !hidden && (maxRoleLevel === undefined || checkRolePermit(role, maxRoleLevel)) && !subpage;
                },
              )
            }/>
            <Switch>
              {routes}
            </Switch>
          </Route>
        </Switch>
      </main>
    </React.Fragment>
  );
};

