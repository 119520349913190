import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { importPost } from 'services/dmp';
import { SpinnerIcon } from 'icons';
import { useShowErrorMessage, useUnmounted } from '../common/hooks';
import 'twin.macro';


export const ImportItemPage = () => {
  const history = useHistory();
  const unmounted = useUnmounted();
  const showErrorMessage = useShowErrorMessage();
  const { postId } = useParams();
  const [importing, setImporting] = useState(true);

  useEffect(() => {

    importPost(postId)
      .then(r => history.replace(`/post/${r.data.id}`))
      .catch(showErrorMessage)
      .finally(() => {
        if(!unmounted.current) {
          setImporting(false);
        }
      });
  }, [postId]);
  return <div tw="w-full h-full flex justify-center">
    {importing && <SpinnerIcon tw="text-7xl"/>}
    {!importing && <a href='/'>close</a>}
  </div>;
};