import React, { useEffect, useState } from 'react';
import 'twin.macro';
import { Button } from '../common/AdminButton';
import { useHistory } from 'react-router-dom';
import {
  PageContainer,
  ListContainer,
  ListRow, 
  ListColumnFixed,
  Notification,
  Input,
  FormField,
} from '../common';
import {
  FetchBlacklist, 
  Delete_domain, 
  Add_domain,
} from 'services/user';

function BlackList() {
  const [items, setItems] = useState([]);
  const [inputDomain, setInputDomain] = useState('');
  const [notification, setNotification] = useState(null);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    await Add_domain(inputDomain).then(async () => {
      await loadData().then(() => {
        setInputDomain('');
        setNotification({ color: 'success', message: 'item toegevoegd aan blacklist' });
      });
    });
  };

  const deleteDomainHandle = async (domain) => {
    await Delete_domain(domain).then(async () => {
      await loadData().then(() => {
        setNotification({ color: 'danger', message: 'item verwijderd van blacklist' });
      });
    });
  };

  const loadData = async () =>  { 
    await FetchBlacklist()
      .then(response => {
        setItems(response.data);
      })
      .catch(error => setNotification({ color: 'danger', message: error.response.data }));
  };

  useEffect(() => {
    if (items.length) { return; } 
    loadData();
  }, [items]);

  useEffect(() => {
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  }, [notification]);

  return (
    <div className="Moderation">
      {notification !== null && 
        <div tw='fixed right-10 top-36 z-50'>
          <Notification 
            color={notification.color}
            onRemove={() => setNotification(null)}   
          >
            <p>{notification.message}</p>
          </Notification>
        </div>
      }
      <ListContainer>
        <ListColumnFixed>
          <form 
            onSubmit={e => handleSubmitForm(e)} 
            tw='flex gap-2 items-end'
          >
            <FormField
              label='domain'
              name='domain'
              placeholder='domain'
              autoComplete="off"
              value={inputDomain}
              onChange={e => setInputDomain(e.target.value)}
              FieldType={Input}
            />            
            <Button type='submit'>Add</Button>
          </form>
        </ListColumnFixed>
        <ListColumnFixed text='Name' tw='font-bold'/>
        {items.map(item => 
          <ListRow key={item.id}>
            <ListColumnFixed text={<pre tw='p-2'>{item.domain}</pre>}/>
            <ListColumnFixed>
              <Button label='Delete' onClick={() => deleteDomainHandle(item.domain)} />
            </ListColumnFixed>
          </ListRow>,
        )}
      </ListContainer>
    </div>
  );
}

export const BlacklistModTab = () => {
  return (
    <PageContainer toolbar={<ToolBar/>}>
      <BlackList />
    </PageContainer>
  );
};

const ToolBar = () => {
  const history = useHistory();
  return <Button label="Go Back" onClick={() => history.goBack()} />;
};
