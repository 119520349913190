// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thumb-container--XMYLuX {
  display: inline-block;
  position: relative
}

.thumb-container--XMYLuX button {
    position: absolute;
    top: -8px;
    right: 8px;
    background: none;
    border: none;
    padding: 0 !important
  }

.thumb-container--XMYLuX button .fa-trash-alt {
      color: white;
    }

.sortable-wrapper--ASwSTs {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 100%;
  overflow-x: scroll;
  padding-bottom: 10px
}

.sortable-wrapper--ASwSTs::-webkit-scrollbar {
    width: 10px;
  }

.sortable-wrapper--ASwSTs::-webkit-scrollbar-track {
    background: rgba(229, 231, 235, var(--tw-bg-opacity));
    cursor: pointer;
  }

.sortable-wrapper--ASwSTs::-webkit-scrollbar-thumb {
    background: rgba(156, 163, 175, var(--tw-bg-opacity));
  }

.sortable-wrapper--ASwSTs::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.sortable-wrapper--ASwSTs > div:not(:last-child) {
    margin-right: 0.5rem;
  }

.input-add-file--qzzkaa {
  visibility: hidden;
  position: absolute;
}

.sortable-collection--qqWdzp {
  display: inline-block;
  white-space: nowrap;
}

.sortable-collection-helper--JB8sMg {
  z-index: 100;
}

.left--VeYylA {
  flex: 0 0 8rem;
}

@media only screen and (min-width: 768px) {
  .left--VeYylA {
    flex: 0 0 6rem;
  }
}

.selected--TyjVKY {
  border: green 6px solid;
}

.remove-button--uJTZLJ svg {
    filter: drop-shadow(0px 0px 4px black);
  }
`, "",{"version":3,"sources":["webpack://./src/components/edit/ItemsBar.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB;AAaF;;AAXE;IACE,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,YAAY;IACZ;EAIF;;AAHE;MACE,YAAY;IACd;;AAIJ;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB;AAqBF;;AApBE;IACE,WAAW;EACb;;AAEA;IACE,qDAAqD;IACrD,eAAe;EACjB;;AAEA;IACE,qDAAqD;EACvD;;AAEA;IACE,gBAAgB;EAClB;;AAEA;IACE,oBAAoB;EACtB;;AAGF;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AACA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE,uBAAuB;AACzB;;AAGE;IACE,sCAAsC;EACxC","sourcesContent":[".thumb-container {\n  display: inline-block;\n  position: relative;\n\n  & button {\n    position: absolute;\n    top: -8px;\n    right: 8px;\n    background: none;\n    border: none;\n    padding: 0 !important;\n    & :global .fa-trash-alt {\n      color: white;\n    }\n  }\n}\n\n.sortable-wrapper {\n  display: flex;\n  align-items: center;\n  position: relative;\n  max-width: 100%;\n  overflow-x: scroll;\n  padding-bottom: 10px;\n  &::-webkit-scrollbar {\n    width: 10px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: rgba(229, 231, 235, var(--tw-bg-opacity));\n    cursor: pointer;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: rgba(156, 163, 175, var(--tw-bg-opacity));\n  }\n\n  &::-webkit-scrollbar-thumb:hover {\n    background: #555;\n  }\n\n  > div:not(:last-child) {\n    margin-right: 0.5rem;\n  }\n}\n\n.input-add-file {\n  visibility: hidden;\n  position: absolute;\n}\n\n.sortable-collection {\n  display: inline-block;\n  white-space: nowrap;\n}\n\n.sortable-collection-helper {\n  z-index: 100;\n}\n\n.left {\n  flex: 0 0 8rem;\n}\n@media only screen and (min-width: 768px) {\n  .left {\n    flex: 0 0 6rem;\n  }\n}\n\n.selected {\n  border: green 6px solid;\n}\n\n.remove-button {\n  svg {\n    filter: drop-shadow(0px 0px 4px black);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumb-container": `thumb-container--XMYLuX`,
	"thumbContainer": `thumb-container--XMYLuX`,
	"sortable-wrapper": `sortable-wrapper--ASwSTs`,
	"sortableWrapper": `sortable-wrapper--ASwSTs`,
	"input-add-file": `input-add-file--qzzkaa`,
	"inputAddFile": `input-add-file--qzzkaa`,
	"sortable-collection": `sortable-collection--qqWdzp`,
	"sortableCollection": `sortable-collection--qqWdzp`,
	"sortable-collection-helper": `sortable-collection-helper--JB8sMg`,
	"sortableCollectionHelper": `sortable-collection-helper--JB8sMg`,
	"left": `left--VeYylA`,
	"selected": `selected--TyjVKY`,
	"remove-button": `remove-button--uJTZLJ`,
	"removeButton": `remove-button--uJTZLJ`
};
export default ___CSS_LOADER_EXPORT___;
