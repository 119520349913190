import React, { useCallback, useEffect, useState } from 'react';
import 'twin.macro';
import { addEmbed, removeEmbed } from 'services/uploads';
import { SelectControl, Input, Button } from '../../common';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import InstagramEmbed from 'react-instagram-embed';
import EmbedContainer from 'react-oembed-container/dist';
import axios from 'axios';
import { useShowErrorMessage, useUnmounted } from '../../common/hooks';
import { DeleteIcon, SaveIcon, SpinnerIcon } from 'icons';
import { showFlashNotificationOp } from '../../store';
import { useDispatch } from 'react-redux';

const parseEmbedUrl = embedUrl => {
  if (typeof embedUrl !== 'string') {
    return ['', ''];
  }
  const parts = embedUrl.split(':');
  if (parts.length !== 2) {
    return ['', '', ''];
  }
  const scheme = parts[0];
  const idParts = parts[1].split('/');
  if (idParts.length === 1) {
    return { scheme, username: '', id: idParts[0] };
  }
  const [username, id] = idParts;
  return { scheme, username, id };
};

const embedIdFromUrl = embedUrl => parseEmbedUrl(embedUrl).id;
const embedSchemeFromUrl = embedUrl => parseEmbedUrl(embedUrl).scheme;
const embedUsernameFromUrl = embedUrl => parseEmbedUrl(embedUrl).username;

const embedUrlFromId = (scheme, embedUsername, embedId) =>
  `${scheme}:`.trim()+`${embedUsername || ''}${embedUsername ? '/' : ''}${embedId}`.trim();

export const Embed = ({ upload, mediaItem, change, ...restProps }) => {
  
  const { source } = restProps;
  const unmounted = useUnmounted();
  const [itemIsEmbed, setItemIsEmbed] = useState(mediaItem && mediaItem.embed_url);
  const [embedId, setEmbedId] = useState('');
  const [embedScheme, setEmbedScheme] = useState('youtube');
  const [embedUsername, setEmbedUsername] = useState('');
  const [unsaved, setUnsaved] = useState(false);
  const showErrorMessage = useShowErrorMessage();
  const dispatch = useDispatch();

  const save = useCallback(() => {
    const embed_url = embedUrlFromId(embedScheme, embedUsername, embedId);
    addEmbed(upload.id, mediaItem.id, embed_url, source)
      .then(() => {
        if(upload.items) {
          const index = upload.items.findIndex(i => i.id ===mediaItem.id);
          if(index > -1) {
            upload.items[index].embed_url = embed_url;

            // trigger preview refresh
            change({ items: [...(upload.items || [])] });
          }
        }
        if(!unmounted.current) {
          setUnsaved(false);
          setItemIsEmbed(true);
        }
        dispatch(showFlashNotificationOp('saveSuccess'));
      })
      .catch(showErrorMessage);
  }, [JSON.stringify({ items: upload.items }), embedScheme, embedUsername, embedId, mediaItem]);

  const remove = useCallback(() => {
    removeEmbed(upload.id, mediaItem.id, source)
      .then(() => {
        if(upload.items) {
          const index = upload.items.findIndex(i => i.id === mediaItem.id);
          if(index > -1) {
            delete upload.items[index].embed_url;

            // trigger preview refresh
            change({ items: [...(upload.items || [])] });
          }
        }
        if(!unmounted.current) {
          setEmbedId('');
          setEmbedUsername('');
          setItemIsEmbed(false);
        }
        dispatch(showFlashNotificationOp('saveSuccess'));
      })
      .catch(showErrorMessage);
  }, [JSON.stringify({ items: upload.items }), embedId, mediaItem]);

  useEffect(() => {
    setItemIsEmbed(mediaItem && mediaItem.embed_url);
    setEmbedId(mediaItem && embedIdFromUrl(mediaItem.embed_url));
    setEmbedUsername(mediaItem && embedUsernameFromUrl(mediaItem.embed_url));
    setEmbedScheme(mediaItem && embedSchemeFromUrl(mediaItem.embed_url) || 'youtube');
  }, [mediaItem]);

  const onChangeEmbedId = useCallback(e => {
    setUnsaved(true);
    setEmbedId(e.target.value);
  }, []);

  const onChangeEmbedUserName = useCallback(e => {
    setUnsaved(true);
    setEmbedUsername(e.target.value);
  }, []);

  return <>
    <div tw="mb-2">
      <SelectControl
        value={embedScheme}
        options={[
          { label: 'youtube', value: 'youtube' },
          { label: 'twitter', value: 'twitter' },
          { label: 'facebook', value: 'facebook' },
          { label: 'instagram', value: 'instagram' },
          { label: 'tiktok', value: 'tiktok' },
          { label: 'kijk.nl', value: 'kijk.nl' },
          { label: 'twitch', value: 'twitch' },
        ]}
        onChange={s => {
          setEmbedScheme(s);
          setEmbedUsername('');
          setEmbedId('');
        }}
      />
    </div>
    {embedScheme === 'twitch' && (
      <div style={{ paddingBottom: '5px' }} >
        Twitch embed ondersteund de volgende waardes: '<strong><mark>dumpert</mark>'</strong> 
        &nbsp; of een [<strong><mark>videoId</mark></strong>].<br/>
        <div style={{ padding: '5px 0px' }}>
          <strong><mark>dumpert</mark></strong>: Live feed van Twitch Dumpert + chat in plaats van comments.
        </div>
        [<strong><mark>videoId</mark></strong>]: Embed van video, gebruik alleen het&nbsp;
        <strong><mark>id</mark></strong> gedeelte na 'videos/' van de URL. 
        (https://www.twitch.tv/videos/<strong><mark>1209916494</mark></strong>)
      </div>
    )}
    <div tw="flex">
      {(embedScheme === 'tiktok' || embedScheme === 'facebook') &&
    <div tw="flex-1 mr-2">
      <Input 
        value={embedUsername} 
        placeholder={'username'} 
        onChange={onChangeEmbedUserName}
      />
    </div>
      }
      <div tw="flex-1 mr-2"> 
        <Input 
          value={embedId} 
          placeholder={'id'} 
          onChange={onChangeEmbedId}
        /> 
      </div>
      <div tw="flex-initial mr-1">
        <Button 
          Icon={SaveIcon} 
          disabled={!(embedId && unsaved)} 
          onClick={save} 
          tw="mr-1"
        />
      </div>
      <div tw="flex-initial">
        <Button 
          Icon={DeleteIcon} 
          disabled={!itemIsEmbed}
          onClick={remove} 
          tw="mr-1"
        />
      </div>
    </div>
  </>;
};

export const EmbedPreview = ({ mediaItem, EmbedRatioContainer }) => {
  const scheme = embedSchemeFromUrl(mediaItem.embed_url);
  const embedId = embedIdFromUrl(mediaItem.embed_url);
  const username = embedUsernameFromUrl(mediaItem.embed_url);
  const twitchType = embedId === 'dumpert' ? `channel=${embedId}` : `video=v${embedId}`;

  const embedType = (scheme) => { 
    switch (scheme) {
    case 'twitch':
      return <iframe 
        src={`https://player.twitch.tv/?${twitchType}&parent=${window.location.hostname}`} 
        frameBorder="0" 
        allowFullScreen={true} 
        scrolling="no" 
        height="100%" 
        width="100%" 
      />;      
    case 'youtube':
      return <>
        <iframe
          key={mediaItem.id}
          style={{ width: '100%', height: '100%' }}
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        />
      </>;
    case 'twitter':
      return <TwitterTweetEmbed
        tweetId={embedId}
        options={{ align: 'center' }}
      />;
    case 'facebook':
      return <>
        <iframe 
          src={'https://www.facebook.com/plugins/video.php'
        + '?href=https%3A%2F%2Fwww.facebook.com%2F'
        + username
        + '%2Fvideos%2F'
        + embedId
        + '%2F&show_text=0&width=746&height=440'
          }        
          width="746" 
          height="440" 
          style={{ border: 'none', overflow: 'hidden' }} 
          scrolling="no" 
          frameBorder="0" 
          allowFullScreen={true}
        />
      </>;
    case 'instagram':
      return <InstagramEmbed
        url={`https://instagr.am/p/${embedId}/`}
        maxWidth={320}
        hideCaption
        style={{ marginTop: '10px' }}
        clientAccessToken='227370684433724|d64249ee8dcd60ac7ef3e285ad57ccc9'
      />;
    case 'tiktok':
      return <TikTok username={username} id={embedId}/>;
    case 'kijk.nl':
      return <iframe 
        src={`https://embed.kijk.nl/?token=mediahuis_dumpert_web&video=${embedId}`}
        frameBorder="0" 
        allow="autoplay; encrypted-media; fullscreen" 
        scrolling="no" 
        allowFullScreen={true}
        style={{ width: '100%', height: '100%' }}
      />;
    }
  };

  return(
    <EmbedRatioContainer twitterEmbed={scheme === 'twitter'}>
      {embedType(scheme)}
    </EmbedRatioContainer>
  );
};

const TikTok = ({ username, id }) => {
  const unmounted = useUnmounted();
  const [html, setHtml] = useState('');
  const [pending, setPending] = useState(false);

  useEffect(() => {
    if (username && id) {
      setPending(true);
      axios.get(
        `https://www.tiktok.com/oembed?url=https://www.tiktok.com/@${username}/video/${id}`,
      )
        .then(({ data }) => {
          if (!unmounted.current) {
            setHtml(data.html);
          }
        })
        .catch(e => {
          if (!unmounted.current) {
            setHtml(e);
          }
        })
        .finally(() => {
          if (!unmounted.current) {
            setPending(false);
          }
        });
    }
  }, [username, id]);

  return <>
    {pending && <SpinnerIcon tw="h-8 w-8" />}
    {!pending && html && <EmbedContainer markup={html}>
      <div dangerouslySetInnerHTML={{ __html: html }}/>
    </EmbedContainer>}
  </>
  ;
};
