import { useCallback, useState } from 'react';
import { cancelPostJobs, cancelPostsJobs, importPost, redoPostJobs, redoPostsJobs,
  deleteConversionArchive, deleteConversionArchives, exportConversionArchive } from 'services/dmp';
import { useHistory, useLocation } from 'react-router-dom';
import { useShowErrorMessage } from '../common/hooks';

export const useEditItem = posts => {
  const location = useLocation();
  const history = useHistory();
  const [importing, setImporting] = useState(false);
  const hasUploadId = posts && posts.length > 0 && !!posts[0].upload_id;
  const showErrorMessage = useShowErrorMessage();

  const edit = useCallback(() => {
    const goToEdit = id => history.push({
      pathname: `/edit/${id}`,
      state: { next: location.pathname + (location.search || '')},
    });
    if(hasUploadId) {
      sessionStorage.setItem('scrollPosition', window.scrollY);
      goToEdit(posts[0].upload_id);
    } else {
      setImporting(true);
      return importPost(posts[0].id)
        .then(e => {
          goToEdit(e.data.id);
        })
        .catch(showErrorMessage)
        .finally(() => setImporting(false));
    }
  }, [posts, hasUploadId]);
  return [importing, edit];
};

export const useCancelItem = posts => {
  
  // if (!posts || !posts.length) {
  //   return [() => function() {}, () => function() {}];
  // }
  const location = useLocation();
  const history = useHistory();
  
  // const [importing, setImporting] = useState(false);
  const hasUploadId = posts && posts !== undefined && posts[0] && !!posts[0].upload_id;
  const showErrorMessage = useShowErrorMessage();
  const goToEdit = id => history.push({
    pathname: `/edit/${id}`,
    state: { next: location.pathname + (location.search || '')},
  });

  const cancel = useCallback(() => {
    const cancelJobs = ids => {
      if (ids.length > 1) {
        return cancelPostsJobs(ids)
          .catch(showErrorMessage);
      }

      cancelPostJobs(ids);
    };

    if(hasUploadId) {
      const ids = posts.map(post => post.upload_id);
      cancelJobs(ids);
    } else {
      return importPost(posts[0].id)
        .then(e => {
          goToEdit(e.data.id);
        })
        .catch(showErrorMessage);
    }
  }, [posts, hasUploadId]);
  return [cancel];
};

export const useExportArchive = ids => {
  const showErrorMessage = useShowErrorMessage();

  const exportFn = useCallback(() => {
    const exportConversion = ids => {
      if (ids.length > 0) {
        return exportConversionArchive(null)
          .catch(showErrorMessage);
      }

      exportConversionArchive(ids);
    };

    exportConversion(ids);
  }, [ids]);

  return [exportFn];
};

export const useRedoItem = ids => {  
  const redo = useCallback(() => {
    const redoJobs = ids => {
      if (ids.length > 1) {
        redoPostsJobs(ids);
        return;
      }

      redoPostJobs(ids);
    };
    
    redoJobs(ids);
  }, [ids]);
  return [redo];
};


export const useDeleteConversionArchive = ({ids, callback}) => {
  const showErrorMessage = useShowErrorMessage();

  const remove = useCallback(() => {
    const removeConversionArchive = ids => {
      if (ids.length > 1) {
        deleteConversionArchives(ids).catch(showErrorMessage).then(callback && setTimeout(() => {
          callback();
        }, 1000));
        return;
      }

      deleteConversionArchive(ids).catch(showErrorMessage).then(callback && setTimeout(() => {
        callback();
      }, 1000));
    };

    removeConversionArchive(ids);
  }, [ids]);
  return [remove];
};
