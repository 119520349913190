import React, { useCallback } from 'react';
import { Modal, Button } from 'react-bulma-components';
import styles from './PopupButton.css';


export const PopupCard = ({ children, title, footer, show, onClose, className, hideCloseButton }) => (
  <Modal show={show} onClose={onClose} closeOnBlur={true} closeOnEsc={true}
    showClose={!title && !hideCloseButton} className={className}>
    <Modal.Content className={ styles.modalContent }>
      <Modal.Card className={ styles.modalCard }>
        {!!title && <Modal.Card.Header onClose={onClose}>
          <Modal.Card.Title style={{ textAlign: 'center' }}>{title}</Modal.Card.Title>
        </Modal.Card.Header>}
        <Modal.Card.Body>
          {children}
        </Modal.Card.Body>
        {!!footer && <Modal.Card.Footer renderAs={Button.Group} align="center" hasAddons>
          {footer}
        </Modal.Card.Footer>}
      </Modal.Card>
    </Modal.Content>
  </Modal>
);


export const PopupButton = ({
  children,
  button,
  title,
  content,
  footer,
  popupActive,
  setPopupActive,
  className,
}) => {

  const open = useCallback(() => {
    setPopupActive(true);
  }, [setPopupActive]);
  const close = useCallback(() => {
    setPopupActive(false);
  }, [setPopupActive]);

  return (
    <>
      {!!button && <Button { ...button } onClick={open}>
        {children}
      </Button>}
      {!button && <div onClick={open}>
        {children}
      </div>}
      <PopupCard title={title} footer={footer} show={popupActive} onClose={close} className={className}>
        {content}
      </PopupCard>
    </>
  );

};
