import React from 'react';

export const BxUserCircleIcon = props =>
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1em"
    width="1em"
    {...props}
  >
    <path
      fill="none"
      d="M12 8c-1.178 0-2 .822-2 2s.822 2 2 2 2-.822 2-2-.822-2-2-2z"
    />
    <path
      fill="none"
      d="M12 4c-4.337 0-8 3.663-8 8 0 2.176.923 4.182 2.39 5.641A5.007 5.007 0 0111 14.573h2a5.007 5.007 0 014.61 3.068C19.077 16.182 20 14.176 20 12c0-4.337-3.663-8-8-8zm0 10c-2.28 0-4-1.72-4-4s1.72-4 4-4 4 1.72 4 4-1.72 4-4 4z"
    />
    <path
      fill="none"
      d="M13 16.572h-2a3.003 3.003 0 00-2.926 2.354C9.242 19.604 10.584 20 12 20s2.758-.396 3.926-1.073A3.003 3.003 0 0013 16.572z"
    />
    <path
      d="M12 2C6.579 2 2 6.579 2 12c0 3.189 1.592 6.078 4 7.924V20h.102C7.77 21.245 9.813 22 12 22s4.23-.755 5.898-2H18v-.076c2.408-1.846 4-4.734 4-7.924 0-5.421-4.579-10-10-10zM8.074 18.927A3.002 3.002 0 0111 16.573h2a3.003 3.003 0 012.926 2.354C14.758 19.604 13.416 20 12 20s-2.758-.396-3.926-1.073zm9.536-1.286A5.007 5.007 0 0013 14.573h-2a5.007 5.007 0 00-4.61 3.068C4.923 16.182 4 14.176 4 12c0-4.337 3.663-8 8-8s8 3.663 8 8c0 2.176-.923 4.182-2.39 5.641z"/>
    <path
      d="M12 6c-2.28 0-4 1.72-4 4s1.72 4 4 4 4-1.72 4-4-1.72-4-4-4zm0 6c-1.178 0-2-.822-2-2s.822-2 2-2 2 .822 2 2-.822 2-2 2z"/>
  </svg>;
