import React, { useCallback, useState } from 'react';
import 'twin.macro';
import { UploadPreview, uploadToPost } from './UploadPreview';
import { useHistory, useParams } from 'react-router-dom';
import { useConfirmDialog, useShowErrorMessage, useUnmounted, useURLParams } from '../common/hooks';
import { archivePost, createPost, updatePost } from 'services/dmp';
import { EditButtons, PrevNextButtons } from './';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageAction, showFlashNotificationOp } from '../store';
import { selectCurrentJobsByUpload } from '../current/ducks';
import { archive, fetch } from 'services/uploads';


export const EditItemPage = () => {
  const history = useHistory();
  const unmounted = useUnmounted();
  const { uploadId } = useParams();
  const source = useURLParams().get('source') || 'accepted';
  const [data, setData] = useState(null);
  const [editState, setEditState] = useState('unsaved');
  const disabled = ['saving', 'deleting', 'publishing'].includes(editState);
  const showErrorMessage = useShowErrorMessage();
  const dispatch = useDispatch();
  const { jobs = [] } = useSelector(selectCurrentJobsByUpload)[uploadId] || {};
  const itemIsBeingConverted = jobs.length > 0;


  const errorHandler = e => {
    if(itemIsBeingConverted) {
      dispatch(setMessageAction({
        title: 'Error',
        color: 'danger',
        text: 'Eventjes geduld nog. Dit item is nog niet geconverteerd',
      }));
    } else {
      showErrorMessage(e);
    }
    if (!unmounted.current) {
      setEditState('unsaved');
    }
  };
  const saveHandler = () => {
    if (!unmounted.current) {
      setEditState('saved');
    }
    dispatch(showFlashNotificationOp('saveSuccess'));
  };

  const exitPage = useCallback(() => {
    const { state } = history.location;

    history.push(state && state.next ? state.next : '/');
  }, [JSON.stringify(data), history]);

  const createPostFromUpload = useCallback(() => {
    setEditState('saving');
    const { post, publishWhenDone } = uploadToPost(data);
    return createPost(post, publishWhenDone)
      .then(exitPage)
      .catch(errorHandler);
  }, [data, itemIsBeingConverted]);

  const save = useCallback(() => {
    setEditState('saving');
    const { post } = uploadToPost(data);
    if (post.postStatus === 'publish' && (!post.date || post.date.getTime() > Date.now())) {
      post.date = new Date(Date.now());
    }
    return updatePost(post)
      .then(saveHandler)
      .catch(errorHandler);
  }, [data, itemIsBeingConverted]);

  const publish = useCallback(() => {
    setEditState('publishing');
    const { post } = uploadToPost(data);
    post.postStatus = 'publish';
    post.date = new Date(Date.now());
    return (source === 'incoming' ? createPost(post, true) : updatePost(post))
      .then(exitPage)
      .catch(errorHandler);
  }, [data, itemIsBeingConverted, source]);

  const remove = useConfirmDialog(
    useCallback(note => {
      setEditState('deleting');
      return (source === 'incoming' ? archive(uploadId, note) : archivePost({ upload_id: uploadId }, note))
        .then(exitPage)
        .catch(errorHandler);
    }, [uploadId, exitPage]),
  );

  const dataChange = useCallback(d => {
    if (!unmounted.current) {
      if (data) {
        setEditState('unsaved');
      }
      setData(d);
    }
  }, [data]);

  const dataErrorHandler = useCallback(e => {
    if (source === 'incoming' && e.response && e.response.status === 404) {

      // link can be outdated if data has been moved from incoming to accepted
      // redirect to accepted source by removing query string parameters.
      history.replace(history.location.pathname);
    }
  }, [source]);

  const prev = useCallback(() => {
    if (data && data.previous) {
      fetch(data.previous, source).then((pervUpload) => {
        let sn = history.location.pathname + history.location.search + history.location.hash;

        if (pervUpload && pervUpload.previous) {
          sn = `/edit/${pervUpload.previous}?source=${source}`;
        }
      
        history.push({
          pathname: `/edit/${data.previous}`,
          search: `?source=${source}`,
          state: { next: sn },
        });
      });
    }
  }, [data && data.previous, history, source]);

  const next = useCallback(() => {
    if (data && data.next) {
      fetch(data.next, source).then((nextUpload) => {
        let sn = history.location.pathname + history.location.search + history.location.hash;

        if (nextUpload && nextUpload.previous) {
          sn = `/edit/${nextUpload.previous}?source=${source}`;
        }

        history.push({
          pathname: `/edit/${data.next}`,
          search: `?source=${source}`,
          state: { next: sn  },
        });
      });
    }
  }, [data && data.next, history, source]);

  return <>
    <EditButtons
      publish={publish}
      data={data}
      source={source}
      editState={editState}
      save={save}
      remove={remove}
      exitPage={exitPage}
      createPostFromUpload={createPostFromUpload}
      disabled={disabled}
    />

    {(source === 'incoming') && <>
      <PrevNextButtons upload={data} prev={prev} next={next} />
    </>}

    <div tw="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
      <UploadPreview uploadId={uploadId} source={source} onDataChange={dataChange}
        enabled={!disabled} onDataError={dataErrorHandler}/>
    </div>
  </>;
};
