import React from 'react';

export const DumpertSingleDIcon = props =>
  <svg
    viewBox="0 0 72.4 72.4" 
    fill="#66cc22"
    width="72.4px" 
    height="72.4px"
    {...props}
  >
    <path
      d="M39.6,0H0v70.8h35.3C60,70.8,71.5,57,71.5,35.3C71.5,17.4,63.2,0,39.6,0z M20.6,54.9V16.5L54,35.7 L20.6,54.9z" />
  </svg>;