// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select--ymjjSb {
  height: 38px;
}
.select--ymjjSb::after {
  content: "\\25BC";
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 5px;
  padding: 0 1em;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, 1);
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  transition: 0.25s all ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/SelectControl.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;EACpB,oBAAoB;EACpB,6BAAkD;EAAlD,kDAAkD;EAGlD,0BAA0B;AAC5B","sourcesContent":[".select {\n  height: 38px;\n}\n.select::after {\n  content: \"\\25BC\";\n  position: absolute;\n  top: 2px;\n  right: 2px;\n  bottom: 2px;\n  border-radius: 5px;\n  padding: 0 1em;\n  background: white;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  pointer-events: none;\n  --tw-text-opacity: 1;\n  color: rgba(156, 163, 175, var(--tw-text-opacity));\n  -webkit-transition: 0.25s all ease;\n  -o-transition: 0.25s all ease;\n  transition: 0.25s all ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `select--ymjjSb`
};
export default ___CSS_LOADER_EXPORT___;
