// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox--DXnhiI {
    width: 1rem;
    height: 1rem;
    margin: 0.2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/selection/ItemsCheckBox.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".checkbox {\n    width: 1rem;\n    height: 1rem;\n    margin: 0.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `checkbox--DXnhiI`
};
export default ___CSS_LOADER_EXPORT___;
