import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';
import { FormField, InputButton, Button } from '../common';
import { 
  BxChevronLeftIcon,
  BxChevronsLeftIcon,
  BxChevronRightIcon,
} from 'icons';

export const PageButtons = ({ currentPage, goToPage, maxPages }) => {

  const [activePage, setActivePage] = useState(currentPage);
  const [pagination, setPagination] = useState([]);

  useEffect(() => {

    const pagesNumbers = [];
    const pageParam = parseInt(currentPage);

    setActivePage(pageParam);

    const initialNum = activePage && activePage > maxPages - 1 ? activePage - Math.round(maxPages / 2) : 0;
    const maxNumPages = activePage && activePage > maxPages - 1 ? activePage + Math.round(maxPages / 2) : maxPages;

    for(let i = initialNum; i < maxNumPages; i++) {

      pagesNumbers.push({ 
        key: `page${i}`, 
        label: i, 
        active: (i === pageParam || (i === 0 && isNaN(pageParam))), 
      });
    }
    setPagination(pagesNumbers);
    
  }, [currentPage, goToPage]);

  return(<>
    {pagination.map(page => 
      <Button 
        key={page.key}
        css={[
          tw`rounded-none border-r-0 focus:ring-0 focus:border-gray-300 `, 
          page.active && tw`bg-blue-100`,
        ]} 
        onClick={() => goToPage(page.label)}
      >
        {page.label}
      </Button>,
    )}
  </>);
};


export const Paginator = ({ startPage = 0, maxPages = 5, hidePageInput, onPageChange = () => {} }) => {
  
  const [page, setPage] = useState(parseInt(startPage));
  const prevEnabled = page !== 0;

  function goToPage(page) {
    setPage(page);
    onPageChange(page);
  }

  return <div tw="flex flex-col md:flex-row justify-center">
    <div tw="flex order-2 md:order-1 justify-center px-1">
      <Button 
        label={<BxChevronsLeftIcon tw="h-4 w-4" />} 
        onClick={prevEnabled ? () => goToPage(0) : null} 
        css={[tw`rounded-r-none border-r-0 focus:ring-0 focus:border-gray-300`, !prevEnabled && tw`text-gray-300`]} 
      />
      <Button 
        label={<BxChevronLeftIcon tw="h-4 w-4" />}
        onClick={prevEnabled ? () => goToPage(page-1) : null} 
        css={[tw`rounded-none border-r-0 focus:ring-0 focus:border-gray-300`, !prevEnabled && tw`text-gray-300`]} 
      />
      <PageButtons 
        currentPage={startPage} 
        maxPages={maxPages} 
        goToPage={goToPage}
      />
      <Button label={<BxChevronRightIcon tw="h-4 w-4" />} tw="rounded-l-none" onClick={() => goToPage(page+1)}/>
    </div>
    {!hidePageInput &&
      <div tw="order-1 w-full mb-2 md:mb-0 md:order-2 md:px-2 md:w-40">
        <FormField
          name="pagenum"
          placeholder='Ga naar pagina'
          autoComplete="off"
          tw="ml-2"
          buttonLabel={<BxChevronRightIcon tw="h-4 w-4" />}
          onPush={goToPage}
          FieldType={InputButton}
        />
      </div>
    }
  </div>;
};
