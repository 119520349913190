import axios from 'axios';
import { authorizeRequest } from 'services/auth';

const commentApi = axios.create({
  baseURL: process.env.COMMENT_HOST,
  withCredentials: true,
});
commentApi.interceptors.request.use(authorizeRequest);

export function hide_all_comments(id, commentIds) {
  if (window.confirm('Are you sure you want to hide all those comments??')) {

    // TODO: Promise returned from put is ignored
    commentApi
      .put(`/moderate/hidecomments/${id}`, {
        comments: commentIds,
      })
      .catch((err) => {
        throw new Error(err);
      });
  }
}

export function wegjorissen_all_comments(id, commentIds) {
  if (
    window.confirm('Are you sure you want to wegjorissen all those comments??')
  ) {

    // TODO: Promise returned from put is ignored
    commentApi
      .put(`/moderate/disapprovecomments/${id}`, {
        comments: commentIds,
      })
      .catch((err) => {
        throw new Error(err);
      });
  }
}

export const getUserComments = (id, page = 0) =>
  commentApi.get(`/moderate/getcomments/${id}/${page}`);

// phrases

export const fetchCensoredPhrases = () => commentApi.get('/moderation/phrases');

export const addPhrase = (phrase, comment) =>
  commentApi.post('/moderation/phrases', {
    phrase: phrase,
    comment: comment,
  });

export const deletePhrase = (phrase) =>
  commentApi.delete(`/moderation/phrases/${phrase}`);

export const getItemsUnmoderatedComments = (ids) => {
  const numericIds = ids?.ids?.map((id) => Number(id));
  return commentApi.post('get-unmoderated-comments', { ids: numericIds });
};
export const getUnmoderatedCommentsRisk = (id) => {
  return commentApi.get(`get-unmoderated-comments/${id}`);
};
export const alterCommentsRisk = (id) => {
  return commentApi.put(`/alter-comments-end/${id}/12`);
};
export const getBlockedComments = (id) => {
  const itemId = id;
  const body = [Number(itemId)];
  return commentApi.post('/get-unmoderated-comments', { ids: body });
};
export const updateBlockedComments = (id) => {
  return commentApi.put(`/alter-comments-end/${id}/25`);
};
