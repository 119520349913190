import * as React from 'react';
import 'twin.macro';
import { ItemsCheckBox } from '../selection';
import { EditPostThumbLink } from './PostButtons';

import {
  ListRow,
  ListColumnGrow,
  ListItemMedia,
  ListItemInfo,
  ConvertListItemStatus,
  ListItemActions, ListColumnFixed,
} from '../common';
import { ConvertPostButtons } from './ConvertPostButtons';

export const ConvertPost = ({ item, group, isHistory = false }) => {
  const postButtonProps = { posts: [item], withCancel: !isHistory, withEdit: isHistory, withRemove: isHistory};

  return <ListRow>
    <ListColumnGrow>
      <EditPostThumbLink posts={[item]}>
        <div tw="mb-2 md:mb-0">
          <ListItemMedia still={item.thumbnail} />
        </div>
      </EditPostThumbLink>
      <ListItemInfo item={item} maxLen={45} />
    </ListColumnGrow>
    <ListColumnFixed>
      {!isHistory && <>
        <ConvertListItemStatus uploadProgress={item.progress ? item.progress : 0} isArchived={false}/>
      </>}
      <ListItemActions uploadItem={item}>
        <ConvertPostButtons {...postButtonProps}>
          {!isHistory && <>
            <ItemsCheckBox controlItems={[item]} group={group}/>
          </>}
        </ConvertPostButtons>
      </ListItemActions>
    </ListColumnFixed>
  </ListRow>;
};
