import { getIdTokenSync } from 'services/auth';

export const MEDIA_BASE_URL = `${process.env.MEDIA_HOST || ''}/media`;
export const UPLOAD_BASE_URL = `${process.env.PRIVATE_MEDIA_PROXY || ''}/uploads`;

export const dirName = (uri) => uri.substr(0, uri.lastIndexOf('/'));

export const baseName = (uri) => uri.substr(uri.lastIndexOf('/'), uri.length);

export const assetPath = function (item) {
  return item && item.directory ? `${MEDIA_BASE_URL}/${item.directory}` : 
    item && item.files ? `${UPLOAD_BASE_URL}/${dirName(item.files[0].path)}` : 
      item && item.path ? `${UPLOAD_BASE_URL}/${dirName(item.path)}` : '';
};

export const autoItem = upload => {
  const videoMediaItem = upload.items && upload.items.find(i => i.input && i.input.video);
  return videoMediaItem || upload.items && upload.items[0];
};

export const findEmbedItem = upload => {
  return upload.items && upload.items.find(i => i.embed_url);
};

export const getStillsCollection = (item) =>
  ['thumb.png', 'thumb-large.png', 'thumb-large.webp', 'poster.jpg', 'poster-medium.webp', 
    'poster-small.png', 'poster-small.jpg']
    .map(still => {
      const suffix = (item.stills_suffix || '').trim();
      const [base, extension] = still.split('.');
      return { key: still, val:
        `${MEDIA_BASE_URL}/${item.directory}/${base}${suffix}.${extension}?access_token=${getIdTokenSync()}`};
    });

export const thumbLarge = (item, getPoster = false) =>
  `${assetPath(item)}${getPoster ? '/poster' : '/thumb-large'}${item.stills_suffix || ''
  }${getPoster ? '.jpg' : '.png'}?access_token=${getIdTokenSync()}`;

export const formatProgress = function (progress) {
  const segs = String(Math.round(progress * 1000) / 10).split('.');
  return `${segs[0]}.${segs[1] || '0'}`;
};

export const secondsToTime = function (d) {
  const h = Math.floor(d / 3600);
  const m = Math.floor(d % 3600 / 60);
  const s = Math.floor((d % 3600) % 60);
  return ('00' + h).slice(-2) + ':' + ('00' + m).slice(-2) + ':' + ('00' + s).slice(-2);
};

export const trimText = function (text, limit) {
  if (!text) {
    return '';
  }

  if (text.length <= limit) {
    return text;
  }

  const tokens = text.split(new RegExp('(?=[ +/.-])', 'g'));
  let out = '';

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (out.length + token.length > limit) {
      break;
    }
    out += token;
  }

  return out + '…';
};

export const cleanTextContent = function (text) {
  if (!text) {
    return '';
  }
  
  const strippedContent = text
    .replace(/<[^>]*>?/gm, ' ')
    .replace(/  +/g, ' ');

  return strippedContent;
};

export const formatFileSize = function (bytes, si) {
  const factor = si ? 1000 : 1024;
  if (Math.abs(bytes) < factor) {
    return bytes + ' B';
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= factor;
    ++u;
  } while (Math.abs(bytes) >= factor && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};


export const formatDateRFC3339 = date => {
  const year = date.getUTCFullYear();
  const [mon, day, hh, mm, ss] = [
    date.getUTCMonth() + 1,
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  ].map(n => n < 10 ? `0${n}` : `${n}`);

  return `${year}-${mon}-${day}T${hh}:${mm}:${ss}Z`;
};

export const parseDateRFC3339 = dateString => new Date(dateString)
  .toLocaleDateString('nl-NL', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Amsterdam',
  });

export const parseDate = (dateString, stucture = {} ) => new Date(dateString)
  .toLocaleDateString('nl-NL', stucture);

// new object without specified keys
export const OmitKeys =
  (o, keys) => Object.entries(o).reduce((a, [k, v]) => keys.includes(k) ? a : { ...a, [k]: v }, {});

export const OmitValues =
  (o, values) => Object.entries(o).reduce((a, [k, v]) => values.includes(v) ? a : { ...a, [k]: v }, {});

export const itemMediaType = mediaItem => mediaItem && (mediaItem.mediaType
  || mediaItem.mime_type && mediaItem.mime_type.substring(0, 5));

export const navigate = (history, url, params, transformParams = true) => {
  let query = { ...params };
  if (transformParams) {
    query = OmitValues(query, ['all']);
  }
  
  const sn = '/'; //https://github.com/sindresorhus/query-string/issues/366#issuecomment-1776018452;
  const ss = new URLSearchParams(query).toString();

  history.push({
    pathname: url,
    search: `${ss}`,
    state: { next: sn  },
  });
};

export const copyTextToClipboard = (text, func) => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  func();
};

