import React from 'react';
import { Job } from './Job';
import { useSelector } from 'react-redux';
import { selectCurrentJobs } from '../ducks';

export const ActiveJobsList = ({ stateFilter = '*', sort }) => {
  
  const jobs = useSelector(selectCurrentJobs);

  const filteredJobs = stateFilter === '*' ? jobs : jobs.filter(j => stateFilter.includes(j.state));

  filteredJobs.sort((a, b) => sort === 'nieuwste' ?
    new Date(b.created) - new Date(a.created) : new Date(a.created) - new Date(b.created));

  return (
    <>
      {filteredJobs.map(job => <Job job={job} key={job.id}/> )}
    </>
  );
};