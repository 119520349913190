import React, { useCallback } from 'react';
import tw from 'twin.macro';
import { useDispatch } from 'react-redux';
import { postsArchiveOp } from './ducks';
import { selectionRemoveAction } from '../selection/ducks';
import { useConfirmDialog, useShowErrorMessage } from '../common/hooks';
import { ActionButton } from '../common';
import { useEditItem } from './hooks';
import { SpinnerIcon } from 'icons';

export const RemovePost = (props) => (
  <ActionButton label={'Prullenbak'} props={props} />
);
export const EditPost = (props) => {
  return(
    <ActionButton label={'Edit'} props={props}/>
  );
};

export const EditPostThumbLink = ({ posts, children }) => {
  const [importing, edit] = useEditItem(posts);
  return (
    <div css={[tw`cursor-pointer w-full md:w-auto`]} onClick={edit}>
      {!importing && children}
      {importing && (
        <div tw="w-40 flex justify-center">
          <SpinnerIcon tw="text-7xl" />
        </div>
      )}
    </div>
  );
};

export const PostButtons = ({ posts, children }) => {
  const dispatch = useDispatch();
  const [importing, edit] = useEditItem(posts);
  const showErrorMessage = useShowErrorMessage();

  const remove = useConfirmDialog(
    useCallback(
      (note) => {
        return dispatch(postsArchiveOp(posts, note))
          .then(() => dispatch(selectionRemoveAction(posts)))
          .catch(showErrorMessage);
      },
      [posts.map((x) => x.id).join()],
    ),
  );

  return (
    <div tw="flex flex-col items-start mb-2 sm:mb-0">
      {posts.length === 1 && (
        <>
          <EditPost
            onClick={edit}
            loading={importing}
            disabled={importing}
            tw='mb-4 text-blue-600'
          />
        </>
      )}
      {posts.length > 0 && (
        <>
          <RemovePost onClick={remove} tw='text-blue-600' />
        </>
      )}
      {children}
    </div>
  );
};
