import { ARCHIVE_FETCH, ARCHIVE_ITEM_HIDE } from './actions';

export const archiveInitialState = {
  items: [],
  criteria: {},
  status: 'initial',
};

export const archiveReducer = (state = archiveInitialState, { type, payload }) => {
  switch (type) {
  case ARCHIVE_FETCH:
    return { ...state, ...payload };
  case ARCHIVE_ITEM_HIDE:
    return { ...state, items: state.items.filter(item => item.id !== payload) };
  default:
    break;
  }
  return state;
};
