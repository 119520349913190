import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './components';

createRoot(document.getElementById('app'))
  .render(
    createElement(App),
  );
