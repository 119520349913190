import React from 'react';
import 'twin.macro';
import { BxsDownloadIcon } from 'icons';
import {
  signedPdfUrl,
  originalUrl,
  versionUrl,
  downloadAllUrl,
} from 'services/dmp';
import { Button } from '../../common';

const message = `<article class="message is-warning">
  <div class="message-header">
    <p></p>
  </div>
  <div class="message-body">
    File wordt voorbereid voor downloaden
  </div>
</article>`;

export const Download = ({ upload, mediaItem, ...restProps }) => {
  const download = (version) => {
    if (version !== 'original') {
      const div = document.createElement('div');
      div.style = 'position: fixed; top: 0; right: 0; z-index: 1000; margin: 1rem;';
      div.innerHTML = message.trim();
      document.body.appendChild(div);
      setTimeout(() => {
        document.body.removeChild(div);
      }, 5000);
    }

    if (version === 'all') {
      dowloadAll();
      return;
    }

    const url =
      version === 'original'
        ? originalUrl({ ...mediaItem, source: restProps.source })
        : versionUrl(upload.id, mediaItem.id, version);
    const link = document.createElement('a');
    const mt = mediaItem.media_type || mediaItem.mediaType;
    switch (mt) {
    case 'image':
      link.download = `${mediaItem.id}${(mt) === 'image/gif' ? '.gif' : '.jpg'}`;
      break;
    case 'video':
      link.download = `${mediaItem.id}-${version}.mp4`;
      break;
    case 'audio':
      link.download = `${mediaItem.id}-${version}.mp3`;
      break;
    default:
      link.download = `${mediaItem.id}`;
    }
    link.href = url;
    link.target = '_top';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const dowloadAll = () => {
    const link = document.createElement('a');
    link.download = `${upload.id}.zip`;
    link.href = downloadAllUrl(upload.id, upload.source);
    link.target = '_top';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Button onClick={() => download('original')}>
        <BxsDownloadIcon tw="mr-1 h-5 w-5 text-gray-400" /> Original
      </Button>

      {upload.source !== 'incoming' &&
        mediaItem.media_type === 'video' &&
        Object.entries(mediaItem.output.versions).map(([version, dim]) => (
          <Button
            key={`mediaitem-version-${version}`}
            onClick={() => download(version)}
          >
            <BxsDownloadIcon tw="mr-1 h-5 w-5 text-gray-400" />
            {dim.width}x{dim.height}
          </Button>
        ))}

      {upload.source !== 'incoming' && !!upload.pubDate && (
        <Button
          href={signedPdfUrl(upload.id)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <BxsDownloadIcon tw="mr-1 h-5 w-5 text-gray-400" />
          Voorwaarden
        </Button>
      )}

      <Button onClick={() => download('all')}>
        <BxsDownloadIcon tw="mr-1 h-5 w-5 text-gray-400" /> Download alles
      </Button>
    </>
  );
};
