import React, { useCallback, useState } from 'react';
import { assetPath, getStillsCollection, thumbLarge } from '../../utils';
import { renderToStaticMarkup } from 'react-dom/server';
import Image from 'react-bulma-components/cjs/components/image';
import { requestReplaceStills, uploadAsset } from 'services/uploads';
import { BusyIcon, Button, ImageView } from '../../common';
import { getUpload } from 'services/dmp';
import { getIdTokenSync } from 'services/auth';
import { useShowErrorMessage, useUnmounted } from '../../common/hooks';
import { FileUploadIcon, VideoImageIcon } from 'icons';
import { showFlashNotificationOp } from '../../store';
import { useDispatch } from 'react-redux';
import tw from 'twin.macro';

const openStillsInNewTab = mediaItem => {
  const stills = <>
    {getStillsCollection(mediaItem).map(s => <label key={s} 
      htmlFor={mediaItem.id}>{s.key}<Image src={s.val} key={s}/></label>)}
  </>;
  const newWindow = window.open();
  newWindow.document.write(renderToStaticMarkup(stills));
};

export const UploadStillsButton = ({ onSelectFiles, loading, ...p }) =>
  <Button tw="hover:cursor-pointer hover:bg-blue-200" Icon={FileUploadIcon}  {...p}>
    <input className="file-input" type="file" name="stills-upload" onChange={e => onSelectFiles(e.target.files || [])}
      disabled={loading}/>
    Upload Stills
  </Button>;

export const GenerateFromVideoButton = ({ loading, ...p }) =>
  <Button label="Generate from item" Icon={VideoImageIcon} tw="mr-1" {...p} disabled={loading}/>;

const grid = tw`absolute grid grid-cols-3 gap-0.5 p-0.5 bottom-16`;
const popup = tw`rounded shadow-lg bg-green-600 ring-1 ring-black ring-opacity-5 focus:outline-none`;

export const Stills = ({ upload, mediaItem, change }) => {
  const unmounted = useUnmounted();
  const [uploadingStills, setUploadingStills] = useState(false);
  const [showFrames, setShowFrames] = useState(false);
  const dispatch = useDispatch();

  const showErrorMessage = useShowErrorMessage();

  const uploadStills = useCallback(async files => {
    if (files.length < 1) {
      return;
    }
    setUploadingStills(true);
    try {
      await uploadAsset(upload.id, mediaItem.id, files[0]);
      await requestReplaceStills(upload.id, mediaItem.id, files[0].name);
      dispatch(showFlashNotificationOp('saveSuccess'));
    } catch (e) {
      showErrorMessage(e);
    }
    try {
      const uploadUpdate = await getUpload(upload.id, upload.source);
      change({ items: uploadUpdate.data.items });
    } catch (e) {
      showErrorMessage(e);
    }

    if (!unmounted.current) {
      setUploadingStills(false);
    }
  }, [upload.id, mediaItem.id, upload.source]);

  const generateStills = useCallback(async e => {
    setShowFrames(false);
    setUploadingStills(true);
    const [frameFile] = e.target.src.split('/').pop().split('?');
    try {
      await requestReplaceStills(upload.id, mediaItem.id, 
        mediaItem.media_type === 'image' ? `${frameFile}` : `frames/${frameFile}`);
      dispatch(showFlashNotificationOp('saveSuccess'));
    } catch (e) {
      showErrorMessage(e);
    }
    try {
      const uploadUpdate = await getUpload(upload.id, upload.source);
      change({ items: uploadUpdate.data.items });
    } catch (e) {
      showErrorMessage(e);
    }

    if (!unmounted.current) {
      setUploadingStills(false);
    }

  }, [mediaItem.id, upload.id, upload.source]);

  return <>
    {mediaItem &&
    <div tw="flex justify-between items-start mt-3">
      <div tw="flex-1 mr-3">
        <a target="_blank" onClick={() => openStillsInNewTab(mediaItem)}>
          {!uploadingStills && <ImageView src={thumbLarge(mediaItem)} size={96} retry/>}
          {uploadingStills && <BusyIcon size={96}/>}
        </a>
      </div>
      <div tw="flex-1">
        <div className="buttons" tw="relative inline-block text-left">
          <UploadStillsButton onSelectFiles={uploadStills} loading={uploadingStills} tw="mb-2"/>
          {(mediaItem.mediaType || mediaItem.media_type) !== 'audio' &&
            <GenerateFromVideoButton onClick={() => setShowFrames(!showFrames)}
              onBlur={() => setShowFrames(false)} disabled={uploadingStills}/>}
          {showFrames && <div css={[popup, grid, tw`w-80`]} role="menu" aria-orientation="horizontal"
            aria-labelledby="menu-button" tabIndex="-1">
            {mediaItem.input && mediaItem.input.video &&
              (
                parseFloat(mediaItem.input.video.duration) > 120 ?
                  ['001', '011', '021', '029', '041', '051'] : ['001', '006', '012', '018', '024', '029']
              )
                .map(n => <ImageView key={n}
                  src={`${assetPath(mediaItem)}/frames/${n}.jpg?access_token=${getIdTokenSync()}`}
                  tw="cursor-pointer" onMouseDown={generateStills}/>)}
            {mediaItem.media_type === 'image' &&
                <ImageView
                  src={`${assetPath(mediaItem)}/normalized.png?access_token=${getIdTokenSync()}`}
                  tw="cursor-pointer" onMouseDown={generateStills}/>}
          </div>}
        </div>
      </div>
    </div>}
  </>;
};

