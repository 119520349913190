import React, { useCallback, useEffect, useState } from 'react';
import tw from 'twin.macro';
import { FileUploadIcon, SpinnerIcon } from 'icons';
import { Button, RefreshButton } from '../common';
import {
  deleteConversionArchive,
  deleteConversionArchives,
  exportConversionArchive,
  getCanceledPostsHistory,
  importConversionArchive,
} from 'services/dmp';
import { ArchivePostsList } from './postsconversion/ArchivePostsList';
import { useConfirmDialog, useShowErrorMessage } from '../common/hooks';
import { selectSelectedItems } from 'components/selection/ducks';
import { useDispatch, useSelector } from 'react-redux';
import { ItemsCheckBox } from '../selection';

const UploadButton = ({ onChange, label, loading }) =>
  <Button
    css={[
      tw`hover:cursor-pointer hover:bg-blue-200`,
      loading && tw`pt-1 pb-2.5`,
    ]}
    Icon={loading ? SpinnerIcon : FileUploadIcon}>
    <input className="file-input" type="file" name="admin-upload" onChange={e => onChange(e)} />
    {loading ? 'importing' : label}
  </Button>;

export const PostsConversionArchive = () => {
  const showErrorMessage = useShowErrorMessage();

  const [date, setDate] = useState(null);
  const [links, setLinks] = useState(null);

  const [donePosts, setDonePosts] = useState([]);
  const [selectedDayPosts, setSelectedDayPosts] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const uploading = uploadProgress > -1;

  const loadDaysPosts = useCallback(() => {
    getCanceledPostsHistory()
      .then(({ data }) => {
        if (data && data.length > 0) {
          setDonePosts(data);
          return;
        }

        setDonePosts([]);
      })
      .catch(showErrorMessage);
  }, []);

  useEffect(loadDaysPosts, [loadDaysPosts]);

  useEffect(() => {
    if (donePosts) {

      // set selected posts as the first date in data
      if (donePosts.length > 0) {
        setDate(donePosts[0].day);
      }

      const ls = [];
      for (const dayposts of donePosts) {
        ls.push(dayposts.day);
      }

      setLinks(ls);
    }
  }, [donePosts]);

  useEffect(() => {
    if (date && donePosts.length > 0) {
      for (const dayposts of donePosts) {
        if (dayposts.day === date) {
          setSelectedDayPosts(dayposts.posts);
        }
      }
    } else {
      setSelectedDayPosts([]);
    }
  }, [date, donePosts]);

  const dispatch = useDispatch();

  const selectedPosts = useSelector(state => ({
    selected: selectSelectedItems(state),
  }));

  // exportPosts as json file
  const exportPosts = useCallback(() => {
    if (!selectedPosts.selected['default'] || !selectedPosts.selected['default'].items ||
      selectedPosts.selected['default'].items.length === 0) {
      const callback = (data) => {
        const element = document.createElement('a');
        const file = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
        element.href = URL.createObjectURL(file);
        element.download = 'posts.json';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      };

      exportConversionArchive(null, callback);
      return;
    }

    if (selectedPosts.selected['default'].items.length === 0) {
      return;
    }

    // find the respective conversion archives of seleced posts
    const selectedPostsArchive = [];
    for (const post of selectedPosts.selected['default'].items) {
      for (const archive of donePosts) {
        if (archive.day === date) {
          for (const p of archive.posts) {
            if (p.id === post) {
              selectedPostsArchive.push(p.id);
            }
          }
        }
      }
    }

    const callback = (data) => {
      const element = document.createElement('a');
      const file = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
      element.href = URL.createObjectURL(file);
      element.download = 'posts.json';
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    };

    exportConversionArchive(selectedPostsArchive, callback);
  }, [selectedPosts.selected && selectedPosts.selected['default']]);

  const deleteSelected = useConfirmDialog(useCallback(() => {
    if (!selectedPosts.selected['default'] || !selectedPosts.selected['default'].items ||
      selectedPosts.selected['default'].items.length === 0) {
      return;
    }

    return new Promise((resolve) => {
      if (selectedPosts.selected['default'].items.length === 1) {
        deleteConversionArchive(selectedPosts.selected['default'].items[0]).then(() => {
          setTimeout(() => {
            loadDaysPosts();
          }, 1000);
        }).catch(showErrorMessage).finally(resolve);
        return;
      }

      deleteConversionArchives(selectedPosts.selected['default'].items).then(() => {
        setTimeout(() => {
          loadDaysPosts();
        }, 1000);
      }).catch(showErrorMessage).finally(resolve);
    });
  }, [selectedPosts.selected && selectedPosts.selected['default']]), false);

  // importPosts as json file
  const importPosts = useCallback(e => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      setUploadProgress(0);

      const reader = new FileReader();
      reader.onload = function () {
        importConversionArchive(JSON.parse(reader.result)).then(() => {
          setUploadProgress(-1);
          loadDaysPosts();
        }).catch(showErrorMessage);
      };

      if (e.target.files[0]) {
        reader.readAsText(e.target.files[0]);
      }
    }
  }, []);


  return (
    <>
      <div tw="py-14">
        <div tw="m-3 grid grid-cols-1 md:grid-cols-3 md:gap-3">
          {<div tw="space-y-3">
          </div>}
          {<div tw="space-y-3">
            <div tw="flex justify-between h-8">
              <h2 tw="text-3xl leading-tight">
                Cancelled On {date ? date : '...'}
              </h2>
              <ItemsCheckBox controlItems={selectedDayPosts} />
              {<div tw="flex self-start">
                {<RefreshButton onRefresh={loadDaysPosts} tw="mr-2" />}
              </div>}
            </div>
            <div tw="flex justify-between h-8">
              <Button onClick={() => dispatch(deleteSelected)} label={'Delete'} />
              <Button onClick={() => dispatch(exportPosts)} label={'Export'} />
              <UploadButton
                label="Import"
                onChange={importPosts}
                loading={uploading}
                uploadProgress={uploadProgress}
              />
            </div>
            <section>
              {<ArchivePostsList posts={selectedDayPosts} deleteCallback={loadDaysPosts} />}
            </section>
          </div>}
          {<div tw="space-y-3">
            <div tw="flex justify-between h-8">
              <h2 tw="text-3xl leading-tight">
                Archive
              </h2>
            </div>
            {links && links.map(l => <div key={l} tw="flex self-start">
              <a key={l} tw="mr-2" style={{ fontWeight: ((l === date) ? '700' : '400') }} onClick={() => { setDate(l); }
              }>{l}</a>
            </div>)}
          </div>}
        </div>
      </div>
    </>
  );
};
