import React from 'react';

export const MenuIcon = props =>
  <svg 
    fill="none" 
    viewBox="0 0 24 24" 
    height="1em" 
    width="1em" {...props}
  >
    <path
      fill="currentColor"
      d="M2 6a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM2 12.032a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM3 17.064a1 1 0 100 2h18a1 1 0 000-2H3z"
    />
  </svg>;
