import React from 'react';
import 'twin.macro';
import { SpinnerIcon, BxRefreshIcon } from 'icons';

export const ButtonIcons = ({ RenderAs }) => (
  <RenderAs tw="mr-1 h-5 w-5 text-gray-400" />
);

const ButtonBaseStyle = ({ RenderAs, children, ...props }) => (
  <RenderAs
    tw="font-medium inline-flex relative items-center px-4 py-2 rounded-md border border-gray-300 bg-white  
  whitespace-nowrap text-gray-900 
  hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600"
    {...props}
  >
    {children}
  </RenderAs>
);

export const Button = ({ label, Icon, children, ...props }) => (
  <ButtonBaseStyle RenderAs={props.href ? 'a' : 'button'} {...props}>
    {Icon && <ButtonIcons RenderAs={Icon} />}
    {label && !children && <span>{label}</span>}
    {children && !label && <>{children}</>}
  </ButtonBaseStyle>
);

export const RefreshButton = ({ onRefresh, ...p }) => (
  <Button Icon={BxRefreshIcon} onClick={() => onRefresh()} {...p} />
);

export const ActionPopUpButton = ({
  label,
  onClick,
  loading = false,
  disabled,
}) => (
  <Button
    tw="disabled:opacity-25 disabled:cursor-auto"
    Icon={loading ? SpinnerIcon : null}
    label={label}
    onClick={onClick}
    disabled={loading || disabled}
  ></Button>
);

export const ActionButton = ({
  label,
  onClick,
  buttons = true,
  loading = false,
  props,
}) => {
  if (buttons) {
    return (
      <Button data-button={label} onClick={onClick} {...props}>
        {loading ? <SpinnerIcon /> : label}
      </Button>
    );
  }

  return (
    <div
      data-button={label}
      tw="text-blue-600 hover:text-blue-900 cursor-pointer"
      onClick={onClick}
      {...props}
    >
      {loading ? <SpinnerIcon /> : label}
    </div>
  );
};
