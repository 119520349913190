import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentJobs, selectCurrentPosts } from '../ducks';
import { ConvertPost } from 'components/posts/ConvertPost';

export const ConvertPostList = ({stateFilter = ['*'], group}) => {
  const jobs = useSelector(selectCurrentJobs);
  const ps = useSelector(selectCurrentPosts);

  const filteredJobs = stateFilter[0] === '*' ? jobs : jobs.filter(j => stateFilter.includes(j.state));
  
  const [posts, setPosts] = useState([]);
  const loadPosts = useCallback(() => {
    if  (ps) {
      setPosts(ps.filter(p => {
        const js = filteredJobs.map(j => j.item.upload_id);

        if (js.includes(p.id)) {  
          const ajs = filteredJobs.filter(j => j.item.upload_id === p.id);
          let progress = 0;

          for (let i = 0; i < ajs.length; i++) {
            progress += ajs[i].progress;
          }

          p.progress = progress / ajs.length;
          return true;
        }

        return false;
      }));
    } else {
      setPosts(null);
    }
  }, [jobs, ps]);

  useEffect(loadPosts, [loadPosts]);

  return (
    <>
      {posts && posts.map(post => <ConvertPost key={post.id} item={post} group={group}/> )}
    </>
  );
};
