import { usersFetchAction } from './actions';
import { getUsersList, deleteUser } from 'services/dmp';

export const usersFetchListOp = filter => async dispatch => {
  dispatch(usersFetchAction({ list: [], filter, status: 'loading' }));
  try {
    const list = await getUsersList(filter);
    dispatch(usersFetchAction({ list, filter, status: 'loaded' }));
  } catch (error) {
    dispatch(usersFetchAction({ items: [], filter, status: 'loaded', error }));
  }
};

export const usersDeleteOp = email => async dispatch => {
  await deleteUser(email);
  return await dispatch(usersFetchListOp());
};
