import React, { useEffect, useState } from 'react';
import tw, { GlobalStyles } from 'twin.macro';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faCheckCircle,
  faCogs,
  faEdit,
  faFileImport,
  faFileUpload,
  faFrown,
  faHome,
  faHourglass,
  faHourglassEnd,
  faHourglassHalf,
  faHourglassStart,
  faInfoCircle,
  faPause,
  faPlay,
  faPlayCircle,
  faPlus,
  faRedo,
  faSearch,
  faSpinner,
  faSync,
  faTrash,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { LayoutMain } from './layout';
import { createReduxStore, setMessageAction } from './store';
import { authSignInOp } from './auth/ducks';
import { ActivatePage, LoginPage } from './auth';
import { getClaims, listenAuthStateChanged } from 'services/auth';

library.add(
  faHome,
  faPlayCircle,
  faEdit,
  faHourglass,
  faHourglassStart,
  faHourglassHalf,
  faHourglassEnd,
  faCogs,
  faInfoCircle,
  faFileUpload,
  faFileImport,
  faArchive,
  faCheckCircle,
  faSearch,
  faSpinner,
  faFrown,
  faSync,
  faTrash,
  faUser,
  faPlus,
  faPlay,
  faPause,
  faRedo,
);

const store = createReduxStore();

const Wrapper = tw.div`text-xs font-medium leading-5`;

const AuthorizedView = ({ authState }) => {
  const location = useLocation();

  return <>
    {authState === 'loggedIn' && <LayoutMain/>}
    {authState === 'loggedOut' && <Redirect to={{
      pathname: '/login',
      state: { next: location.pathname + (location.search || '') },
    }}/>}
    {authState === 'initial' && <div id="spinner" tw="animate-pulse">
      <img alt="logo" src={'/img/logo-bigger-new.png'}/>
    </div>}
  </>;
};

export const App = () => {
  const [authState, setAuthState] = useState('initial');

  useEffect(() => {

    return listenAuthStateChanged(currentUser => {
      if (currentUser) {
        setAuthState(x => {
          if (x === 'initial') {
            getClaims(true)
              .then(({ sub, name, role }) => {
                store.dispatch(authSignInOp({ email: sub, name, role }, null));
                setAuthState('loggedIn');
              })
              .catch(e => store.dispatch(
                setMessageAction({ text: JSON.stringify(e), title: 'Error', color: 'danger' }),
              ));
            return 'initial';
          }
          return 'loggedIn';
        });
      } else {
        setAuthState('loggedOut');
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <GlobalStyles/>
        <Wrapper>
          <Switch>
            <Route exact path="/login" component={LoginPage}/>
            <Route exact path="/activate" component={ActivatePage}/>
            <Route path="*" render={() => <AuthorizedView authState={authState}/>}/>
          </Switch>
        </Wrapper>
      </BrowserRouter>
    </Provider>

  );
};

