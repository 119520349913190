import { postsFetchAction } from './actions';
import { getPosts, archivePost } from 'services/dmp';
import { searchPosts } from 'services/post';
import { selectionClearAction } from '../../selection/ducks';
import { selectPostsListItems, selectPostsListStatus } from './selectors';
import { setMessageAction } from '../../store';

export const postsFetchOp = (postsFilter, page, mediatypeFilter) => async dispatch => {
  const filter = postsFilter || 'all';
  dispatch(selectionClearAction());
  dispatch(postsFetchAction({ filter, items: [], status: 'loading' }));
  try {
    const { data: { items = [], admins = [] } } = await getPosts(filter, page, mediatypeFilter);
    dispatch(postsFetchAction({ filter, items, admins, status: 'loaded' }));
  } catch (error) {
    dispatch(postsFetchAction({ filter, items: [], status: 'error', error }));
  }
};

export const postsArchiveOp = (posts, note) => async (dispatch, getState) => {
  const state = getState();
  const postIds = posts.map(p => p.id);
  const listsKeys = Object.keys(state.posts.filters).filter(
    k => postIds.some(i => selectPostsListItems(state, k).map(p => p.id).includes(i)),
  );

  listsKeys.map(k => postsFetchAction({
    filter: k,
    status: selectPostsListStatus(state, k),
    items: selectPostsListItems(state, k).filter(p => !postIds.includes(p.id)),
  })).map(dispatch);

  try {
    await Promise.all(posts.map(p => archivePost(p, note)));
  } catch (e) {
    dispatch(setMessageAction({ text: e.toString(), title: 'Error', color: 'danger' }));
  } finally {
    await postsFetchOp()(dispatch);
  }
};

export const postsSearchOp = (postsFilter, page, mediatypeFilter, term) => async dispatch => {
  const filter = postsFilter || 'all';
  dispatch(postsFetchAction({ filter, items: [], status: 'loading' }));
  try {
    const { data: { items = [] } } = await searchPosts(filter, page, mediatypeFilter, term);
    dispatch(postsFetchAction({ filter, items, status: 'loaded' }));
  } catch (error) {
    dispatch(postsFetchAction({ filter, items: [], status: 'error', error }));
  }
};
