import { createSelector } from 'reselect';

export const selectPostsList = (state, filter) => filter ? (state.posts.filters[filter] || {}) : {};

const selectUpload = (_, props) => props.upload || {};
const postStats = item => item && item.stats;

// export const selectPostsListStatus = createSelector(selectPostsList, ({ state }) => state);

export const selectPostsListStatus = createSelector(selectPostsList, ({ status }) => status);
export const selectPostsListItems = createSelector(selectPostsList, ({ items }) => items || []);
export const selectAdmins = createSelector(selectPostsList, ({ admins }) => admins || []);
export const selectPostStats = createSelector(
  [state => selectPostsListItems(state, 'all'), selectUpload],
  (items, upload) => postStats(items.find(i => i.id === (upload.post && upload.post.id))),
);
