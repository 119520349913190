import React, { useState, useEffect } from 'react';
import 'twin.macro';
import { connect, useSelector } from 'react-redux';
import {
  LoadingListContainer,
  PageContainer,
  RefreshButton,
  SearchTextInput,
  SelectControl,
  ListContainer,
} from '../common';
import { ItemsCheckBox } from '../selection';
import { Post } from './Post';
import { PostButtons } from './PostButtons';
import { Paginator } from '../common/Paginator';
import { navigate } from '../utils';
import {
  postsFetchOp as fetch,
  postsSearchOp as search,
  selectPostsListItems,
  selectAdmins,
  selectPostsListStatus,
} from './ducks';
import { selectSelectedItems } from 'components/selection/ducks';
const postsFiltersOptions = [
  { label: 'Alles', value: 'all' },
  { label: 'Staat online', value: 'published' },
  { label: 'Gepland', value: 'scheduled' },
  { label: 'Ontwerp', value: 'unscheduled' },
  { label: 'Geheim weggezet', value: 'secret' },
];
const mediatypeFilterOptions = [
  { label: 'Alles', value: 'all' },
  { label: 'Video', value: 'video' },
  { label: 'Plaatjes', value: 'foto' },
  { label: 'Audio', value: 'audio' },
];
const getParam = ({ search }, name, fallback) =>
  new URLSearchParams(search).get(name) || fallback;
const getStatusFilter = (location) => getParam(location, 'status', 'all');
const getMediatypeFilter = (location) => getParam(location, 'mediatype', 'all');
const getPage = (location) => getParam(location, 'page', '0');
const ToolBar = ({
  items,
  selected,
  statusFilter,
  mediatypeFilter,
  history,
  onSearch,
  onRefresh,
  page,
}) => {
  const selectedItems = selected.items
    .map((itemId) => items.find((item) => item.id === itemId))
    .filter((s) => s);
  return (
    <div tw="w-full flex flex-col md:flex-row justify-between">
      <div tw="flex flex-col md:flex-row mb-2 md:mb-0">
        <div tw="flex flex-wrap md:flex-nowrap justify-between">
          <div tw="order-1 flex-grow md:w-full mb-2 md:mb-0 mr-2 md:mr-3">
            <SearchTextInput
              onSearch={onSearch}
              placeholder="Zoek in posts"
              delay
            />
          </div>
          <div tw="flex flex-shrink order-2 justify-end mb-2 md:mb-0 md:mr-3">
            <RefreshButton onRefresh={onRefresh} />
          </div>
          <div tw="order-3 flex flex-row w-full justify-between mb-2 md:mb-0">
            <div tw="flex-1 mr-1 md:mr-3">
              <SelectControl
                value={statusFilter}
                options={postsFiltersOptions}
                onChange={(s) =>
                  navigate(history, '/posts', {
                    status: s,
                    mediatype: mediatypeFilter,
                    page,
                  })
                }
              />
            </div>
            <div tw="flex-1 ml-1 md:ml-0 md:mr-3">
              <SelectControl
                value={mediatypeFilter}
                options={mediatypeFilterOptions}
                onChange={(s) =>
                  navigate(history, '/posts', {
                    status: statusFilter,
                    mediatype: s,
                    page,
                  })
                }
              />
            </div>
          </div>
        </div>
        <Paginator
          startPage={page}
          history={history}
          onPageChange={(p) =>
            navigate(history, '/posts', {
              status: statusFilter,
              mediatype: mediatypeFilter,
              page: p,
            })
          }
        />
      </div>
      <div tw="flex flex-col md:flex-row md:items-center">
        <PostButtons posts={selectedItems}>
          <ItemsCheckBox controlItems={items} />
        </PostButtons>
      </div>
    </div>
  );
};

const PostsTab = ({ location, history, fetch, search }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // handle scroll position after content load
  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(scrollPosition));
        sessionStorage.removeItem('scrollPosition');
      }, 100);
    }
  };

  const fetchPosts = () => {
    fetch(
      getStatusFilter(location),
      getPage(location),
      getMediatypeFilter(location),
    ).then(handleScrollPosition);
  };

  useEffect(() => {
    if (searchTerm === '') {
      fetchPosts();
    }

    const searchPosts = () => {
      search(
        getStatusFilter(location),
        getPage(location),
        getMediatypeFilter(location),
        searchTerm,
      );
    };
    const newStatusFilter = getStatusFilter(location);
    const newMediatypeFilter = getMediatypeFilter(location);
    const newPage = getPage(location);
    if (
      newStatusFilter !== getStatusFilter(history.location) ||
      newMediatypeFilter !== getMediatypeFilter(history.location) ||
      newPage !== getPage(history.location) ||
      searchTerm !== ''
    ) {
      if (searchTerm !== '') {
        searchPosts();
      } else {
        fetchPosts();
      }
    }
  }, [location, history, fetch, search, searchTerm]);

  const { status, items, admins, selected } = useSelector((state) => ({
    items: selectPostsListItems(state, getStatusFilter(location)),
    admins: selectAdmins(state, getStatusFilter(location)),
    status: selectPostsListStatus(state, getStatusFilter(location)),
    selected: selectSelectedItems(state),
  }));

  const toolBarProps = {
    items,
    selected: selected['default'] || { items: [] },
    history,
    page: getPage(location),
    statusFilter: getStatusFilter(location),
    mediatypeFilter: getMediatypeFilter(location),
    onRefresh: fetchPosts,
    onSearch: (term) => setSearchTerm(term),
  };
  const listProps = {
    items,
    status,
    mapFunc: (item) => (
      <Post
        key={item.id}
        item={item}
        admins={admins}
      />
    ),
  };
  return (
    <PageContainer isPostPage toolbar={<ToolBar {...toolBarProps} />}>
      <ListContainer>
        <LoadingListContainer {...listProps} />
      </ListContainer>
      <div tw="md:hidden mt-4 text-center">
        <Paginator
          maxPages={5}
          startPage={getPage(location)}
          history={history}
          hidePageInput
          onPageChange={(p) =>
            navigate(history, '/posts', {
              status: getStatusFilter(location),
              mediatype: getMediatypeFilter(location),
              page: p,
            })
          }
        />
      </div>
      <div tw="hidden md:block mt-4 text-center">
        <Paginator
          maxPages={20}
          startPage={getPage(location)}
          history={history}
          hidePageInput
          onPageChange={(p) =>
            navigate(history, '/posts', {
              status: getStatusFilter(location),
              mediatype: getMediatypeFilter(location),
              page: p,
            })
          }
        />
      </div>
    </PageContainer>
  );
};
const mapStateToProps = (state, { location }) => ({
  items: selectPostsListItems(state, getStatusFilter(location)),
  admins: selectAdmins(state, getStatusFilter(location)),
  status: selectPostsListStatus(state, getStatusFilter(location)),
  selected: selectSelectedItems(state),
});
const mapDispatchToProps = { fetch, search };
export const PostsTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PostsTab);
