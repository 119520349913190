import React, { useCallback, useState, useEffect } from 'react';
import tw from 'twin.macro';
import { trimText, cleanTextContent } from '../utils';
import { Uploader } from '../edit';
import {
  MediaIcon,
  DateUpload,
  Tags,
  NsfTags,
  Tag,
  Button,
} from '../common';
import { Progress } from 'react-bulma-components';
import { DumpertSingleDIcon } from 'icons';
import { editorCSS } from '../edit/RichTextEditorStyles';
import { useGetIsRisk } from '../edit/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentJobsByUpload } from '../current/ducks';
import { setMessageAction, showFlashNotificationOp } from '../store';
import {
  useConfirmDialog,
  useShowErrorMessage,
  useUnmounted,
} from '../common/hooks';
import { updatePost } from 'services/dmp';
import { TagsInput } from 'react-tag-input-component';
import { RichTextEditor } from '../edit/RichTextEditor';
import styles from './List.css';

export const ListContainer = ({ children }) => (
  <div tw="bg-white w-full shadow overflow-hidden sm:rounded-md">
    {children}
  </div>
);

export const ListRow = ({ children, ...r }) => (
  <div
    css={[
      tw`flex flex-col md:flex-row border-gray-200 border-solid border`,
    ]}
    {...r}
  >
    {children}
  </div>
);

export const ListColumnFixed = ({ children, text = children, small, ...r }) => (
  <div
    css={[
      tw`flex flex-col md:flex-row md:w-3/8 items-center px-2 py-2`,
      small && tw`md:w-1/8`,
    ]}
    {...r}
  >
    {text}
  </div>
);

export const ListColumnGrow = ({ children, text = children, ...props }) => (
  <div {...props}>{text}</div>
);

export const ListItemMedia = ({ children, still }) => {
  const hasStill = still && !still.includes('missing');
  return (
    <div tw="w-full md:flex-shrink-0 md:h-full md:w-40">
      {!children && (
        <>
          {hasStill && (
            <div data-img="landscape">
              <img src={still} alt="" tw="rounded object-cover" />
            </div>
          )}
          {!hasStill && (
            <div
              tw="bg-gray-800 w-full flex items-center justify-center rounded mb-2 md:mb-0"
              style={{ height: '90px' }}
            >
              <DumpertSingleDIcon tw="h-8" />
            </div>
          )}
        </>
      )}
      {children && (
        <>
          <div tw="p-2 md:p-0 bg-gray-200 h-full flex items-center justify-center rounded">
            {children}
          </div>
        </>
      )}
    </div>
  );
};

function truncateHTML(html, maxLength) {
  const dom = new DOMParser().parseFromString(html, 'text/html');
  const body = dom.body;
  let totalLength = 0;
  let truncated = false;

  function traverse(node) {
    if (truncated) {
      node.parentNode.removeChild(node);
      return;
    }

    if (node.nodeType === Node.TEXT_NODE) {
      if (totalLength + node.length > maxLength) {
        node.textContent = node.textContent.slice(0, maxLength - totalLength) + '...';
        truncated = true;
      }
      totalLength += node.length;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      Array.from(node.childNodes)
        .forEach(child => traverse(child));
      
      // Remove empty nodes after truncation
      if (node.childNodes.length === 0 && node.tagName !== 'BR') {
        node.parentNode.removeChild(node);
      }
    }
  }

  traverse(body);

  return body.innerHTML;
}

export const ListItemInfo = ({ item }) => {
  if (!item) {
    return null;
  }

  if (!Array.isArray(item.tags)) {
    item.tags = item.tags === '' ? [] : item.tags?.split(' ') || [];
  }

  const [itemState, setItemState] = useState({
    original: {
      ...item,
      title: trimText(item.title, 80),
      description: (item.description) ? truncateHTML(item.description, 156) : '<p></p>',
    },
    new: {
      ...item,
      title: trimText(item.title, 80),
      description: (item.description) ? truncateHTML(item.description, 156) : '<p></p>',
    },
  });

  const [haveChanges, setHaveChanges] = useState(false);

  useEffect(() => {
    if (itemState) {
      const oj = JSON.stringify({
        title: itemState?.original?.title,
        description: itemState?.original?.description,
        tags: itemState?.original?.tags,
      });

      const nj = JSON.stringify({
        title: itemState?.new?.title,
        description: itemState?.new?.description,
        tags: itemState?.new?.tags,
      });

      if (nj !== oj) {
        setHaveChanges(true);
      } else {
        setHaveChanges(false);
      }
    }
  }, [itemState]);

  const unmounted = useUnmounted();
  const showErrorMessage = useShowErrorMessage();
  const dispatch = useDispatch();
  const { jobs = [] } = useSelector(selectCurrentJobsByUpload)[item.upload_id] || {};
  const itemIsBeingConverted = jobs.length > 0;

  const uploadToPost = (upload) => {
    const keys = [
      'title',
      'description',
      'nsfw',
      'nopreroll',
      'secret',
      'scripts',
      'postStatus',
      'pubDate',
    ];
    const post = Object.entries(upload)
      .filter(([k, v]) => keys.includes(k) && v !== undefined)
      .reduce((o, [k, v]) => ({ ...o, [k]: v }), {}); // leave out undefined fields

    post.upload_id = upload.upload_id;
    if (upload.tags !== undefined) {
      post.tags = upload.tags.join(' ');
    }
    if (upload.pubDate !== undefined) {
      post.date = upload.pubDate;
    }
    if (post.postStatus === 'draft') {
      post.date = null;
    } else if (post.postStatus === 'planned') {
      post.date = post.pubDate;
    } else if (post.postStatus === 'publish') {
      if (post.date === undefined) {
        post.date = upload.date;
      }
    }
    return { post, publishWhenDone: post.postStatus === 'publish' };
  };

  const errorHandler = (e) => {
    if (itemIsBeingConverted) {
      dispatch(
        setMessageAction({
          title: 'Error',
          color: 'danger',
          text: 'Eventjes geduld nog. Dit item is nog niet geconverteerd',
          message: 'Eventjes geduld nog. Dit item is nog niet geconverteerd',
        }),
      );
    } else {
      showErrorMessage(e);
    }
    if (!unmounted.current) {
      setUpdating(false);
    }
  };

  const saveHandler = () => {
    if (!unmounted.current) {
      setUpdating(false);
    }

    dispatch(showFlashNotificationOp('saveSuccess'));
    setItemState({
      original: itemState.new,
      new: itemState.new,
    });
  };


  const [updating, setUpdating] = useState(false);

  const save = useConfirmDialog(useCallback(() => {
    setUpdating(true);
    const { post } = uploadToPost(itemState.new);
    if (
      post.postStatus === 'publish' &&
      (!post.date || post.date.getTime() > Date.now())
    ) {
      post.date = new Date(Date.now());
    }

    return updatePost(post)
      .then(() => dispatch(saveHandler))
      .catch(errorHandler);
  }), false);

  const cancelEdit = (e) => {
    e.preventDefault();

    setItemState({
      original: itemState.original,
      new: itemState.original,
    });
  };

  const handleTitleChange = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();

      setItemState({
        original: itemState.original,
        new: {
          ...itemState.new,
          title: e.target.innerText,
        },
      });
    }

    if (e.key === 'Escape') {
      e.preventDefault();

      setItemState({
        original: itemState.original,
        new: itemState.original,
      });

      e.target.innerText = itemState?.new.title;
      e.target.blur();
    }
  });

  const onTitleBlur = useCallback((e) => {
    setItemState({
      original: itemState.original,
      new: {
        ...itemState.new,
        title: e.target.innerText,
      },
    });
  });

  const onDescBlur = useCallback((e) => {
    setItemState({
      original: itemState.original,
      new: {
        ...itemState.new,
        description: e,
      },
    });
  });

  const changeTags = useCallback(({ tags }) => {
    setItemState({
      original: itemState.original,
      new: {
        ...itemState.new,
        tags,
      },
    });
  });

  return (
    <div tw="w-full ml-4">
      {!(item.id && item.media) && (
        <>
          <p tw="font-medium text-gray-900 max-w-xs overflow-x-hidden overflow-ellipsis">
            {trimText(item.title, 100)}
          </p>
          <p
            css={[
              editorCSS,
              tw`text-gray-500 max-w-[16rem] max-h-[16rem] overflow-x-hidden overflow-ellipsis`,
            ]}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: cleanTextContent(
                  item.description.length > 86
                    ? item.description.substring(0, 86).concat('...')
                    : item.description,
                ),
              }}
            />
          </p>
          <div tw="pt-2 flex justify-start items-start">
            <MediaIcon mediaType={item.media_type} />
            <Tags list={item.tags} max={3} />
          </div>
        </>
      )}
      {itemState.original?.id && itemState.original?.media && (
        <>
          <p
            tw="font-medium overflow-ellipsis"
            className={styles.blinput}
            contentEditable="true"
            suppressContentEditableWarning={true}
            onKeyUp={handleTitleChange}
            onBlur={onTitleBlur}
          >
            {trimText(itemState?.new?.title, 80)}
          </p>
          <div
            className={styles.rte}
          >
            <RichTextEditor
              shouldFocus={false}
              text={
                truncateHTML(itemState?.new?.description, 156)
              }
              onChange={() => { }}
              onRawChange={() => { }}
              onBlur={onDescBlur}
              id="rich-text-editor"
              hideButtons={true}
            />
          </div>

          <div
            tw="flex justify-start items-start"
            css={[
              `.rti--container {--rti-s: 0.1rem !important; min-height: 1.8745rem; width: 100%;
                --rti-border: transparent; }`,
              '.rti--container:hover {--rti-border: #d1d5db; outline: none;}',
              '.rti--container:focus-within {--rti-border: #d1d5db; --rti-main: #d1d5db; border: none;}',
              'align-items: flex-end;',
            ]}
          >
            <MediaIcon mediaType={itemState?.new?.media_type} />
            <TagsInput
              value={itemState?.new?.tags || []}
              onChange={(tags) => changeTags({ tags })}
              name="tags"
              classNames={{ input: 'input-cls' }}
              separators={['Enter', ' ', ',']}
            />
            {haveChanges && (
              <>
                <Button
                  enabled={!updating}
                  onClick={save}
                  tw="border-gray-300 pt-[0.25rem] pb-[0.25rem]"
                >
                  Save
                </Button>
                <Button
                  enabled={!updating}
                  onClick={cancelEdit}
                  tw="border-gray-300 pt-[0.25rem] pb-[0.25rem]"
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const ListItemUploader = ({
  item,
  uploader,
  created,
  uploadDate,
  children,
  ...props
}) => {
  const itemId = item.id;
  let isRisk = false;
  if (itemId.includes('_')) {
    const articleId = itemId.split('_')[0];
    isRisk = useGetIsRisk(articleId);
  }

  return (
    <div tw="flex flex-col px-2 w-full md:p-2 justify-center items-start" {...props}>
      <Uploader uploader={uploader} created={created} uploadDate={uploadDate} />
      <NsfTags nsfw={item.nsfw} nsfa={item.nopreroll} />
      {isRisk.isRisk ? <Tag label="Risk" color="red" /> : ''}
      {children}
    </div>
  );
};

export const ListItemStatus = ({
  children,
  date,
  uploadProgress,
  isArchived,
}) => {
  return (
    <div tw="flex px-2 py-2 md:px-2">
      <>
        {!isArchived && (
          <>
            <DateUpload created={date} isPost />
            {uploadProgress > 0 && (
              <div>
                <Progress
                  max={100}
                  value={uploadProgress}
                  color={
                    uploadProgress < 0.5
                      ? 'warning'
                      : uploadProgress === 1
                        ? 'success'
                        : 'info'
                  }
                  size="small"
                />
              </div>
            )}
          </>
        )}
        {isArchived && <Tag label="Gearchiveerd" color="gray" />}
        {children}
      </>
    </div>
  );
};

export const ConvertListItemStatus = ({ children, date, uploadProgress }) => (
  <div tw="flex w-full items-start md:items-center px-2 py-2 w-full md:px-2 md:w-auto">
    <>
      <DateUpload created={date} isPost />
      {uploadProgress >= 0 && (
        <>
          <progress max="1" value={uploadProgress}>
            {uploadProgress * 100}%
          </progress>
        </>
      )}
      {children}
    </>
  </div>
);

export const ListItemActions = ({ children, uploadItem }) => (
  <div
    css={[
      tw`flex w-full md:flex-row md:w-auto items-start 
  md:items-center md:justify-end md:justify-center px-2 md:pl-0 pr-2 pb-2`,
      uploadItem && tw`flex-col`,
    ]}
  >
    {children}
  </div>
);
