import 'twin.macro';
import React from 'react';
import { parseDateRFC3339 } from '../utils';

export const Uploader = ({ uploader = {}, created, uploadDate = false }) => (
  <>
    {uploader.name && <div tw="flex">
      <p tw="font-medium text-gray-900 mr-1">Naam:</p>
      <p tw="text-gray-500">{uploader.name}</p>
    </div>}
    {uploader.email && <div tw="flex">
      <p tw="font-medium text-gray-900 mr-1">Email:</p>
      <p tw="text-gray-500"><a href={'mailto:' + uploader.email}>{uploader.email}</a></p>
    </div>}
    {uploader.ip_address && <div tw="flex">
      <p tw="font-medium text-gray-900 mr-1">IP:</p>
      <p tw="text-gray-500">{uploader.ip_address}</p>
    </div>}
    {(created && uploadDate) && (
      <div tw="flex">
        <p tw="font-medium text-gray-900 mr-1">Upload datum:</p>
        <p tw="text-gray-500">{parseDateRFC3339(created)}</p>
      </div>      
    )}
  </>
);