import * as React from 'react';
import 'twin.macro';
import { Tag } from '../common';
import { parseDateRFC3339 } from '../utils';

export const DateUpload = ({ created, isPost }) => 
  <>
    {created && (
      <div tw="font-medium text-gray-900 block">
        {isPost && (
          new Date(created) > new Date(Date.now()) 
            ? <Tag label='Gepland' color="yellow" />
            : <Tag label='Online' color="green" />
        )}
        <span tw="text-gray-500 pt-1">
          {!isPost && <>Upload datum:</>}
          <i>{parseDateRFC3339(created)}</i>
        </span>
      </div>
    )}
  </>
;