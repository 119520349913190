import React, { Fragment } from 'react';
import 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import { authLogOutUserOp, selectAuthUser } from './ducks';
import { BxsUserCircleIcon } from 'icons';
import { Menu, Transition } from '@headlessui/react';

export const UserMenu = () => {
  const user = useSelector(selectAuthUser);
  const dispatch = useDispatch();
  return (
    <>
      <Menu as="div" tw="hidden sm:block ml-3 relative">
        {({ open }) => (
          <>
            <div>
              <Menu.Button 
                tw="bg-black flex rounded-full
                 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                id="user-menu" aria-expanded="false" aria-haspopup="true">
                <span tw='text-white px-3 py-2 rounded-md'>
                  <BxsUserCircleIcon tw="w-4 h-4 mr-1 float-left fill-current"/>
                  {user.name}
                </span>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                tw="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white 
                focus:outline-none z-10"
              >
                <Menu.Item >
                  {() => <div
                    tw="block px-4 py-2 text-gray-700 hover:text-gray-700 cursor-pointer"
                    onClick={() => dispatch(authLogOutUserOp())}>Uitloggen</div>}
                </Menu.Item>
              </Menu.Items>
            </Transition>

          </>
        )}
      </Menu>
    </>
  );
};

export const UserMenuMobile = () => {
  const user = useSelector(selectAuthUser);
  const dispatch = useDispatch();
  return (
    <div tw="pt-4 pb-3 border-t border-gray-700">
      <div tw="flex items-center px-5">

        <div>
          <div tw="text-base text-white">{user.name}</div>
          <div tw="text-gray-400">{user.email}</div>
        </div>
      </div>
      <div tw="mt-3 px-2 space-y-1">
        <div
          tw="block px-3 py-3 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 cursor-pointer"
          onClick={() => dispatch(authLogOutUserOp())}
        >
          Uitloggen
        </div>
      </div>
    </div>
  );
};