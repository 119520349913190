import React, { useCallback, useState } from 'react';
import { assetPath } from '../../utils';
import 'twin.macro';
import {
  removeWatermark,
  uploadWatermark,
} from 'services/uploads';
import { ImageView, Button } from '../../common';
import { getUpload } from 'services/dmp';
import { useShowErrorMessage, useUnmounted } from '../../common/hooks';
import {
  FileUploadIcon,
  DeleteIcon,
} from 'icons';

export const UploadWatermarkButton = ({  onSelectFiles, loading, ...p }) => 
  <Button tw="hover:cursor-pointer hover:bg-blue-200" Icon={FileUploadIcon}  {...p}>
    <input className="file-input" type="file" name="watermarkFile" onChange={e => onSelectFiles(e.target.files || [])}
      disabled={loading}/>
    Upload watermark
  </Button>;

export const RemoveWatermarkButton = ({ ...p }) => 
  <Button label='Remove' Icon={DeleteIcon} tw="mr-1" {...p} />;

export const Watermark = ({ upload, mediaItem, change }) => {
  const unmounted = useUnmounted();

  const { id, custom_watermark } = mediaItem ? mediaItem : { id: null, custom_watermark: '' };

  const [uploadingWatermark, setUploadingWatermark] = useState(false);

  const showErrorMessage = useShowErrorMessage();

  const doUploadWatermark = useCallback(async files => {
    if (files.length < 1) {
      return;
    }

    try {
      await uploadWatermark(upload.id, id, files[0]);
    } catch (e) { showErrorMessage(e); }
    try {
      const uploadUpdate = await getUpload(upload.id, upload.source);
      change({ items: uploadUpdate.data.items });
    } catch (e) { showErrorMessage(e); }

    if(!unmounted.current) {
      setUploadingWatermark(false);
    }
  }, [id, upload.id, upload.source]);

  const doRemoveWatermark = useCallback(async () => {
    setUploadingWatermark(true);
    try {
      await removeWatermark(upload.id, id);
    } catch (e) { showErrorMessage(e); }
    try {
      const uploadUpdate = await getUpload(upload.id, upload.source);
      change({ items: uploadUpdate.data.items });
    } catch (e) { showErrorMessage(e); }

    if(!unmounted.current) {
      setUploadingWatermark(false);
    }
  }, [id, upload.id, upload.source]);

  return <>
    <div tw="flex justify-between items-start mt-3">
      <div tw="flex-1 mr-3">
        {!custom_watermark && <>No custom watermark</>}
        {custom_watermark &&
        <ImageView src={`${assetPath(mediaItem)}/${custom_watermark}`} size={96} retry/>}
      </div>
      <div tw="flex-1">
        <div className="buttons">
          <UploadWatermarkButton 
            onSelectFiles={doUploadWatermark} 
            loading={uploadingWatermark}  tw="mb-2"/>
          <RemoveWatermarkButton 
            onClick={doRemoveWatermark} 
            disabled={!custom_watermark || uploadingWatermark}
          />
        </div>
      </div>
    </div>
  </>;
};
