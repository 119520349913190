// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rte--md1jU8 {
    border: none;
    padding: 2px;
    height: 3rem;
    overflow-y: auto;
    color: black;
    border-radius: 0.375rem;
}

.rte--md1jU8:hover, .rte--md1jU8[focus-within] {
    padding: 1px;
    border: 1px solid #d1d5db;
}

.rte--md1jU8:hover, .rte--md1jU8:focus-within {
    padding: 1px;
    border: 1px solid #d1d5db;
}

.rte--md1jU8[focus-within] {
    padding: 1px;
    border: 1px solid #d1d5db;
    background-color: #f3f4f6;
}

.rte--md1jU8:focus-within {
    padding: 1px;
    border: 1px solid #d1d5db;
    background-color: #f3f4f6;
}

.blinput--LNUQ8m {
    border: none;
    padding: 2px;
    overflow-y: auto;
    overflow-x: hidden;
    color: black;
    border-radius: 0.375rem;
    text-overflow:ellipsis;
}

.blinput--LNUQ8m:hover, .blinput--LNUQ8m[focus-within] {
    padding: 1px;
    border: 1px solid #d1d5db;
    outline: none;
}

.blinput--LNUQ8m:hover, .blinput--LNUQ8m:focus-within {
    padding: 1px;
    border: 1px solid #d1d5db;
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/List.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAHA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;AAC7B;;AAJA;IACI,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;AACjB;;AAJA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":[".rte {\n    border: none;\n    padding: 2px;\n    height: 3rem;\n    overflow-y: auto;\n    color: black;\n    border-radius: 0.375rem;\n}\n\n.rte:hover, .rte:focus-within {\n    padding: 1px;\n    border: 1px solid #d1d5db;\n}\n\n.rte:focus-within {\n    padding: 1px;\n    border: 1px solid #d1d5db;\n    background-color: #f3f4f6;\n}\n\n.blinput {\n    border: none;\n    padding: 2px;\n    overflow-y: auto;\n    overflow-x: hidden;\n    color: black;\n    border-radius: 0.375rem;\n    text-overflow:ellipsis;\n}\n\n.blinput:hover, .blinput:focus-within {\n    padding: 1px;\n    border: 1px solid #d1d5db;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rte": `rte--md1jU8`,
	"blinput": `blinput--LNUQ8m`
};
export default ___CSS_LOADER_EXPORT___;
