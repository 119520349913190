import React from 'react';
import 'twin.macro';
import { Link, NavLink } from 'react-router-dom';
import { UserMenu, UserMenuMobile } from '../auth';
import { Disclosure } from '@headlessui/react';
import { CreateUploadButton } from '../current/CreateUploadButton';
import { DumpertSingleDIcon, CloseIcon, MenuIcon } from 'icons';

export const NavBar = ({ links = [] }) => 
  <Disclosure as="nav" tw="bg-gray-800 fixed z-50 w-screen">
    {({ open }) => (
      <>
        <div tw="mx-auto px-4 sm:px-6 lg:px-8">
          <div tw="flex items-center justify-between h-16">
            <div tw="flex items-center">
              <div tw="flex-shrink-0">
                <Link to='/'>
                  <DumpertSingleDIcon tw="h-8"/>
                </Link>
              </div>
              <div tw="hidden sm:block sm:ml-6">
                <div tw="flex space-x-4">
                  {links.map(
                    ({ title, path }) => (
                      <NavLink 
                        key={path}
                        to={path}
                        tw="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md"
                        activeStyle={{ backgroundColor: 'rgba(107,114,128,var(--tw-bg-opacity))' }}
                      >
                        {title}
                      </NavLink>
                    ),
                  )}
                  <CreateUploadButton />
                </div>
              </div>
            </div>
            <div tw="hidden sm:ml-6 sm:block">
              <div tw="ml-3 relative">
                <UserMenu />
              </div>
            </div>
            <div tw="-mr-2 flex sm:hidden">
              <Disclosure.Button 
                tw="inline-flex items-center justify-center p-2 rounded-md text-gray-400 
                  hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                {open ? (
                  <CloseIcon tw="w-4 h-4 fill-current"/>
                ) : (
                  <MenuIcon tw="w-4 h-4 fill-current" />
                )}
              </Disclosure.Button>
            </div>
          </div>
        </div>

        <Disclosure.Panel tw="sm:hidden">
          <div tw="px-2 pt-2 pb-3 space-y-1">
            {links.map(
              ({ title, path }) => (
                <Disclosure.Button key={path} tw="w-full text-left">
                  <NavLink 
                    to={path}
                    tw="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-3 rounded-md"
                    activeStyle={{ backgroundColor: 'rgba(107,114,128,var(--tw-bg-opacity))' }}
                  >
                    {title}
                  </NavLink>
                </Disclosure.Button>
              ),
            )}
            <CreateUploadButton />
          </div>
          <UserMenuMobile />
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>;

