import axios from 'axios';
import { authorizeRequest } from './auth';

const postApi = axios.create({ baseURL: process.env.POST_HOST, withCredentials: true });
postApi.interceptors.request.use(authorizeRequest);


export const searchPosts = (filter, page=0, media_type, term) => postApi.get(
  `/search/${encodeURIComponent(term)}/${page}/?post_state=${filter}&media_type=${media_type}`,
);


export const getSubtitles = (postId, itemId, language) => postApi.get(
  `/subtitles/${postId}/${itemId}/${language}`,
);



export const setItemSubtitles = (postId, itemId, language, formData) => postApi.put(
  `/subtitles/${postId}/${itemId}/${language}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
);


export const getItemSubtitles = (itemId) => postApi.get(
  `/subtitles/${itemId}`,
);
