import React, { useCallback } from 'react';
import 'twin.macro';
import { ActionButton, ActionsDivider as Divider } from '../common';
import { useConfirmDialog, useShowErrorMessage } from '../common/hooks';
import { useCancelItem, useEditItem, useRedoItem, useDeleteConversionArchive } from './hooks';
import { postsArchiveOp } from './ducks';
import { SpinnerIcon } from 'icons';
import { useDispatch } from 'react-redux';
import { selectionRemoveAction } from '../selection/ducks';

export const RemovePost = props => <ActionButton { ...{ label: 'Prullenbak', ...props }} />;
export const EditPost = props => <ActionButton { ...{ label: 'edit', ...props }} />;
export const CancelJobs = props => <ActionButton { ...{ label: 'Cancel', ...props }} />;
export const RedoJobs = props => <ActionButton { ...{ label: 'Redo', ...props }} />;
export const DeleteConversionArchive = props => <ActionButton { ...{ label: 'Delete', ...props }} />;

export const EditPostThumbLink = ({ posts, children }) => {
  const [importing, edit] = useEditItem(posts);
  return (
    <div tw="cursor-pointer w-full md:w-auto" onClick={edit}>
      {!importing && children}
      {importing && <div tw="w-40 flex justify-center"><SpinnerIcon tw="text-7xl"/></div>}
    </div>
  );
};

export const ConvertPostButtons = ({
  posts,
  children,
  redoCallback = null,
  withCancel = true,
  withEdit = false,
  withRemove = false,
  withRedo = false,
  withDeleteArchive = false,
  deleteCallback = null,
}) => {
  const [importing, edit] = useEditItem(posts);
  const [cancelItem] = useCancelItem(posts);
  const [redoItem] = useRedoItem([posts.map(x => x.id).join()]);

  // const [redoItem] = useRedoItem(posts ? [posts.map(x => x.id).join()] : []);
  const redo = () => {
    redoItem();

    if (redoCallback) {
      redoCallback();
    }
  };
  const [removeArchive] = useDeleteConversionArchive({ids: [posts.map(x => x.id).join()], callback: deleteCallback});

  const remove = useConfirmDialog(
    useCallback(note => {
      const dispatch = useDispatch();
      const showErrorMessage = useShowErrorMessage();
      return dispatch(postsArchiveOp(posts, note))
        .then(() => dispatch(selectionRemoveAction(posts.map(x => x.id))))
        .catch(showErrorMessage);
    }, [posts.map(x => x.id).join()]),
  );

  return <div tw="flex flex-row items-center mb-2 sm:mb-0">
    <>
      {withEdit && <>
        <EditPost onClick={edit} loading={importing} disabled={importing}/>
        <Divider/>
      </>}
      {withCancel && <>
        <CancelJobs onClick={cancelItem} loading={importing} disabled={importing}/>
        <Divider/>
      </>}
    </>
    {withRemove &&
      <>
        <RemovePost onClick={remove}/>
        <Divider/>
      </>
    }
    {posts.length > 0 && withRedo &&
    <>
      {/* <EditPost onClick={edit} loading={importing} disabled={importing}/> */}
      <RedoJobs onClick={redo} loading={importing} disabled={importing}/>
      <Divider/>
    </>
    }
    {posts.length > 0 && deleteCallback && withDeleteArchive &&
    <>
      {/* <EditPost onClick={edit} loading={importing} disabled={importing}/> */}
      <DeleteConversionArchive onClick={removeArchive} loading={importing} disabled={importing}/>
      <Divider/>
    </>
    }
    {children}
  </div>;
};
