import { USERS_FETCH } from './actions';

const EDITOR_EMAILS = [
  'milan@news-media.nl',
  'andrey@news-media.nl',
  'jeroen@news-media.nl',
  'kirill@news-media.nl',
];  // TODO: add real editor emails fetched from backend handler



export const usersInitialState = {
  list: [],
  filter: '',
  status: 'initial',
  editors: EDITOR_EMAILS,
};


export const usersReducer = (state = usersInitialState, { type, payload }) => {
  switch (type) {
  case USERS_FETCH:
    return { ...state, ...payload };
  default:
    break;
  }
  return state;
};