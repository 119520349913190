import React from 'react';

export const PublishIcon = props =>
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height="1em"
    width="1em"
    {...props}
  >
    <path d="M5 4v2h14V4H5m0 10h4v6h6v-6h4l-7-7-7 7z"/>
  </svg>;