import React, { useState, useEffect, useLayoutEffect } from 'react';
import tw from 'twin.macro';
import { useHistory, Link } from 'react-router-dom';
import { Button } from '../common/AdminButton';
import {
  PageContainer,
  ListContainer,
  ListRow, 
  SearchTextInput,
  ListColumnFixed,
} from '../common';
import { DummyPage } from './moderateUserPage';
import { fetchData } from 'services/user';
import { navigate, parseDateRFC3339 } from '../utils';
import { Paginator } from '../common/Paginator';

const getParam = ({ search }, name, fallback) => new URLSearchParams(search).get(name) || fallback;
export const getSearch = location => getParam(location, 'search', '');
export const getPage = location => getParam(location, 'page', '0');


const UserPagination = ({ history, defaultMaxPages, search, totalusers, hidePageInput }) => {
  
  const maxPages = Math.round(totalusers / 30);
  return(
    <Paginator 
      maxPages={search !== '' && maxPages < 15 
        ? maxPages === 1 ? 2 
          : maxPages : defaultMaxPages}
      startPage={getPage(location)}
      hidePageInput={hidePageInput}
      onPageChange={p => navigate(history, '/moderation', { page: p, search })}/>
  );
};

export const ModerationTab = () => {

  const history = useHistory();

  const [data, setData] = useState({
    items: [],
    DataisLoaded: false,
  });

  
  const page = Number(getPage(location));
  const search = getSearch(location);

  useEffect(() => {
    document.title = 'Dumpert - Moderation';
    fetchData(page, search)
      .then((response) => {
        setData({
          totalusers: response.data.total_count,
          users: response.data.users,
          DataisLoaded: true,
        });
      });
  }, [page, search]);

  useLayoutEffect(() => () => document.title = 'Dumpert - Admin', []);

  const { totalusers, users, DataisLoaded } = data;

  if (!DataisLoaded) {
    return <div>
      <h1> Please wait.... </h1> </div> ; 
  }

  return (
    <PageContainer toolbar={<ToolBar history={history} search={search} totalusers={totalusers}/>}> 
      <div tw='font-bold pb-2'>
        Aantal gebruikers: {totalusers}
        {search && <> met de term '{search}'</>}
      </div>
      <ListContainer>
        <ListRow tw='font-bold bg-gray-800 text-white'>
          <ListColumnFixed text='ID' small/>
          <ListColumnFixed text='Username'/>
          <ListColumnFixed text='Email'/>
          <ListColumnFixed text='First-Last name'/>
          <ListColumnFixed text='Active | Confirmed' />
          <ListColumnFixed text='Last updated	'/>
          <ListColumnFixed text='State' small/>
        </ListRow>
        
        {!(users && users.length > 0) && <>Geen Resultaten</>}
        {(users && users.length > 0) && users.map((item) => (<div key={item.id}>
          <ListRow css={[
            item.commentary_state === 'tempban' && tw`bg-[orange] bg-opacity-30`,
            item.commentary_state === 'permaban' && tw`bg-[tomato] bg-opacity-30`,
            item.commentary_state === 'shadowban' && tw`bg-[gray] bg-opacity-30`,
          ]}>
            <ListColumnFixed text={item.id} small/>
            <ListColumnFixed tw='font-bold'>
              {<Link to={`moderation/edit/${item.id}`} tw='text-brand'>{item.username}</Link>}
            </ListColumnFixed>
            <ListColumnFixed text={item.email} />
            <ListColumnFixed text={<>{item.first_name.String} {item.last_name.String} </>}/>
            <ListColumnFixed text={<>{item.is_active ? 'y' : 'n'} | {item.confirmed_at!=='' ? 'y' : 'n'}  </>}/>
            <ListColumnFixed text={parseDateRFC3339(item.updated_at)}/>
            <ListColumnFixed text={item.commentary_state} small/>
          </ListRow>
        </div>
        ))}
      </ListContainer>

      {(users && totalusers > 30) &&
        <div tw="hidden md:block mt-4 mb-10 text-center overflow-scroll">   
          <UserPagination history={history} defaultMaxPages={15} search={search} totalusers={totalusers} hidePageInput/>
        </div> 
      }     
    </PageContainer>
  );
};

const BlacklistButton = () => <ActionButton label='Blacklist' to='/moderation/blacklist'/>;
const CensoredButton = () => <ActionButton label='Censored_phrases' to='/moderation/phrases'/>;

const ActionButton = ({ label, to }) => {
  const history = useHistory();
  const onClick = () => {
    history.push(to);
  };
  return (
    <Button onClick={onClick}>{label}</Button>
  );
};

const ToolBar = ({ history, search, totalusers }) => { 
  return(<div tw='flex gap-2'>
    {search !== '' && <Button label="Go Back" onClick={() => history.goBack()} />}
    {search === '' && <>
      <BlacklistButton />
      <CensoredButton />
    </>
    }
    <SearchTextInput onSearch={(term => {
      navigate(history, '/moderation', { search: term });
    })
    } placeholder="Zoek in gebruikers" delay/>
    {(totalusers > 30) && <UserPagination 
      defaultMaxPages={10} 
      history={history} 
      search={search} 
      totalusers={totalusers}
    />}
  </div>);
};

export const UserModTab = () => {
  return (
    <DummyPage/>
  );
};
