import { selectionClearAction } from '../../selection/ducks';
import { archiveFetchAction } from './actions';
import { search } from 'services/uploads';

export const archiveFetchItemsOp = (page, criteria) => async dispatch => {
  dispatch(selectionClearAction());
  dispatch(archiveFetchAction({ items: [], criteria, status: 'loading' }));
  try {
    const items = await search(page, criteria);
    dispatch(archiveFetchAction({ status: 'loaded', items }));
  } catch (error) {

    // no-op
    dispatch(archiveFetchAction({ status: 'loaded', error }));
  }
};
