import React from 'react';
import 'twin.macro';
import c from 'classnames';
import styles from './LoadingListContainer.css';
import { SpinnerIcon, FrownIcon } from 'icons';

export const LoadingListContainer = ({ items, status, mapFunc, filterFunc }) => (
  <div className={c({ [styles.loading]: items.length < 1 || status === 'loading' })}>
    {status === 'loading' && <SpinnerIcon tw="h-16 w-16" />}
    {status === 'loaded' && items.length < 1 && <FrownIcon tw="h-8 w-8" />}
    {status === 'loaded' && items.length > 0 && filterFunc ? items.filter(filterFunc).map(mapFunc) : items.map(mapFunc)}
  </div>
);